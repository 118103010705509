import { Box, VStack, Text, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import instance from "../api/api";

interface BuyerDetailsProps {
  buyersID: string;
}

const initialValues = {
  firstName: "",
  surname: "",
  phoneNumber: "",
  emailAddress: "",
};

function BuyerDetails({ buyersID }: BuyerDetailsProps) {
  const [userProfile, setUserProfile] = useState(initialValues);

  useEffect(() => {
    const controller = new AbortController();
    let active = true;

    async function fetchBuyer() {
      if (active) {
        const response = await instance.get(`/api/v1/buyers/${buyersID}`);
        setUserProfile(response?.data?.buyer);
      }
    }
    fetchBuyer();
    return () => {
      controller.abort();
      active = false;
    };
  }, [buyersID]);
  return (
    <Box mb={10}>
      <Flex>
        <Box
          bgColor="#F5F5F5"
          w={[24, 24, 400]}
          h={[24, 24, 104]}
          mr={[2, 10, 0]}
          pl={[2, 2, 6]}
          pt={[1, 1, 6]}
        >
          <VStack alignItems="left">
            <Text fontWeight="bold" fontSize={18}>
              Name
            </Text>
            <Text>
              {userProfile.firstName} {userProfile.surname}
            </Text>
          </VStack>
        </Box>
        <Box
          bgColor="#F5F5F5"
          w={[200, 340, 400]}
          h={[24, 24, 104]}
          mr={[2, 10, 0]}
          pl={[2, 2, 6]}
          pt={[1, 1, 6]}
        >
          <VStack alignItems="left">
            <Text fontWeight="bold" fontSize={18}>
              Email Address
            </Text>
            <Text>{userProfile.emailAddress}</Text>
          </VStack>
        </Box>
        <Box
          bgColor="#F5F5F5"
          w={[24, 24, 400]}
          h={[24, 24, 104]}
          mr={[2, 10, 0]}
          pl={[2, 2, 6]}
          pt={[1, 1, 6]}
        >
          <VStack alignItems="left">
            <Text fontWeight="bold" fontSize={18}>
              Phone Number
            </Text>
            <Text>{userProfile.phoneNumber}</Text>
          </VStack>
        </Box>
      </Flex>
    </Box>
  );
}

export default BuyerDetails;
