import { useState } from "react";
import {
  Card,
  CardBody,
  Avatar,
  Image,
  Stack,
  Heading,
  CardFooter,
  Button,
  Text,
  useDisclosure,
  AvatarGroup,
  useToast,
} from "@chakra-ui/react";
import { StatusCodes } from "http-status-codes";
import eggIcon from "../images/eggIcon.svg";
import chickenIcon from "../images/chickenIcon.svg";
import instance from "../api/api";
import STATUS from "../constants/stat";
import UserRequestDetailsModal from "./UserRequestDetailsModal";

export interface UserDetails {
  _id: string;
  firstName: string;
  surname: string;
  emailAddress?: string;
  shopName?: string;
  farmName?: string;
  district: string;
  status: string;
  phoneNumber: number;
  userProfilePicture?: string;
  productType?: string[];
  createdAt: string;
  updatedAt: string;
}
export interface UserRequestProps {
  userRequestDetails: UserDetails;
  setUserRequestList: React.Dispatch<any>;
}

export default function UserRequest({
  userRequestDetails,
  setUserRequestList,
}: UserRequestProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const updateUserToApproved = async (userId: string) => {
    setIsLoading(true);

    if (userRequestDetails.productType) {
      const response = await instance.put(`/api/v1/farmers/${userId}`, {
        status: STATUS.APPROVED,
      });

      if (response.status === StatusCodes.OK) {
        setUserRequestList((prevState: any) => {
          return prevState.filter((userRequest: any) => {
            return userRequest._id !== userRequestDetails._id;
          });
        });
        setIsLoading(false);
        return toast({
          title: "Farmer request approved",
          description: "Farmer request approved successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }

      setIsLoading(false);
      return toast({
        title: "Farmer request not approved",
        description: "Farmer request not approved",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    const response = await instance.put(`/api/v1/buyers/${userId}`, {
      status: STATUS.APPROVED,
    });

    if (response.status === StatusCodes.OK) {
      setUserRequestList((prevState: any) => {
        return prevState.filter((userRequest: any) => {
          return userRequest._id !== userRequestDetails._id;
        });
      });
      setIsLoading(false);
      return toast({
        title: "Buyer request approved",
        description: "Buyer request approved successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }

    setIsLoading(false);
    return toast({
      title: "Buyer request not approved",
      description: "Buyer request not approved",
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };
  return (
    <Card
      textAlign="center"
      bg="#303133"
      color="white"
      maxW={["300px", "280px", "300px"]}
      h="300px"
      mr={["5%", "2%", "5%"]}
      mt={["10", "2", "10"]}
    >
      <CardBody
        justifyContent="center"
        pt={userRequestDetails.productType ? "0px" : "9"}
      >
        {userRequestDetails.productType && (
          <AvatarGroup
            pt="1"
            justifyContent="end"
            size={["sm", "xs", "sm"]}
            max={2}
          >
            {userRequestDetails.productType.map((product) => {
              return (
                <Avatar
                  key={product}
                  border="0px"
                  src={product === "Eggs" ? eggIcon : chickenIcon}
                />
              );
            })}
          </AvatarGroup>
        )}
        <Image
          display="inline"
          borderColor="black"
          boxSize={["100px", "70px", "100px"]}
          border="1px"
          borderWidth="5px"
          borderRadius="full"
          alt={`${userRequestDetails.firstName} ${userRequestDetails.surname}`}
          src={
            userRequestDetails.userProfilePicture ||
            "https://bit.ly/sage-adebayo"
          }
        />
        <Stack mt="2" spacing="1">
          <Heading
            size={
              (userRequestDetails.firstName + userRequestDetails.surname)
                .length > 20
                ? "xs"
                : ""
            }
          >{`${userRequestDetails.firstName} ${userRequestDetails.surname}`}</Heading>

          {userRequestDetails.productType ? (
            <Text>{userRequestDetails.farmName}</Text>
          ) : (
            <Text>{userRequestDetails.shopName}</Text>
          )}

          <Text fontSize="xs">{userRequestDetails.district}</Text>
        </Stack>
      </CardBody>

      <CardFooter p="0px" pb="15" justifyContent="center">
        <Button
          bg="#303133"
          border="1px"
          mr="2"
          borderColor="#BC6C25"
          onClick={() => {
            updateUserToApproved(userRequestDetails._id);
          }}
          _hover={{
            background: "white",
            color: "black",
          }}
          isLoading={isLoading}
        >
          Accept
        </Button>
        <Button
          bg="#303133"
          border="1px"
          borderColor="#BC6C25"
          onClick={onOpen}
          _hover={{
            background: "white",
            color: "black",
          }}
        >
          View
        </Button>

        <UserRequestDetailsModal
          userRequestDetails={userRequestDetails}
          isOpen={isOpen}
          onClose={onClose}
          isLoading={isLoading}
          updateUserToApproved={updateUserToApproved}
        />
      </CardFooter>
    </Card>
  );
}
