import {
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  useToast,
  GridItem,
  Button,
  HStack,
  Link,
  Text,
  Select,
  InputGroup,
  InputLeftElement,
  Box,
  Flex,
  Checkbox,
} from "@chakra-ui/react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { MdOutlineMail, MdPersonOutline } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { AiOutlineShop } from "react-icons/ai";
import { IFarmer } from "../models/farmer.type";
import "./Errors.css";
import "../styles/fontStyle.css";
import instance from "../api/api";
import validateNames from "../constants/validate";

const validate = validateNames();

const schema = yup.object().shape({
  productType: yup.array().min(1),
  firstName: yup
    .string()
    .required("First name is required")
    .min(3, "First name must be at least 3 characters long")
    .matches(validate, "Invalid character"),
  district: yup.string().required(),
  farmName: yup.string().required(),
  surname: yup
    .string()
    .required("Last name is required")
    .min(3, "Last name must be at least 3 characters long")
    .matches(validate, "Invalid character"),
  emailAddress: yup
    .string()
    .email("Please enter valid email address")
    .required("Email address is required"),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .matches(/^[0-9]{8}$/, "Phone number is 8 digits"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 3 characters long"),
});

function FarmerRegister() {
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const districts = [
    { id: 1, value: "Berea", text: "Berea" },
    { id: 2, value: "Butha-Buthe", text: "Butha-Buthe" },
    { id: 3, value: "Leribe", text: "Leribe" },
    { id: 4, value: "Mafeteng", text: "Mafeteng" },
    { id: 5, value: "Maseru", text: "Maseru" },
    { id: 6, value: "Mohale's Hoek", text: "Mohale's Hoek" },
    { id: 7, value: "Mokhotlong", text: "Mokhotlong" },
    { id: 8, value: "Qacha's Nek", text: "Qacha's Nek" },
    { id: 9, value: "Quthing", text: "Quthing" },
    { id: 10, value: "Thaba-Tseka", text: "Thaba-Tseka" },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IFarmer>({
    resolver: yupResolver(schema),
  });

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const onSubmit = async (data: IFarmer) => {
    try {
      const response = await instance.post("/api/v1/farmers/register", data);
      if (response) {
        navigate("/success");
      }
      if (!response) {
        toast({
          title: "Account not created.",
          description:
            "Sorry your registration was not successful. Please try again later.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast({
          title: "Account not created.",
          description:
            "Sorry your registration was not successful. Please try again later.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return error.message;
      }
      return "An unexpected error occurred";
    }
  };
  return (
    <div>
      <Flex
        w="full"
        h="full"
        alignItems="flex-start"
        justify="space-between"
        direction={{ base: "column", md: "row" }}
      >
        <Box w="full">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <SimpleGrid columns={2} columnGap={3} rowGap={6} w="full" py={25}>
              <GridItem colSpan={{ base: 2, lg: 1 }}>
                <FormControl isRequired>
                  <FormLabel
                    htmlFor="firstName"
                    fontWeight={600}
                    className="label"
                  >
                    first name
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <MdPersonOutline />
                    </InputLeftElement>
                    <Input
                      id="firstName"
                      placeholder="John"
                      {...register("firstName")}
                    />
                  </InputGroup>
                  {errors.firstName && (
                    <p className="error">{errors.firstName.message}</p>
                  )}
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2, lg: 1 }}>
                <FormControl isRequired>
                  <FormLabel
                    htmlFor="surname"
                    fontWeight={600}
                    className="label"
                  >
                    last name
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <MdPersonOutline />
                    </InputLeftElement>
                    <Input
                      placeholder="Doe"
                      id="surname"
                      {...register("surname")}
                    />
                  </InputGroup>

                  {errors?.surname && (
                    <p className="error">{errors.surname.message}</p>
                  )}
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2 }}>
                <FormControl isRequired>
                  <FormLabel
                    htmlFor="district"
                    fontWeight={600}
                    className="label"
                  >
                    district
                  </FormLabel>
                  <Select id="district" {...register("district")}>
                    <option value="">Select District</option>
                    {districts.map((option) => (
                      <option key={option.id} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </Select>
                  {errors?.district && (
                    <p className="error">Please select your district</p>
                  )}
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2 }}>
                <FormControl isRequired>
                  <FormLabel fontWeight={600} className="label">
                    product type
                  </FormLabel>
                  <HStack spacing={10}>
                    <Checkbox
                      {...register("productType")}
                      type="checkbox"
                      value="Poultry"
                      pr={10}
                    >
                      Poultry
                    </Checkbox>
                    <Checkbox
                      {...register("productType")}
                      type="checkbox"
                      value="Eggs"
                    >
                      Eggs
                    </Checkbox>
                  </HStack>
                  <p style={{ color: "red" }}>
                    {errors.productType && "Please select your product type"}
                  </p>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2 }}>
                <FormControl isRequired>
                  <FormLabel
                    htmlFor="phoneNumber"
                    fontWeight={600}
                    className="label"
                  >
                    phone number
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <FiPhone />
                    </InputLeftElement>
                    <Input
                      placeholder="56214440"
                      type="number"
                      id="phoneNumber"
                      {...register("phoneNumber")}
                    />
                  </InputGroup>
                  {errors?.phoneNumber && (
                    <p className="error">{errors.phoneNumber.message}</p>
                  )}
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2 }}>
                <FormControl isRequired>
                  <FormLabel
                    htmlFor="emailAddress"
                    fontWeight={600}
                    className="label"
                  >
                    email address
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <MdOutlineMail />
                    </InputLeftElement>
                    <Input
                      placeholder="Farmai@gmail.com "
                      id="emailAddress"
                      type="email"
                      {...register("emailAddress")}
                    />
                  </InputGroup>
                  {errors?.emailAddress && (
                    <p className="error">{errors.emailAddress.message}</p>
                  )}
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2 }}>
                <FormControl isRequired>
                  <FormLabel
                    htmlFor="password"
                    fontWeight={600}
                    className="label"
                  >
                    password
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement>
                      {!passwordShown ? (
                        <BsFillEyeFill onClick={togglePassword} />
                      ) : (
                        <BsFillEyeSlashFill onClick={togglePassword} />
                      )}
                    </InputLeftElement>
                    <Input
                      placeholder="Password"
                      id="password"
                      type={passwordShown ? "text" : "password"}
                      {...register("password")}
                    />
                  </InputGroup>

                  {errors?.password && (
                    <p className="error">{errors.password.message}</p>
                  )}
                  {errors?.password?.type === "minLength" && (
                    <p className="error">
                      Password must be at least 6 characters long
                    </p>
                  )}
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2 }}>
                <FormControl isRequired>
                  <FormLabel
                    htmlFor="farmName"
                    fontWeight={600}
                    className="label"
                  >
                    farm name
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <AiOutlineShop />
                    </InputLeftElement>
                    <Input
                      id="farmName"
                      placeholder="Your Farm Name"
                      {...register("farmName", {
                        required: "Farm name is required",
                      })}
                    />
                  </InputGroup>

                  {errors?.farmName && (
                    <p className="error">Farm name is required</p>
                  )}
                </FormControl>

                <Button
                  size="lg"
                  w="full"
                  mt="2rem"
                  bgColor="#BC6C25"
                  color="white"
                  type="submit"
                  _hover={{
                    border: "2px solid #BC6C25",
                    color: "black",
                    bgColor: "white",
                  }}
                  isLoading={isSubmitting}
                >
                  Create Account
                </Button>

                <HStack justifyContent="center" p={3}>
                  <Text fontSize={[20, 17, 23]}>
                    Already have an account?{"  "}
                    <Link
                      href="/login"
                      color="#BC6C25"
                      fontWeight={500}
                      textDecoration="underline"
                    >
                      Login
                    </Link>
                  </Text>
                </HStack>
              </GridItem>
            </SimpleGrid>
          </form>
        </Box>
      </Flex>
    </div>
  );
}

export default FarmerRegister;
