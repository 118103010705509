import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Input,
  Select,
  Radio,
  RadioGroup,
  NumberInput,
  NumberInputField,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputStepper,
  InputGroup,
  InputLeftElement,
  useToast,
  Flex,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import axios from "axios";
import instance from "../api/api";
import { getUserId } from "../services/auth.service";
import Header from "./BuyersHeader";
import "./Errors.css";
import "../styles/fontStyle.css";
import Order from "../models/Orders.type";

function AddOrder() {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<Order>();
  const userId = getUserId();

  const eggSize = [
    { id: 1, value: "Small", text: "Small" },
    { id: 2, value: "Medium", text: "Medium" },
    { id: 3, value: "Large", text: "Large" },
  ];

  const [product, setProduct] = useState(false);
  const [eggsValue, setEggsValue] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const onSubmit = async (data: Order) => {
    try {
      await instance.post(`/api/v1/buyers/${userId}/order`, data);
      toast({
        title: "Order created",
        description: "Order placed successfully, see cart to view orders",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          toast({
            title: "Order not created",
            description: "Order could not be placed",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      }
      return "An unexpected error occurred";
    }
  };

  const uploadError = {
    ageInWeeks: {
      required: "Age in weeks is required",
    },
    askingPrice: {
      required: "Price is required",
    },
  };

  const onPoultryValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const check = e.target.value;
    if (!check) {
      setProduct(false);
    } else {
      setProduct(true);
      setEggsValue(false);
    }
  };

  const onEggsValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const check = e.target.value;
    if (!check) {
      setEggsValue(false);
    } else {
      setEggsValue(true);
      setProduct(false);
    }
  };

  return (
    <div>
      <Header />
      <Box>
        <Heading
          mt={[2, 4, 6, 8]}
          fontWeight="semibold"
          p={4}
          color="#1C6B28"
          fontSize={{ sm: "20px", md: "30px", lg: "38px" }}
        >
          Create New Order
        </Heading>
        <Box p={4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              mt={[2, 4, 6, 0]}
              w="100"
              border="1px solid"
              borderColor="borderColor"
            >
              <Box>
                <Heading
                  mt={[2, 4, 6, 0]}
                  p={5}
                  fontWeight="medium"
                  fontSize={{ sm: "25px", md: "30px", lg: "30px" }}
                >
                  Order Information
                </Heading>
              </Box>
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(3, 1fr)",
                  lg: "repeat(4, 1fr)",
                }}
                gap={6}
                py={3}
                ml={[2, 4, 8]}
                mr={[2, 4, 8]}
              >
                <GridItem>
                  <FormControl as="fieldset">
                    <FormLabel
                      as="legend"
                      fontWeight="semibold"
                      className="label"
                    >
                      PRODUCT TYPE
                    </FormLabel>
                    <RadioGroup>
                      <HStack
                        spacing="24px"
                        {...register("productType", {
                          required: "Please select your product type",
                          min: 1,
                        })}
                      >
                        <div onChange={onPoultryValue}>
                          <Radio
                            {...register("productType")}
                            value="Poultry"
                            pr={5}
                          >
                            Poultry
                          </Radio>
                        </div>
                        <div onChange={onEggsValue}>
                          <Radio {...register("productType")} value="Eggs">
                            Eggs
                          </Radio>
                        </div>
                      </HStack>
                      {errors.productType && (
                        <p className="error">Please select your product type</p>
                      )}
                    </RadioGroup>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel
                      htmlFor="quantity"
                      fontWeight="semibold"
                      className="label"
                    >
                      Quantity
                    </FormLabel>
                    <Box maxW={60}>
                      <NumberInput
                        defaultValue={50}
                        min={50}
                        clampValueOnBlur={false}
                      >
                        <NumberInputField
                          {...register("quantity", {
                            required: "Quantity is required",
                            min: 1,
                          })}
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </Box>
                    {errors.quantity && (
                      <p className="error">{errors.quantity?.message}</p>
                    )}
                    {errors?.quantity?.type === "min" && (
                      <p className="error">Quantity can not be less than 1</p>
                    )}
                  </FormControl>
                  {product && (
                    <Flex>
                      <FormControl mt={2} maxW={60}>
                        <FormLabel
                          htmlFor="ageInWeeks"
                          fontWeight="semibold"
                          className="label"
                        >
                          Age In Weeks
                        </FormLabel>
                        <Input
                          id="ageInWeeks"
                          placeholder="2weeks"
                          type="number"
                          maxW={60}
                          {...register("ageInWeeks", uploadError.ageInWeeks)}
                        />
                        {errors.ageInWeeks && (
                          <p className="error">{errors.ageInWeeks?.message}</p>
                        )}
                      </FormControl>
                    </Flex>
                  )}
                  {eggsValue && (
                    <FormControl mt={2} maxW={60}>
                      <FormLabel fontWeight="semibold" className="label">
                        Egg Size
                      </FormLabel>
                      <Select
                        id="district"
                        {...register("eggSize", {
                          required: "Please select egg size",
                        })}
                      >
                        <option value="">Select egg Size</option>
                        {eggSize.map((option) => (
                          <option key={option.id} value={option.value}>
                            {option.text}
                          </option>
                        ))}
                      </Select>
                      {errors?.eggSize && (
                        <p className="error">{errors.eggSize.message}</p>
                      )}
                    </FormControl>
                  )}
                </GridItem>
                {eggsValue && (
                  <FormControl as="fieldset">
                    <FormLabel
                      as="legend"
                      fontWeight="semibold"
                      className="label"
                    >
                      Price per tray
                    </FormLabel>
                    <InputGroup>
                      <InputLeftElement>M</InputLeftElement>
                      <Input
                        type="number"
                        id="askingPrice"
                        maxW={60}
                        fontWeight={400}
                        placeholder="350"
                        {...register("price", uploadError.askingPrice)}
                      />
                    </InputGroup>
                    {errors.price && (
                      <p className="error">{errors.price?.message}</p>
                    )}
                  </FormControl>
                )}
                {product && (
                  <FormControl as="fieldset">
                    <FormLabel as="legend" fontWeight={700} className="label">
                      PRICE PER Kg
                    </FormLabel>
                    <InputGroup>
                      <InputLeftElement>M</InputLeftElement>
                      <Input
                        type="number"
                        id="askingPrice"
                        maxW={60}
                        fontWeight={400}
                        placeholder="350"
                        {...register("price", uploadError.askingPrice)}
                      />
                    </InputGroup>
                    {errors.price && (
                      <p className="error">{errors.price?.message}</p>
                    )}
                  </FormControl>
                )}
              </Grid>
            </Box>
            <Flex>
              <Button
                size="lg"
                mt={8}
                colorScheme="brand"
                type="submit"
                w={{ base: "full", md: "20rem" }}
                isLoading={isSubmitting}
              >
                Add Item to Cart
              </Button>
              <Button
                size="lg"
                mt={8}
                ml={2}
                w={{ base: "full", md: "20rem" }}
                bgColor="gray.300"
                type="submit"
                onClick={() => navigate("/dashboard/buyer-dashboard")}
              >
                Cancel
              </Button>
            </Flex>
          </form>
        </Box>
      </Box>
    </div>
  );
}

export default AddOrder;
