import {
  VStack,
  Stack,
  Text,
  Heading,
  Grid,
  Link,
  Box,
  Button,
} from "@chakra-ui/react";
import "aos/dist/aos.css";
import AboutCard from "./AboutCard";
import AboutImage1 from "../../images/Homepage_Images/AboutImage1.svg";
import AboutImage2 from "../../images/Homepage_Images/AboutImage2.svg";
import AboutImage3 from "../../images/Homepage_Images/AboutImage3.svg";
import AboutImage4 from "../../images/Homepage_Images/AboutImage4.svg";

function About() {
  return (
    <Stack maxH="120em" p={10} spacing={10} align="center">
      <VStack p={0} data-aos="zoom-in" data-aos-duration="1000">
        <Heading
          color="black"
          data-testid="aboutus"
          fontWeight={700}
          textAlign="center"
          fontSize={["1.88em", "1.88em", "6xl"]}
        >
          Why work with us
        </Heading>
        <Text fontSize="1.2em" fontWeight={400} textAlign="center">
          We provide fair pricing for both farmers and buyers
        </Text>
      </VStack>
      <Grid
        justifyContent="center"
        textAlign="center"
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(2, 0fr)",
          lg: "repeat(2, 0fr)",
          xl: "repeat(4, 1fr)",
        }}
        gap={10}
      >
        <AboutCard
          image={AboutImage1}
          title="Fair Prices"
          subtitle="Connects you directly to the source and ensuring you
          get the best price for top-quality products."
        />
        <AboutCard
          image={AboutImage2}
          title="E-commerce"
          subtitle="Sell and shop online through our website and enjoy a 
          variety of features."
        />
        <AboutCard
          image={AboutImage3}
          title="Manage Products"
          subtitle="Track and Manage your stock, orders, deliveries and much more."
        />
        <AboutCard
          image={AboutImage4}
          title="Coming Soon"
          subtitle="Stay tuned for exciting new products and features! We're
            always working to improve our marketplace."
        />
      </Grid>
      <Box
        textAlign="center"
        fontSize="1.25em"
        fontWeight={600}
        color="#49B97A"
      >
        <Link
          href="/products/listing"
          _hover={{
            textDecoration: "none",
          }}
        >
          <Button
            bg="#1C6B28"
            colorScheme="brand"
            textDecoration="none"
            data-testid="button"
            fontSize="lg"
            h={{ base: "40px", md: "50px" }}
            w={[170, 170, 280]}
            borderRadius="20px"
            mr={5}
            _hover={{
              border: "3px solid black",
              bg: "white",
              color: "black",
            }}
          >
            See all products
          </Button>
        </Link>
      </Box>
    </Stack>
  );
}

export default About;
