import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  useToast,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Button,
  Hide,
  Select,
  RadioGroup,
  HStack,
  Radio,
  InputGroup,
  InputLeftElement,
  Textarea,
  InputLeftAddon,
  Flex,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { RiAddLine } from "react-icons/ri";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "../../components/AdminHeader";
import instance from "../../api/api";
import Orders from "../../models/Orders.type";
import LoadingSpinner from "../../components/LoadingSpinner";

function AdminEditOrder() {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<Orders>();

  const [isPoultrySeller, setIsPoultrySeller] = useState(false);
  const [orders, setOrders] = useState<Orders[]>([]);
  const [productChecked, setProductChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const itemId = localStorage.getItem("itemId");
  const productType = localStorage.getItem("productType");
  const toast = useToast();
  const navigate = useNavigate();

  const onSubmit = async (data: Orders) => {
    setIsLoading(true);
    const orderId = localStorage.getItem("orderId");
    try {
      const response = await instance.put(`/api/v1/orders/${orderId}`, data);
      if (response && response.data) {
        setIsLoading(false);
        toast({
          title: "Order updated",
          description: "Order updated successfully, see cart to view orders",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          setIsLoading(false);
          toast({
            title: "Order not updated",
            description: "Order could not be updated",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      }
      return "An unexpected error occured";
    }
  };

  const uploadError = {
    productName: {
      required: "Product name is required",
    },
    askingPrice: {
      required: "Product price is required",
      pattern: {
        value: /^[0-9]+$/,
        message: "Please enter a number",
      },
    },
    quantity: {
      required: "Quantity is required",
    },
    ageInWeeks: {
      required: "Age in weeks is required",
    },
    description: {
      required: "Description is required",
    },
  };

  const onClickChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const check = e.target.value;
    if (!check) {
      setProductChecked(false);
    } else {
      setProductChecked(true);
    }
  };

  const handlePoultry = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkPoultry = event.target.checked;
    if (!checkPoultry) {
      setIsPoultrySeller(false);
    } else {
      setIsPoultrySeller(true);
    }
  };

  const handleEggs = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkEggs = event.target.checked;
    if (!checkEggs) {
      setIsPoultrySeller(true);
    } else {
      setIsPoultrySeller(false);
    }
  };

  const editOrders = {
    _id: "",
    productName: "",
    askingPrice: "",
    quantity: "",
    ageInWeeks: "",
    description: "",
    farmerId: "",
  };

  const [editableOrders, setEditableOrders] = useState(editOrders);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    setEditableOrders({ ...editableOrders, [name]: value });
  };

  useEffect(() => {
    let dataFetched = false;
    const usersOrders: Orders[] = [];
    const productCheckbox = () => {
      if (productType === "Eggs") {
        setProductChecked(true);
      }

      if (productType === "Poultry") {
        setIsPoultrySeller(true);
        setProductChecked(true);
      }
    };

    async function fetchAllOrders() {
      if (!dataFetched) {
        setOrders([]);
        const response = await instance.get(`/api/v1/orders/${itemId}`);
        usersOrders.pop();
        usersOrders.push(response.data.order);
        setOrders(usersOrders);
        localStorage.setItem("itemId", " ");
        productCheckbox();
      }
    }
    fetchAllOrders();
    return () => {
      dataFetched = true;
    };
  }, [itemId, productType]);

  const renderEditOrder = (
    <Box>
      <Header />
      <Heading
        mt={[2, 4, 6, 8]}
        fontWeight={600}
        p={6}
        color="green"
        fontSize={{ base: "1.875rem", md: "2.25rem" }}
      >
        Edit Order
      </Heading>
      <Box p={4} mt="1px">
        {orders.map((order: Orders) => (
          <form onSubmit={handleSubmit(onSubmit)} key={order?.createdAt}>
            <Box mt={4} border="1px" borderColor="gray.500">
              <Box>
                <Heading
                  mt={2}
                  fontWeight={500}
                  p={3}
                  fontSize={{ base: "1.5rem", md: "2rem" }}
                >
                  Order Information
                </Heading>
              </Box>
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(3, 1fr)",
                  lg: "repeat(4, 1fr)",
                }}
                gap={6}
                py={3}
                ml={[2, 4, 8]}
                mr={[2, 4, 8]}
              >
                <GridItem>
                  <FormControl as="fieldset" isRequired>
                    <FormLabel
                      as="legend"
                      fontWeight="semibold"
                      className="label"
                    >
                      PRODUCT TYPE
                    </FormLabel>
                    {isPoultrySeller ? (
                      <RadioGroup defaultValue="Poultry">
                        <div onChange={onClickChange}>
                          <HStack spacing="24px" {...register("productType")}>
                            <div onChange={handlePoultry}>
                              <Radio
                                {...register("productType")}
                                value="Poultry"
                                pr={5}
                              >
                                Poultry
                              </Radio>
                            </div>
                            <div onChange={handleEggs}>
                              <Radio
                                {...register("productType")}
                                value="Eggs"
                                isDisabled
                              >
                                Eggs
                              </Radio>
                            </div>
                          </HStack>
                        </div>
                        {!productChecked && (
                          <p className="error">
                            Please select your product type
                          </p>
                        )}
                      </RadioGroup>
                    ) : (
                      <RadioGroup defaultValue="Eggs">
                        <div onChange={onClickChange}>
                          <HStack spacing="24px" {...register("productType")}>
                            <div onChange={handlePoultry}>
                              <Radio
                                {...register("productType")}
                                value="Poultry"
                                pr={5}
                                isDisabled
                              >
                                Poultry
                              </Radio>
                            </div>
                            <div onChange={handleEggs}>
                              <Radio {...register("productType")} value="Eggs">
                                Eggs
                              </Radio>
                            </div>
                          </HStack>
                        </div>
                        {!productChecked && (
                          <p className="error">
                            Please select your product type
                          </p>
                        )}
                      </RadioGroup>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isRequired>
                    {isPoultrySeller ? (
                      <FormLabel
                        htmlFor="quantity"
                        fontWeight={700}
                        className="label"
                      >
                        Quantity of poultry
                      </FormLabel>
                    ) : (
                      <FormLabel
                        htmlFor="quantity"
                        fontWeight={700}
                        className="label"
                      >
                        Quantity in trays
                      </FormLabel>
                    )}
                    <Box maxW={40}>
                      <Input
                        defaultValue={order?.quantity}
                        min={1}
                        fontWeight={400}
                        {...register("quantity", uploadError.quantity)}
                        onChange={() => handleInputChange}
                      />
                    </Box>
                    {errors.quantity && (
                      <p className="error">{errors.quantity?.message}</p>
                    )}
                  </FormControl>
                  {isPoultrySeller ? (
                    <FormControl maxW={40} mt={2}>
                      <FormLabel
                        htmlFor="ageInWeeks"
                        fontWeight={700}
                        className="label"
                      >
                        Age In Weeks
                      </FormLabel>
                      <Input
                        id="ageInWeeks"
                        value={order?.ageInWeeks}
                        fontWeight={400}
                        type="number"
                        maxW={160}
                        {...register("ageInWeeks", uploadError.ageInWeeks)}
                        onChange={handleInputChange}
                        isDisabled
                      />
                      {errors.ageInWeeks && (
                        <p className="error">{errors.ageInWeeks?.message}</p>
                      )}
                    </FormControl>
                  ) : (
                    <FormControl mt={2} maxW={40}>
                      <FormLabel fontWeight={700} className="label">
                        Egg Size
                      </FormLabel>
                      <Select
                        fontWeight={400}
                        value={order?.eggSize}
                        {...register("eggSize")}
                        onChange={() => handleInputChange}
                        isDisabled
                      >
                        <option>Small</option>
                        <option>Medium</option>
                        <option>Large</option>
                      </Select>
                    </FormControl>
                  )}
                </GridItem>
                <GridItem>
                  <Hide>
                    <Box
                      boxSize="80px"
                      objectFit="cover"
                      bg="gray.100"
                      mr={[0, 250, 250]}
                    >
                      <RiAddLine fontSize={80} />
                    </Box>
                  </Hide>
                  <FormControl isRequired>
                    <FormLabel
                      htmlFor="askingPrice"
                      fontWeight={700}
                      className="label"
                    >
                      Price
                    </FormLabel>
                    <InputGroup>
                      <InputLeftElement>M</InputLeftElement>
                      <Input
                        type="number"
                        id="price"
                        w={100}
                        fontWeight={400}
                        value={order?.price}
                        {...register("price", uploadError.askingPrice)}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                    {errors.price && (
                      <p className="error">{errors.price?.message}</p>
                    )}
                  </FormControl>
                  <FormControl mt={2} maxW={40} isRequired>
                    <FormLabel fontWeight={700} className="label">
                      Status
                    </FormLabel>
                    <Select
                      fontWeight={400}
                      value={order?.status}
                      {...register("status")}
                      onChange={() => handleInputChange}
                    >
                      <option>Pending</option>
                      <option>Sold</option>
                    </Select>
                  </FormControl>
                  <Hide>
                    <FormControl as="fieldset">
                      <FormLabel as="legend" fontWeight={500}>
                        Payment Method
                      </FormLabel>
                      <RadioGroup defaultValue="Cash">
                        <HStack spacing="24px">
                          <Radio value="Cash">Cash Only</Radio>
                          <Radio value="Card">Card Only</Radio>
                          <Radio value="Both">Cash & Card</Radio>
                        </HStack>
                      </RadioGroup>
                    </FormControl>
                  </Hide>
                </GridItem>
              </Grid>
            </Box>
            <Hide>
              <Box mt={[2, 4, 6, 8]} w="100" border="1px solid black">
                <Box>
                  <Heading
                    mt={[2]}
                    fontWeight={500}
                    fontSize={{ base: "1.5rem", md: "2rem" }}
                  >
                    Shipping Information
                  </Heading>
                </Box>
                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(4, 1fr)",
                  }}
                  gap={6}
                  py={3}
                  ml={[2, 4, 8]}
                  mr={[2, 4, 8]}
                >
                  <GridItem>
                    <FormControl>
                      <FormLabel fontWeight={500}>ADDERSS</FormLabel>
                      <Textarea
                        fontSize="18px"
                        fontWeight={400}
                        lineHeight="22px"
                        letterSpacing="0em"
                        textAlign="left"
                        placeholder="Cheyenne Korsgaard
                    2972 Westheimer Rd.
                    Santa Ana, Illinois
                    85486
                    The United States of America"
                        size="lg"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel fontWeight={500}>METHOD</FormLabel>
                      <Input
                        placeholder="Delivery and Collection"
                        fontWeight={400}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel fontWeight={500}>PHONE NUMBER</FormLabel>
                      <InputGroup>
                        <InputLeftAddon>+266</InputLeftAddon>
                        <Input
                          type="tel"
                          placeholder="6331  0128"
                          w="auto"
                          fontWeight={400}
                        />
                      </InputGroup>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel fontWeight={500}>FEE</FormLabel>
                      <InputGroup>
                        <InputLeftElement> M </InputLeftElement>
                        <Input
                          w={100}
                          placeholder="9.00"
                          type="number"
                          fontWeight={400}
                        />
                      </InputGroup>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel fontWeight={500}>CONSTRAINTS</FormLabel>
                      <Textarea
                        fontSize="18px"
                        fontWeight={400}
                        lineHeight="22px"
                        letterSpacing="0em"
                        textAlign="left"
                        placeholder="Delivery not available on Holidays"
                        size="lg"
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
              </Box>
            </Hide>
            <Flex>
              <Button
                size="lg"
                mt={6}
                w={{ base: "full", md: "20rem" }}
                colorScheme="brand"
                type="submit"
                isLoading={isSubmitting}
              >
                Save Changes
              </Button>
              <Button
                size="lg"
                mt="6"
                ml={2}
                w={{ base: "full", md: "20rem" }}
                bgColor="gray.300"
                type="submit"
                disabled={isLoading}
                onClick={() => navigate("/dashboard/all-orders")}
              >
                Cancel
              </Button>
            </Flex>
          </form>
        ))}
      </Box>
    </Box>
  );

  return <div>{isLoading ? <LoadingSpinner /> : renderEditOrder}</div>;
}

export default AdminEditOrder;
