import React, { useMemo, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Hero";
import Login from "./pages/Login";
import LoginWithPassword from "./pages/LoginWithPassword";
import AddOrder from "./components/AddOrder";
import Success from "./components/Success";
import Verify from "./pages/Verify";
import BuyersProfile from "./pages/Buyer/BuyersProfile";
import BuyerDashboard from "./pages/Buyer/BuyerDashboard";
import AdminRegister from "./pages/Admin/AdminRegister";
import AdminProfile from "./pages/Admin/AdminProfile";
import EditOrder from "./pages/Buyer/EditOrder";
import FarmersProfile from "./pages/Farmer/FarmersProfile";
import FarmersDashboard from "./pages/Farmer/FarmersDashboard";
import EditProduct from "./pages/Farmer/EditProduct";
import AdminNewUsers from "./pages/Admin/AdminAddNewUsers";
import AdminAllUsers from "./pages/Admin/AdminAllUsers";
import AuthContext, { IState } from "./context/Auth/AuthContext";
import AdminAllOrders from "./pages/Admin/AdminAllOrders";
import AdminUpdateBuyer from "./pages/Admin/AdminUpdateBuyer";
import AdminEditOrder from "./pages/Admin/AdminEditOrder";
import AdminUpdateAdmin from "./pages/Admin/AdminUpdateAdmin";
import AdminUpdateFarmer from "./pages/Admin/AdminUpdateFarmer";
import ProtectedRoute from "./components/ProtectedRoute";
import OrderDetails from "./pages/Admin/OrderDetails";
import ProductListing from "./pages/ProductListing";
import Registration from "./pages/Registration";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

function MainRoutes() {
  const [user, setUser] = useState<IState | null>(null);
  const userAuthValue = useMemo(() => ({ user, setUser }), [user, setUser]);

  return (
    <div>
      <AuthContext.Provider value={userAuthValue}>
        <ProtectedRoute.BuyerRoutes
          route={<BuyersProfile />}
          path="buyers-profile"
        />
        <ProtectedRoute.BuyerRoutes route={<AddOrder />} path="add-order" />
        <ProtectedRoute.BuyerRoutes route={<EditOrder />} path="edit-order" />
        <ProtectedRoute.BuyerRoutes
          route={<BuyerDashboard />}
          path="buyer-dashboard"
        />
        <ProtectedRoute.FarmerRoutes
          route={<FarmersProfile />}
          path="farmers-profile"
        />
        <ProtectedRoute.FarmerRoutes
          route={<FarmersDashboard />}
          path="farmers-dashboard"
        />
        <ProtectedRoute.FarmerRoutes
          route={<EditProduct />}
          path="edit-product/:id"
        />
        <ProtectedRoute.AdminRoutes
          route={<AdminNewUsers />}
          path="user-requests"
        />
        <ProtectedRoute.AdminRoutes
          route={<AdminAllUsers />}
          path="all-users"
        />
        <ProtectedRoute.AdminRoutes
          route={<AdminDashboard />}
          path="admin-dashboard"
        />
        <ProtectedRoute.AdminRoutes
          route={<AdminAllOrders />}
          path="all-orders"
        />
        <ProtectedRoute.AdminRoutes
          route={<AdminUpdateBuyer />}
          path="update-buyer"
        />
        <ProtectedRoute.AdminRoutes
          route={<AdminProfile />}
          path="admin-profile"
        />

        <ProtectedRoute.AdminRoutes route={<AdminRegister />} path="admin" />
        <ProtectedRoute.AdminRoutes
          route={<AdminEditOrder />}
          path="admin-edit-order"
        />
        <ProtectedRoute.AdminRoutes
          route={<AdminUpdateAdmin />}
          path="update-admin"
        />
        <ProtectedRoute.AdminRoutes
          route={<AdminUpdateFarmer />}
          path="update-farmer"
        />
        <ProtectedRoute.OtherRoutes route={<Login />} path="login" />
        <ProtectedRoute.OtherRoutes
          route={<ForgotPassword />}
          path="forgot-password"
        />
        <ProtectedRoute.OtherRoutes
          route={<ResetPassword />}
          path="reset-password"
        />
        <ProtectedRoute.OtherRoutes
          route={<LoginWithPassword />}
          path="password-login"
        />
        <ProtectedRoute.OtherRoutes route={<Success />} path="success" />
        <ProtectedRoute.OtherRoutes route={<Verify />} path="verify" />
        <ProtectedRoute.OtherRoutes route={<Registration />} path="/register" />
        <ProtectedRoute.AdminRoutes
          route={<OrderDetails />}
          path="order-details"
        />
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="products/listing" element={<ProductListing />} />
        </Routes>
      </AuthContext.Provider>
    </div>
  );
}

export default MainRoutes;
