import { useState } from "react";
import { Button, Grid, Stack, Text, Box, Hide } from "@chakra-ui/react";
import { BsFilter } from "react-icons/bs";
import useSWR from "swr";
import BuyersHeader from "../components/BuyersHeader";
import DefaultHeader from "../components/Header";
import AdminHeader from "../components/AdminHeader";
import instance from "../api/api";
import { getRole } from "../constants/auth";
import Pricelist from "../interface/Pricelist.type";
import ProductCard from "../components/ProductCard";
import SearchButton from "../components/SearchButton";
import LoadingSpinner from "../components/LoadingSpinner";
import Footer from "../components/Footer";

export default function ProductListing() {
  const [allProducts, setAllProducts] = useState<Pricelist[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const role = getRole();

  const selectHeader = () => {
    if (role === "Buyer") {
      return <BuyersHeader />;
    }

    if (role === "Admin") {
      return <AdminHeader />;
    }

    return <DefaultHeader />;
  };

  const sendRequest = async (url: string) => {
    const products: Pricelist[] = [];
    const response = await instance.get(url);
    if (response.data) {
      if (response.data.otherProducts) {
        for (let i = 0; i < response.data.otherProducts.length; i += 1) {
          products.push({
            size: "nil",
            productType: response.data.otherProducts[i].productType,
            productName: response.data.otherProducts[i].productName,
            price: response.data.otherProducts[i].askingPrice,
            quantity: response.data.otherProducts[i].quantity,
            imageUrl: response.data.otherProducts[i].productImage,
            unitOfMeasurement: response.data.otherProducts[i].unitOfMeasurement,
          });
        }
      }
      products.push({
        productType: "Eggs",
        productName: "Eggs",
        size: "Small",
        quantity: response.data.small.quantity,
        price: response.data.small.price,
      });
      products.push({
        productType: "Eggs",
        productName: "Eggs",
        size: "Medium",
        quantity: response.data.medium.quantity,
        price: response.data.medium.price,
      });
      products.push({
        productType: "Eggs",
        productName: "Eggs",
        size: "Large",
        quantity: response.data.large.quantity,
        price: response.data.large.price,
      });
    }
    setAllProducts(products);
    return response.data;
  };

  const { data: pricelistData, error: pricelistError } = useSWR(
    "/api/v1/eggsizes",
    sendRequest,
  );

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = allProducts.filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  );

  if (!pricelistData || pricelistError) {
    return (
      <div>
        {selectHeader()}
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <Stack minH="100vh">
        {selectHeader()}
        <Stack>
          <Stack
            w="98vw"
            pr="35px"
            pt="20px"
            pl={[10, 10, 50]}
            direction={{ base: "column", md: "row" }}
            alignItems="center"
            spacing={2}
          >
            <Text
              color="black"
              fontWeight={500}
              fontSize="1.5em"
              pb="5px"
              pr="5px"
            >
              Products
            </Text>
            <SearchButton
              placeholder="Search for products"
              searchTerm={searchTerm}
              handleChange={handleSearch}
            />
            <Hide>
              <Stack
                direction={{ base: "row" }}
                justifyContent={{ base: "space-between", md: "flex-end" }}
                w={{ base: "full", lg: "40%" }}
                spacing={4}
              >
                <Button
                  bgColor="white"
                  border="1px solid #BC6C25"
                  w={{ base: "30%", xl: "20%" }}
                >
                  <span>
                    <BsFilter />
                  </span>{" "}
                  Filter
                </Button>
              </Stack>
            </Hide>
          </Stack>
          <Box pt="25px" pl={[10, 10, 59.9]} pr="40px" mb={5}>
            <Grid
              justifyContent="center"
              textAlign="center"
              templateColumns={{
                sm: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(2, 1fr)",
                xl: "repeat(3, 1fr)",
              }}
              gap={6}
              p={{ base: 2, md: 10 }}
              border="1px dashed black"
              borderRadius="10px"
              mb={2}
            >
              {filteredData.map((item: Pricelist) => (
                <ProductCard
                  productType={item.productType}
                  productName={item.productName}
                  unitOfMeasurement={item.unitOfMeasurement}
                  imageUrl={item.imageUrl}
                  size={item.size}
                  price={item.price}
                  quantity={item.quantity}
                  userRole={role}
                />
              ))}
            </Grid>
          </Box>
        </Stack>
      </Stack>
      {filteredData && <Footer />}
    </>
  );
}
