import {
  Box,
  Text,
  Flex,
  Hide,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import SearchButton from "../../components/SearchButton";
import Header from "../../components/AdminHeader";
import BuyerRequests from "../../components/BuyerRequests";
import FarmerRequests from "../../components/FarmerRequests";

function AdminNewUsers() {
  return (
    <Box>
      <Header />
      <Box className="title" pt="34">
        <Flex
          pt="20px"
          pl={[10, 10, 75]}
          direction={{ base: "column", md: "row" }}
          alignItems={["left", "left", "center"]}
        >
          <Text
            color="#303133"
            fontWeight="bold"
            fontSize={["25", "38"]}
            pb="15px"
            pr={55}
            mr={10}
          >
            User Requests
          </Text>
          <Hide>
            <SearchButton placeholder="Search for users" />
          </Hide>
        </Flex>
        <Tabs isLazy>
          <TabList pl={[12, 12, 70]}>
            <Tab color="#303133">Buyers</Tab>
            <Tab color="#303133">Farmers</Tab>
          </TabList>
          <TabPanels pl={["10px", "5px", "70px"]} pr={["10px", "5px", "70px"]}>
            <TabPanel>
              <BuyerRequests />
            </TabPanel>
            <TabPanel>
              <FarmerRequests />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}

export default AdminNewUsers;
