import { Flex, VStack, Heading, Text, Image } from "@chakra-ui/react";
import React from "react";
import "aos/dist/aos.css";
import OrderPageImage from "../../images/Homepage_Images/OrderPageImage.svg";

function LandingSection2() {
  return (
    <div>
      <Flex
        align="center"
        maxH="120em"
        justifyContent="space-between"
        p={{
          base: "0",
          md: "2em",
        }}
        h={{ base: "auto", lg: "100vh" }}
        py={[0, 5, 0]}
        direction={{ base: "column", lg: "row" }}
      >
        <VStack data-aos="fade-up" data-aos-duration="1000">
          <Image
            mt={{
              base: "3em",
              md: "auto",
            }}
            px={{ base: 5 }}
            src={OrderPageImage}
            height={{
              base: "auto",
              md: "48em",
              lg: "48em",
              xl: "80em",
              "2xl": "96em",
            }}
            width={{
              base: "auto",
              md: "48em",
              lg: "62em",
              xl: "80em",
              "2xl": "120em",
            }}
          />
        </VStack>
        <VStack
          data-aos="fade-up"
          textAlign={{
            base: "center",
            lg: "right",
          }}
          p={10}
          spacing={5}
          alignItems="flex-start"
          w={{
            base: "auto",
            md: "48em",
            lg: "62em",
            xl: "80em",
            "2xl": "96em",
          }}
          mt={{
            base: "2em",
          }}
        >
          <Heading
            fontSize={{
              base: "2em",
              lg: "2.25em",
            }}
            color="#000000"
            data-testid="hero"
            fontWeight={500}
          >
            Ready to join the revolution of smarter farming?
          </Heading>

          <VStack>
            <Text
              fontSize="1.3em"
              fontWeight={400}
              color="#000000"
              lineHeight="24.68px"
            >
              Your one-stop shop for all your fresh product needs. With our
              online platform, you can now access the best produce from local
              farmers, right at your doorstep.
            </Text>
          </VStack>
        </VStack>
      </Flex>
    </div>
  );
}

export default LandingSection2;
