import { Flex, Text, Skeleton, Stack, SimpleGrid } from "@chakra-ui/react";
import useSWR from "swr";
import { GiFarmer } from "react-icons/gi";
import { BiBody } from "react-icons/bi";
import OverviewCard from "../../components/AdminDashboard/OverviewCard";
import RequestsOverviewCard from "../../components/AdminDashboard/RequestsOverviewCard";
import Header from "../../components/Header";
import totalEggsSoldIcon from "../../images/totalEggsSold.svg";
import totalOrdersSoldIcon from "../../images/totalOrdersSold.svg";
import instance from "../../api/api";
import AllOrdersTable from "../../components/AdminDashboard/AllOrdersTable";
import OrderSummaryGraph from "../../components/AdminDashboard/OrderSummaryGraph";
import UserSummaryCard from "../../components/AdminDashboard/UserSummaryCard";
import AvailableStockOverviewCard from "../../components/AdminDashboard/AvailableStockOverviewCard";

export default function AdminDashboard() {
  const getDashboardStats = async (url: string) => {
    const response = await instance.get(url);

    return response.data;
  };

  const { data, isLoading } = useSWR("/admin/dashboard", getDashboardStats, {
    refreshInterval: 5000,
  });

  return (
    <div>
      <Header />
      <Flex flexDir="column">
        <Flex flexDir="column" p={["5", "10"]}>
          <Text fontSize={["2xl", "4xl"]} fontWeight="bold">
            Welcome Back
          </Text>
          <Text fontSize={["md", "xl"]}>Heres your overview</Text>
          <Flex mt="5" flexDir={["column", "column", "row"]}>
            <Flex
              w={["100%", "100%", "70%"]}
              mr={["0", "0", "5"]}
              flexDir="column"
            >
              <SimpleGrid
                mb={["2", "2", "3"]}
                columns={[1, 2, 4]}
                spacing="10px"
              >
                <OverviewCard
                  title="Trays Sold"
                  amount={data?.traysSold || 0}
                  imageLocation={totalEggsSoldIcon}
                />
                <OverviewCard
                  title="Total Orders"
                  amount={data?.totalOrdersCount || 0}
                  imageLocation={totalOrdersSoldIcon}
                />
                <OverviewCard
                  title="Pending Orders"
                  amount={data?.totalPendingOrders || 0}
                  imageLocation={totalOrdersSoldIcon}
                />
                <OverviewCard
                  title="Total Sales"
                  amount={`M${data?.totalSalesGenerated || 0}`}
                  imageLocation={totalOrdersSoldIcon}
                />
              </SimpleGrid>

              <Flex flexDir={["column", "row"]} mb={["2", "2", "3"]} gap="10px">
                <UserSummaryCard
                  title="Farmers"
                  icon={GiFarmer}
                  activeUsersCount={data?.farmerSummary[0].totalFarmers || 0}
                />
                <UserSummaryCard
                  title="Buyers"
                  icon={BiBody}
                  activeUsersCount={data?.buyerSummary[0].totalBuyers || 0}
                />
              </Flex>

              <Flex
                mb={["5", "5", "0"]}
                h="100%"
                border="1px"
                borderRadius="md"
                p={["0", "10"]}
                justifyContent="center"
                flexDir="column"
                textAlign="center"
              >
                <Text mb="3" fontWeight="bold">
                  Total Orders by Month
                </Text>
                {isLoading && (
                  <Stack w="100%">
                    <Skeleton height="100%" />
                  </Stack>
                )}

                {data && (
                  <OrderSummaryGraph graphData={data?.ordersGraphStats} />
                )}
              </Flex>
            </Flex>

            <Flex
              w={["100%", "100%", "30%"]}
              border="1px"
              borderRadius="md"
              flexDir="column"
              p={["2", "5"]}
            >
              {data && (
                <AvailableStockOverviewCard chartData={data?.availableStock} />
              )}
              <RequestsOverviewCard />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          flexDir="column"
          pl={["5", "2", "30"]}
          pr={["5", "5", "0"]}
          pt={["1", "5", "5"]}
        >
          <Text pl="2" fontSize="xl" fontWeight="bold">
            All Orders
          </Text>
          <Flex overflow="auto" p={["0", "10"]} flexDir="column">
            <AllOrdersTable orders={data?.totalOrders || []} />
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
}
