import React from "react";
import "aos/dist/aos.css";
import { HStack, Text, Image, Heading, Flex, Stack } from "@chakra-ui/react";
import InventoryImage from "../../images/Homepage_Images/InventoryImage.svg";

export default function LandingSection4() {
  return (
    <HStack
      w="full"
      maxH="120em"
      h={{ base: "auto", lg: "100vh" }}
      align="center"
      justifyContent="space-between"
      flexDirection={{ base: "column-reverse", md: "row" }}
    >
      <Stack
        data-aos="fade-up"
        data-aos-duration="1000"
        textAlign={{
          base: "center",
          md: "left",
        }}
      >
        <Flex
          p={{
            md: "5em",
          }}
          flexDirection="column"
          ml={{
            base: "0em",
            md: "1.25em",
          }}
          w={{
            base: "auto",
            md: "24em",
            lg: "31em",
            xl: "40em",
            "2xl": "48em",
          }}
        >
          <Heading
            fontSize={{
              base: "2em",
              lg: "2.5em",
            }}
            fontWeight={700}
            color="#050505"
          >
            Digital tools made for your convenience
          </Heading>
          <Text fontSize="1.25em" mt={10}>
            Streamline your farm operations with our digital tools! From
            inventory management to sales tracking, our suite of digital tools
            is designed to help farmers grow their business and reach more
            customers. Join the digital farming revolution today!
          </Text>
        </Flex>
      </Stack>

      <Stack
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="1000"
        w={{
          base: "auto",
          md: "48em",
          lg: "62em",
          xl: "80em",
          "2xl": "96em",
        }}
      >
        <Image src={InventoryImage} />
      </Stack>
    </HStack>
  );
}
