import { Flex, Text } from "@chakra-ui/react";
import { PieChart, Pie, ResponsiveContainer, Legend, Cell } from "recharts";

const COLORS = ["#606C38", "#BC6C25", "#FFBB28"];

interface AvailableStockType {
  _id: string;
  totalQuantity: number;
}

interface AvailableStockOverviewCardProps {
  chartData: AvailableStockType[];
}

export default function AvailableStockOverviewCard({
  chartData,
}: AvailableStockOverviewCardProps) {
  const data = [
    { name: chartData[0]._id, value: chartData[0].totalQuantity },
    { name: chartData[1]._id, value: chartData[1].totalQuantity },
    { name: chartData[2]._id, value: chartData[2].totalQuantity },
  ];

  const stockValues = data.map((item) => item.value);

  const availableStock = stockValues.reduce((acc, value) => {
    return acc + value;
  });

  const renderBlackLegendText = (value: string) => {
    return (
      <Text display="inline" color="black">
        {value}
      </Text>
    );
  };
  return (
    <Flex mb="3" textAlign="center" flexDir="column">
      <Text fontWeight="bold">Available Stock</Text>
      <ResponsiveContainer width="100%" height={310}>
        <PieChart>
          {availableStock !== 0 ? (
            <Pie
              legendType="line"
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill="#606C38"
              dataKey="value"
              label
            >
              {data.map((entry, index) => (
                <Cell fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          ) : (
            <Pie
              legendType="line"
              data={[{ name: "no stock", value: 1 }]}
              fill="#eeeeee"
              innerRadius={60}
              outerRadius={80}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          )}

          <Legend
            formatter={renderBlackLegendText}
            layout="vertical"
            verticalAlign="bottom"
          />
        </PieChart>
      </ResponsiveContainer>
    </Flex>
  );
}
