import {
  Flex,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Box,
  Text,
  useDisclosure,
  Badge,
  Tooltip,
  Stack,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import useSWR from "swr";
import React, { useState } from "react";
import moment from "moment";
import instance from "../../api/api";
import ProductOrdered from "./ProductOrdered";
import ProductSupplier from "./ProductSupplier";
import farmAiLogo from "../../images/farmAIDrawerLogo.svg";
import OrderCompleteButton from "../OrderCompleteButton";

interface Buyer {
  firstName: string;
  surname: string;
  district: string;
  shopName: string;
  phoneNumber: number;
  emailAddress: string;
  createdAt: string;
  updatedAt: string;
  _id: string;
  status: string;
  password?: string;
}

interface Order {
  _id: string;
  buyerId: Buyer | null;
  createdAt: string;
  updatedAt?: string;
  productName?: string;
  productType: string[];
  quantity: number;
  price?: number;
  paymentMethod?: string;
  status: string;
  description?: string;
  ageInWeeks?: number;
  eggSize?: string;
}

interface AdminOrderDetailsProps {
  orderId: string;
  orders: Order[];
}

export default function AdminOrderDetailsDrawer({
  orderId,
  orders,
}: AdminOrderDetailsProps) {
  const [orderDetails, setOrderDetails] = useState<Order>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef(null);

  const getOrderDetails = (id: string) => {
    const order = orders.filter((orderDet: any) => orderDet._id === id);
    setOrderDetails(order[0]);
  };

  const getOrderSuppliers = async (url: string) => {
    const response = await instance.get(url);

    return response.data.farmers;
  };

  const { data: availableOrderSuppliers } = useSWR(
    `/admin/order-suppliers?orderId=${orderDetails?._id}`,
    getOrderSuppliers,
  );

  return (
    <Flex>
      <Button
        border="1px"
        size="sm"
        onClick={() => {
          getOrderDetails(orderId);
          onOpen();
        }}
        ref={btnRef}
      >
        View Details
      </Button>
      {orderDetails && (
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
          size={["sm", "md", "md"]}
        >
          <DrawerOverlay />
          <DrawerContent bg="#FEFAE0">
            <DrawerCloseButton />
            <DrawerHeader>
              <Flex mb="3" alignItems="center">
                <Text fontSize={["xl", "2xl"]} mr="5">
                  Order{" "}
                  <Tooltip label={orderDetails._id}>
                    <Text display="inline" color="#929292">
                      {orderDetails._id.slice(0, 10)}
                    </Text>
                  </Tooltip>
                </Text>
                {orderDetails.status === "Sold" ? (
                  <Box borderRadius="md" color="white" bg="#606C38" as={Badge}>
                    Sold
                  </Box>
                ) : (
                  <Box borderRadius="md" color="white" bg="#303133" as={Badge}>
                    Pending
                  </Box>
                )}
              </Flex>
              <Text fontSize={["sm", "lg", "lg"]} color="#BC6C25">
                Processed on{" "}
                {moment(new Date()).local().format("DD-MM-YYYY hh:mm:ss a")}
              </Text>
            </DrawerHeader>

            <DrawerBody p="5">
              <Box p="2">
                <Flex h="50%" justifyContent="space-between">
                  <Flex flexDir="column">
                    <Text fontSize={["sm", "md"]}>Processed By</Text>
                    <Box
                      pt="2"
                      boxSize={["40px", "50px"]}
                      borderRadius="md"
                      objectFit="cover"
                    >
                      <Image src={farmAiLogo} alt="Dan Abramov" />
                    </Box>
                  </Flex>
                  <Flex textAlign="end" flexDir="column">
                    <Text fontWeight="bold">To:</Text>
                    <Text
                      fontSize={["sm", "md"]}
                      fontWeight="bold"
                    >{`${orderDetails.buyerId?.firstName} ${orderDetails.buyerId?.surname}`}</Text>
                    <Text fontSize="sm">
                      {orderDetails.buyerId?.emailAddress}
                    </Text>
                    <Text fontSize="sm">
                      {orderDetails.buyerId?.phoneNumber}
                    </Text>
                    <Text fontSize="sm">{orderDetails.buyerId?.district}</Text>
                  </Flex>
                </Flex>
              </Box>
              <Flex p="2" mt="2" flexDir="column">
                <Text mb="2" fontWeight="bold">
                  Available Farmers
                </Text>
                <SimpleGrid
                  h="100%"
                  overflow="auto"
                  spacing={4}
                  columns={[1, 2, 3]}
                >
                  {availableOrderSuppliers &&
                    availableOrderSuppliers.map((supplier: any) => {
                      return (
                        <ProductSupplier
                          key={supplier.id}
                          supplierInfo={supplier}
                        />
                      );
                    })}
                </SimpleGrid>
              </Flex>

              <Flex flexDir="column" p="2">
                <ProductOrdered
                  productPrice={orderDetails.price || 0}
                  productQuantity={orderDetails.quantity}
                  productSize={orderDetails.eggSize || ""}
                  productType={orderDetails.productType[0]}
                />
              </Flex>
            </DrawerBody>

            <DrawerFooter alignItems="center">
              <Stack w="100%">
                <Button
                  color="#BC6C25"
                  w="100%"
                  variant="outline"
                  borderColor="#BC6C25"
                  mr={3}
                  onClick={onClose}
                  _hover={{
                    background: "#606C38",
                    color: "white",
                  }}
                >
                  Cancel
                </Button>
                {orderDetails.status !== "Sold" && (
                  <OrderCompleteButton orderID={orderDetails._id} />
                )}
              </Stack>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </Flex>
  );
}
