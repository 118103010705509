import { Flex, GridItem, Image, Box, Text } from "@chakra-ui/react";
import "aos/dist/aos.css";
import React from "react";

export default function AboutCard({
  image,
  title,
  subtitle,
}: {
  image: string;
  title: string;
  subtitle: string;
}) {
  return (
    <GridItem
      data-aos="flip-right"
      data-aos-duration="500"
      textAlign="center"
      justifyContent="center"
      w={{ base: "100%", md: "19em" }}
      h="21em"
      p="2.3em"
      alignItems="center"
      borderRadius={46}
      bg="#FFFFFF"
      boxShadow="0px 4px 20px rgba(0, 0, 0, 0.25)"
    >
      <Flex justifyContent="center">
        <Box justifyContent="center">
          <Image src={image} />
        </Box>
      </Flex>
      <Flex direction="column">
        <Box justifyContent="center" fontWeight={700} fontSize="1.4em" p={2}>
          <Text>{title}</Text>
        </Box>
        <Box
          justifyContent="center"
          fontWeight={400}
          fontSize={{
            base: "1em",
            md: "1.1em",
          }}
          lineHeight="1.4em"
        >
          <Text>{subtitle}</Text>
        </Box>
      </Flex>
    </GridItem>
  );
}
