import { useState } from "react";
import {
  Box,
  Text,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
  Hide,
} from "@chakra-ui/react";
import useSWR from "swr";
import { GiFarmer } from "react-icons/gi";
import { BiBody } from "react-icons/bi";
import { FaUserCog } from "react-icons/fa";
import Header from "../../components/AdminHeader";
import AllAdminsTable from "../../components/AllAdminsTable";
import AllBuyersTable from "../../components/AllBuyersTable";
import AllFarmersTable from "../../components/AllFarmersTable";
import AddNewUserDrawer from "../../components/AdminDashboard/AddNewUserDrawer";
import AllUsersSearchBar from "../../components/AdminDashboard/AllUsersSeachBar";
import instance from "../../api/api";
import Admin from "../../models/admin.type";
import Buyer, { IBuyer } from "../../models/buyer.type";
import Farmer, { IFarmer } from "../../models/farmer.type";
import UserSummaryCard from "../../components/AdminDashboard/UserSummaryCard";
import STATUS from "../../constants/stat";

function AdminAllUsers() {
  const [activeFarmers, setActiveFarmers] = useState(0);
  const [activeBuyers, setActiveBuyers] = useState(0);

  const [userAdmins, setUserAdmins] = useState<Admin[]>([]);
  const [userBuyers, setUserBuyers] = useState<Buyer[]>([]);
  const [userFarmers, setUserFarmers] = useState<Farmer[]>([]);
  const toast = useToast();

  const getAdmins = async (url: string) => {
    try {
      const response = await instance.get(url);

      setUserAdmins(response.data.admins);
    } catch (error) {
      toast({
        title: "Failed to load Admins",
        description: "Fetching admins failed. Please reload the tab.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const getFarmers = async (url: string) => {
    try {
      const response = await instance.get(url);

      setUserFarmers(response.data.farmers);

      const activeUserFarmers = response?.data?.farmers.filter(
        (farmer: IFarmer) => farmer.status === STATUS.APPROVED,
      );

      setActiveFarmers(activeUserFarmers.length);
    } catch (error) {
      toast({
        title: "Failed to load Farmers",
        description: "Fetching farmers failed. Please reload the tab.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const getBuyers = async (url: string) => {
    try {
      const response = await instance.get(url);

      setUserBuyers(response.data.buyers);

      const activeUserBuyers = response.data.buyers.filter(
        (buyer: IBuyer) => buyer.status === STATUS.APPROVED,
      );

      setActiveBuyers(activeUserBuyers.length);
    } catch (error) {
      toast({
        title: "Failed to load Buyers",
        description: "Fetching Buyers failed. Please reload the tab.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useSWR("/admin/admins", getAdmins);
  useSWR("/api/v1/buyers", getBuyers);
  useSWR("/api/v1/farmers", getFarmers);

  return (
    <Box>
      <Header />
      <Box className="title" pt="34">
        <Flex
          pt="20px"
          pl={[5, 10, 59.9]}
          direction={{ base: "column", md: "row" }}
          mb="5"
        >
          <Text
            color="#303133"
            fontWeight="bold"
            fontSize="3xl"
            pb="15px"
            pl={[0, 0, 5]}
            w={["100%", "100%", "30%"]}
          >
            Registered Users
          </Text>

          <Flex
            w="100%"
            justifyContent={["flex-start", "flex-start", "flex-end"]}
            alignItems={["flex-start", "center"]}
            pr="55"
          >
            <Hide>
              <AllUsersSearchBar placeholder="Search for user" />
            </Hide>

            <AddNewUserDrawer />
          </Flex>
        </Flex>
        <Flex
          p="12"
          flexDir={["column", "row"]}
          mb={["2", "2", "3"]}
          gap="10px"
        >
          <UserSummaryCard
            title="Farmers"
            icon={GiFarmer}
            activeUsersCount={activeFarmers}
          />
          <UserSummaryCard
            title="Buyers"
            icon={BiBody}
            activeUsersCount={activeBuyers}
          />
          <UserSummaryCard
            title="Admins"
            icon={FaUserCog}
            activeUsersCount={userAdmins.length}
          />
        </Flex>
        <Tabs>
          <TabList ml={[0, 5, 70]}>
            <Tab>Admins</Tab>
            <Tab>Buyers</Tab>
            <Tab>Farmers</Tab>
          </TabList>
          <TabPanels pl={["2", "2", "10"]} pr="10" overflow="auto">
            <TabPanel>
              <AllAdminsTable
                admins={userAdmins}
                setUserAdmins={setUserAdmins}
              />
            </TabPanel>
            <TabPanel>
              <AllBuyersTable
                buyers={userBuyers}
                setUserBuyers={setUserBuyers}
              />
            </TabPanel>
            <TabPanel>
              <AllFarmersTable
                farmers={userFarmers}
                setUserFarmers={setUserFarmers}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}

export default AdminAllUsers;
