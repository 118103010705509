/* eslint-disable no-underscore-dangle */
import { useState } from "react";
import { Box, Stack, Button, Text, Hide, Flex } from "@chakra-ui/react";
import { BsFilter } from "react-icons/bs";
import useSWR from "swr";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import instance from "../../api/api";
import AddInventoryItemDrawer from "../../components/AddInventoryItemDrawer";
import FarmersHeader from "../../components/FarmersHeader";
import totalEggsSoldIcon from "../../images/totalEggsSold.svg";
import FarmersInventoryTable from "../../components/FarmersInventoryTable";
import SearchButton from "../../components/SearchButton";
import LoadingSpinner from "../../components/LoadingSpinner";
import { GetFarmerDetails, getUserId } from "../../services/auth.service";
import { IProduct } from "../../interface/Product.type";
import OverviewCard from "../../components/AdminDashboard/OverviewCard";

function FarmersDashboard() {
  const userId = getUserId();
  const [products, setProducts] = useState<IProduct[]>([]);
  const [totalSmallEggs, setTotalSmallEggs] = useState(0);
  const [totalMediumEggs, setTotalMediumEggs] = useState(0);
  const [totalLargeEggs, setTotalLargeEggs] = useState(0);
  const farmerName = GetFarmerDetails()?.firstName;
  const [searchTerm, setSearchTerm] = useState("");

  const getDashboardStats = async (url: string) => {
    const response = await instance.get(url);
    setTotalSmallEggs(response.data.totalSmallEggs);
    setTotalMediumEggs(response.data.totalMediumEggs);
    setTotalLargeEggs(response.data.totalLargeEggs);
    return response.data;
  };

  const { data: statsData, error: statsFetchError } = useSWR(
    `api/v1/farmers/dashboard/${userId}`,
    getDashboardStats,
    {
      refreshInterval: 5000,
    },
  );

  const availableEggsData = [
    { id: 1, name: "Small", value: totalSmallEggs },
    { id: 2, name: "Medium", value: totalMediumEggs },
    { id: 3, name: "Large", value: totalLargeEggs },
  ];

  const stockValues = availableEggsData.map((item) => item.value);

  const availableEggs = stockValues.reduce((acc, value) => {
    return acc + value;
  });

  const soldEggsData = [
    { id: 1, name: "Small", value: 70 },
    { id: 2, name: "Medium", value: 100 },
    { id: 3, name: "Large", value: 80 },
  ];

  const COLORS = ["#606C38", "#BC6C25", "#FFBB28"];
  const sendRequest = async (url: string) => {
    const farmerProducts: IProduct[] = [];
    const response = await instance.get(url);
    if (response.data) {
      for (let i = 0; i < response.data.products.length; i += 1) {
        farmerProducts.push(response?.data?.products[i]);
      }
    }
    setProducts(farmerProducts);
    return response.data.products;
  };
  const { data: productsData, error: productsFetchError } = useSWR(
    `api/v1/farmers/${userId}/products`,
    sendRequest,
    {
      refreshInterval: 5000,
    },
  );

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = products.filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  );

  const dashboardTopSection: any = (
    <Stack
      pr="35px"
      pt="20px"
      pl={[10, 10, 50]}
      direction={{ base: "column", sm: "row" }}
      justifyContent="space-between"
    >
      <Text color="black" fontWeight={500} fontSize="1.5em" pb="5px" pr="5px">
        Inventory
      </Text>
      <SearchButton
        searchTerm={searchTerm}
        handleChange={handleSearch}
        placeholder="Search for items"
      />
      <Stack
        direction={{ base: "row" }}
        justifyContent={{ base: "space-between", sm: "flex-end" }}
        w={{ base: "full", lg: "40%" }}
        spacing={4}
      >
        <Hide>
          <Button
            bgColor="white"
            border="1px solid #BC6C25"
            w={{ base: "30%", xl: "20%" }}
          >
            <span>
              <BsFilter />
            </span>{" "}
            Filter
          </Button>
        </Hide>

        <AddInventoryItemDrawer setProducts={setProducts} />
      </Stack>
    </Stack>
  );

  const legendTextColor = (legendText: string) => {
    return (
      <Text color="black" display="inline">
        {legendText}
      </Text>
    );
  };

  if (
    !productsData ||
    productsFetchError ||
    !statsData ||
    statsFetchError ||
    !farmerName
  ) {
    return (
      <div>
        <FarmersHeader />
        {dashboardTopSection}
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Box>
      <FarmersHeader />
      <Box pr="35px" pt="20px" pl={[10, 10, 50]}>
        <Text fontSize={["2xl", "4xl"]} fontWeight="bold">
          Welcome, {farmerName}
        </Text>
        <Text fontSize={["md", "xl"]}>Here is your overview</Text>
      </Box>
      <Flex
        mt="5"
        flexDir="column"
        pr="35px"
        pt="20px"
        pl={[10, 10, 50]}
        height="auto"
      >
        <Flex flexGrow="2" flexDir="column">
          <Flex mb={["6", "6", "10"]} gap="20px" flexDir="row">
            <OverviewCard
              title="Total Available Eggs"
              amount={statsData.totalEggs}
              imageLocation={totalEggsSoldIcon}
            />
            <Hide>
              <OverviewCard
                title="Total Sold Eggs"
                amount={250}
                imageLocation={totalEggsSoldIcon}
              />
            </Hide>
          </Flex>
        </Flex>
        <Flex
          flexGrow="1"
          border="1px"
          borderRadius="md"
          textAlign="center"
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Box w="full">
            <Text zIndex={10} fontWeight={700} fontSize="1.3em">
              Available Eggs
            </Text>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                {availableEggs !== 0 ? (
                  <Pie
                    data={availableEggsData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={40}
                    outerRadius={80}
                    fill="#82ca9d"
                    label
                  >
                    {availableEggsData.map((entry) => (
                      <Cell
                        key={`cell-${entry.id}`}
                        fill={COLORS[entry.id % COLORS.length]}
                      />
                    ))}
                  </Pie>
                ) : (
                  <Pie
                    legendType="line"
                    data={[{ name: "no stock", value: 1 }]}
                    fill="#eeeeee"
                    innerRadius={60}
                    outerRadius={80}
                    dataKey="value"
                  >
                    {availableEggsData.map((entry) => (
                      <Cell
                        key={`cell-${entry.id}`}
                        fill={COLORS[entry.id % COLORS.length]}
                      />
                    ))}
                  </Pie>
                )}
                <Tooltip />
                <Legend
                  formatter={legendTextColor}
                  verticalAlign="bottom"
                  height={36}
                />
              </PieChart>
            </ResponsiveContainer>
          </Box>

          <Hide>
            <Box w="full">
              <Text fontWeight={700} fontSize="1.3em">
                Sold Eggs
              </Text>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={soldEggsData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={40}
                    outerRadius={80}
                    fill="#82ca9d"
                    label
                  >
                    {soldEggsData.map((entry) => (
                      <Cell
                        key={`cell-${entry.id}`}
                        fill={COLORS[entry.id % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend
                    formatter={legendTextColor}
                    style={{ color: "black" }}
                    verticalAlign="bottom"
                    height={36}
                  />
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Hide>
        </Flex>
      </Flex>
      {dashboardTopSection}
      <FarmersInventoryTable
        products={filteredData}
        setProducts={setProducts}
      />
    </Box>
  );
}

export default FarmersDashboard;
