import { useState } from "react";
import "../../styles/fontStyle.css";
import useSWR from "swr";
import "../../components/Errors.css";
import LoadingSpinner from "../../components/LoadingSpinner";
import instance from "../../api/api";
import EditProductForm from "../../components/EditProductForm";

function EditProduct() {
  const id = localStorage.getItem("productId");

  const [initialValues, setInitialValues] = useState({
    _id: "",
    askingPrice: "",
    quantity: "",
    ageInWeeks: "",
    eggSize: "",
    farmerId: "",
    unitOfMeasurement: "",
    productType: "",
    productName: "",
    createdAt: "",
    updatedAt: "",
    status: "",
    description: "",
  });

  const sendRequest = async (url: string) => {
    const response = await instance.get(url);
    if (response.data) {
      setInitialValues(response.data.product);
    }
    return response.data;
  };
  const { data, error } = useSWR(`/api/v1/products/${id}`, sendRequest);
  if (!data || error) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      {initialValues.quantity !== "" ? (
        <EditProductForm initialValues={initialValues} />
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
}

export default EditProduct;
