import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  InputGroup,
  InputLeftElement,
  RadioGroup,
  Radio,
  Button,
  Box,
  useToast,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { IProduct } from "../interface/Product.type";
import product from "../constants/productType";
import eggSize from "../constants/getEggValue";
import instance from "../api/api";
import getProductType from "../utils/GetProductType";
import LoadingSpinner from "./LoadingSpinner";

export default function EditProductForm({
  initialValues,
}: {
  initialValues: IProduct;
}) {
  const toast = useToast();
  const productType = getProductType();
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState(initialValues);
  const id = localStorage.getItem("productId");

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<IProduct>();

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setProducts({ ...products, [name]: value });
  };
  const onSubmit = async (theProduct: IProduct) => {
    setIsLoading(true);
    try {
      const response = await instance.put(`/api/v1/products/${id}`, theProduct);
      setIsLoading(false);
      toast({
        title: "Update Product",
        description: "Product updated successfully",
        status: "success",
        duration: 7000,
        isClosable: true,
      });
      return response.data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response) {
          setIsLoading(false);
          toast({
            title: "Update Product Error",
            description: "Product could not be updated!",
            status: "error",
            duration: 7000,
            isClosable: true,
          });
        }
      }
      return "An unexpected error occurred";
    }
  };

  const uploadError = {
    productType: {
      required: "Product type is required",
    },
    productName: {
      required: "Product name is required",
    },
    askingPrice: {
      required: "Product price is required",
      pattern: {
        value: /^[0-9]+$/,
        message: "Only numbers are required",
      },
    },
    quantity: {
      required: "Quantity is required",
      min: {
        value: 1,
        message: "Quantity can not be less than 1",
      },
    },
    ageInWeeks: {
      required: "Age in weeks is required",
      min: {
        value: 1,
        message: "Quantity can not be less than 1",
      },
    },
    unitOfMeasurement: {
      required: "Unit of Measurement is required",
    },
    description: {
      required: "Description is required",
      minLength: {
        value: 5,
        message: "Not less than 10",
      },
    },
  };

  const renderEditProduct = (
    <div>
      <Stack>
        <Box py={10} mt={{ base: "-45", md: "-35", lg: "-25" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack mt={2} spacing={4}>
              <FormControl>
                <FormLabel as="legend" fontWeight={500} className="label">
                  Product Type
                </FormLabel>
              </FormControl>
              <RadioGroup>
                <Radio mr={2} value={products.productType} checked disabled>
                  {products.productType}
                </Radio>
              </RadioGroup>

              {productType !== product.Eggs && (
                <FormControl mt={3} mb={4}>
                  <FormLabel
                    htmlFor="productName"
                    fontWeight={500}
                    className="label"
                  >
                    Product Name
                  </FormLabel>
                  <Input
                    id="productName"
                    placeholder={products.productName}
                    fontWeight={400}
                    disabled
                    value={products?.productName}
                  />
                </FormControl>
              )}
              {productType === product.Livestock && (
                <FormControl mt={3} mb={4}>
                  <FormLabel
                    htmlFor="ageInWeeks"
                    fontWeight={500}
                    className="label"
                  >
                    Age In Weeks
                  </FormLabel>
                  <Input
                    id="ageInWeeks"
                    placeholder="2weeks"
                    fontWeight={400}
                    type="number"
                    value={products?.ageInWeeks}
                    {...register("ageInWeeks", uploadError.ageInWeeks)}
                    onChange={handleInputChange}
                  />
                  {errors.ageInWeeks && (
                    <p className="error">{errors.ageInWeeks?.message}</p>
                  )}
                </FormControl>
              )}

              <FormControl>
                <FormLabel
                  htmlFor="quantity"
                  fontWeight={500}
                  className="label"
                >
                  Quantity
                </FormLabel>
                <Box>
                  <Input
                    fontWeight={400}
                    value={products?.quantity}
                    {...register("quantity", uploadError.quantity)}
                    onChange={handleInputChange}
                  />
                </Box>
                {errors.quantity && (
                  <p className="error">{errors.quantity?.message}</p>
                )}
              </FormControl>
              <FormControl>
                <FormLabel
                  htmlFor="askingPrice"
                  fontWeight={500}
                  className="label"
                >
                  Price Per{" "}
                  {products.productType === product.Eggs
                    ? "Tray"
                    : products.unitOfMeasurement}
                </FormLabel>
                <InputGroup>
                  <InputLeftElement> M </InputLeftElement>
                  <Input
                    id="askingPrice"
                    w={100}
                    fontWeight={400}
                    value={products.askingPrice}
                    {...register("askingPrice", uploadError.askingPrice)}
                    onChange={handleInputChange}
                  />
                </InputGroup>
                {errors.askingPrice && (
                  <p className="error">{errors.askingPrice?.message}</p>
                )}
              </FormControl>
              {productType === product.Eggs && (
                <FormControl mt={3} mb={4}>
                  <FormLabel fontWeight={500} className="label">
                    Egg Size
                  </FormLabel>
                  <Select
                    id="eggSize"
                    data-testid="eggSize"
                    {...register("eggSize")}
                    value={products?.eggSize}
                    onChange={handleInputChange}
                  >
                    {eggSize.map((option) => (
                      <option key={option.id} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Stack>
            <Button
              size="lg"
              mt={2}
              w="full"
              bgColor="#BC6C25"
              color="white"
              _hover={{
                bgColor: "white",
                color: "black",
                border: "2px solid #BC6C25",
              }}
              type="submit"
              isLoading={isSubmitting}
              disabled={isLoading}
            >
              Update Inventory Item
            </Button>
          </form>
        </Box>
      </Stack>
    </div>
  );
  return <div>{isLoading ? <LoadingSpinner /> : renderEditProduct}</div>;
}
