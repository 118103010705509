import {
  Box,
  Button,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import React, { useState } from "react";
import source from "../images/sortIcon.svg";

function SortByButton() {
  const initialValues = {
    firstName: "",
    surname: "",
    district: "",
    shopName: "",
    phoneNumber: "",
    emailAddress: "",
  };

  const [searchKey, setSearchKey] = useState(initialValues);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setSearchKey({ ...searchKey, [name]: value });
  };

  return (
    <Box mr={[10, 10, 20]} mt={[10, 10, 0]} ml={[50, 50, 0]}>
      <InputGroup size="md">
        <InputLeftElement>
          <Image src={source} width="20px" height="20px" ml={10} />
        </InputLeftElement>
        <Input
          ml={5}
          px="3rem"
          type="text"
          placeholder="Sort by"
          width={["full", "full", 338]}
          value={
            searchKey.emailAddress ||
            searchKey.phoneNumber ||
            searchKey.district ||
            searchKey.firstName ||
            searchKey.surname ||
            searchKey.shopName
          }
          onChange={handleChange}
        />
        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" disabled>
            Filter
          </Button>
        </InputRightElement>
      </InputGroup>
    </Box>
  );
}

export default SortByButton;
