/* eslint-disable react/no-children-prop */
import {
  Box,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import React from "react";
import source from "../images/searchIcon.png";

interface SearchProps {
  placeholder?: string;
  searchTerm?: string;
  handleChange?: (event: any) => void;
}
function SearchButton({ placeholder, searchTerm, handleChange }: SearchProps) {
  return (
    <Box
      mr={[0, 0, 15]}
      w={{ base: "full", md: 330, lg: 600, xl: 1200, "2xl": 2500 }}
    >
      <InputGroup size="sm">
        <InputLeftElement>
          <Image src={source} width="20px" height="20px" />
        </InputLeftElement>
        <Input
          type="text"
          placeholder={placeholder}
          width={["full", "full", 757, 800, 800, 1400]}
          value={searchTerm}
          borderRadius="6px"
          onChange={handleChange}
        />
      </InputGroup>
    </Box>
  );
}

export default SearchButton;
