import { useState } from "react";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";
import instance from "../../api/api";
import Header from "../../components/FarmersHeader";
import "../../components/Errors.css";
import { getUserId } from "../../services/auth.service";
import LoadingSpinner from "../../components/LoadingSpinner";
import FarmersProfileForm from "../../components/FarmersProfileForm";

function FarmersProfile() {
  const [initialValues, setInitialValues] = useState({
    _id: "",
    firstName: "",
    surname: "",
    district: "",
    farmName: "",
    phoneNumber: "",
    emailAddress: "",
    productType: [""],
    createdAt: "",
    updatedAt: "",
    status: "",
  });
  const userId = getUserId();
  const navigate = useNavigate();
  if (!userId) {
    navigate("/login");
  }

  const sendRequest = async (url: string) => {
    const response = await instance.get(url);
    if (response.data) {
      setInitialValues(response.data.farmer);
    }
    return response.data;
  };

  const { data, error } = useSWR(`/api/v1/farmers/${userId}`, sendRequest);
  if (!data || error) {
    return (
      <div>
        <Header />
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <Header />
      {initialValues.firstName !== "" ? (
        <FarmersProfileForm initialValues={initialValues} />
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
}
export default FarmersProfile;
