import React, { ChangeEvent, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  GridItem,
  Button,
  Flex,
  Select,
  Heading,
  Box,
  HStack,
  Text,
  VStack,
  Avatar,
  useToast,
} from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import axios from "axios";
import User from "../images/Vector.svg";
import instance from "../api/api";
import { IAdmin } from "../models/admin.type";
import LoadingSpinner from "./LoadingSpinner";
import validateNames from "../constants/validate";
import { getUserId } from "../services/auth.service";

export default function AdminProfileForm({
  initialValues,
}: {
  initialValues: IAdmin;
}) {
  const [userProfile, setUserProfile] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const isAdmin = [
    { id: 1, value: "true", text: "true" },
    { id: 2, value: "false", text: "false" },
  ];

  const validate = validateNames();
  const toast = useToast();
  const userId = getUserId();
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setUserProfile({ ...userProfile, [name]: value });
  };
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: initialValues,
  });

  const uploadError = {
    firstName: {
      required: "First name is required",
      pattern: {
        value: validate,
        message: "Invalid character!!",
      },
    },
    surname: {
      required: "Last name is required",
      pattern: {
        value: validate,
        message: "Invalid character!!",
      },
    },
    phoneNumber: {
      required: "Phone number is required",
      maxLength: 8,
      minLength: 8,
    },
    emailAddress: {
      required: "Email address is required",
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid email address",
      },
    },
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const data = {
      firstName: userProfile.firstName,
      surname: userProfile.surname,
      phoneNumber: userProfile.phoneNumber,
      emailAddress: userProfile.emailAddress,
      isAdmin: userProfile.isAdmin,
    };
    try {
      const response = await instance.put(`/admin/admins/${userId}`, data);
      toast({
        title: "Profile updated",
        description: "Profile updated successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          toast({
            title: "Profile not updated",
            description: "Profile could not be updated ",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          setIsLoading(false);
          return error;
        }
      }
      return "An unexpected error occurred";
    }
  };

  const renderUser = (
    <Flex
      w="full"
      h="full"
      p={10}
      alignItems="flex-start"
      direction={{ base: "column", md: "row" }}
    >
      <Box w="full">
        <Flex
          direction={{ base: "column", md: "row" }}
          align={{ base: "center" }}
          ml={[0, 0, 50]}
        >
          <Avatar src={User} size={{ base: "xl", md: "xl" }} />
          <VStack p={6} align="flex-start">
            <HStack>
              <Text size="sm">{userProfile?.firstName}</Text>
              <Text size="sm">{userProfile?.surname}</Text>
            </HStack>
            <Text size="sm">Administrator</Text>
          </VStack>
        </Flex>
        <Box w={{ base: "full", md: "65%" }} ml={[0, 0, 50]}>
          <hr className="line" />
        </Box>
        <Heading
          p={6}
          fontSize={{ base: "xl" }}
          fontWeight={500}
          ml={[0, 0, 30]}
        >
          Personal Information
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <SimpleGrid
            columns={2}
            columnGap={3}
            rowGap={6}
            w={{ base: "full", md: "65%" }}
            py={25}
            ml={[0, 0, 50]}
          >
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel fontWeight={700} className="label">
                  first name
                </FormLabel>
                <Input
                  placeholder="John"
                  {...register("firstName", uploadError?.firstName)}
                  value={userProfile?.firstName}
                  onChange={handleInputChange}
                />
                {errors?.firstName && (
                  <p className="error">{errors.firstName?.message}</p>
                )}
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel fontWeight={700} className="label">
                  last name
                </FormLabel>
                <Input
                  placeholder="Doe"
                  {...register("surname", uploadError.surname)}
                  value={userProfile?.surname}
                  onChange={handleInputChange}
                />
                {errors.surname && (
                  <p className="error">{errors.surname?.message}</p>
                )}
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel fontWeight={700} className="label">
                  email address
                </FormLabel>
                <Input
                  placeholder="farmai@gmail.com"
                  value={userProfile?.emailAddress}
                  onChange={handleInputChange}
                />
                {errors?.emailAddress && (
                  <p className="error">{errors.emailAddress.message}</p>
                )}
              </FormControl>
            </GridItem>
            <FormControl>
              <FormLabel fontWeight={700} className="label">
                isadmin
              </FormLabel>
              <Select
                id="isAdmin"
                {...register("isAdmin")}
                value={userProfile.isAdmin}
                onChange={handleInputChange}
              >
                {isAdmin.map((option) => (
                  <option key={option.id} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </Select>
            </FormControl>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel
                  htmlFor="phoneNumber"
                  fontWeight={700}
                  className="label"
                >
                  phone number
                </FormLabel>
                <Input
                  placeholder="56214440"
                  value={userProfile?.phoneNumber}
                  {...register("phoneNumber", uploadError.phoneNumber)}
                  onChange={handleInputChange}
                />
                {errors?.phoneNumber && (
                  <p className="error">{errors.phoneNumber.message}</p>
                )}
                {errors?.phoneNumber?.type === "maxLength" && (
                  <p className="error">Phone number cannot exceed 8 digits</p>
                )}
                {errors?.phoneNumber?.type === "minLength" && (
                  <p className="error">Phone number is 8 digits</p>
                )}
              </FormControl>
              <Button
                size="lg"
                w="full"
                mt="2rem"
                colorScheme="tigersEye"
                type="submit"
                isLoading={isSubmitting}
                disabled={isLoading}
              >
                Update
              </Button>
            </GridItem>
          </SimpleGrid>
        </form>
      </Box>
    </Flex>
  );

  return <div>{isLoading ? <LoadingSpinner /> : renderUser}</div>;
}
