type Order = {
  status: string;
  count: number;
};

type MonthlyOrders = {
  _id: {
    month: number;
    year: number;
  };
  orders: Order[];
};

type Data = {
  name: string;
  complete: number;
  pending: number;
  amt: number;
};

function convertToData(monthlyOrders: MonthlyOrders[]): Data[] {
  const months: string[] = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const data: Data[] = months.map((month, index) => {
    const orders = monthlyOrders.find(
      (monthlyOrder) => monthlyOrder._id.month === index + 1,
    )?.orders;

    const complete = orders?.reduce(
      (total, order) => (order.status === "Sold" ? total + order.count : total),
      0,
    );

    const pending = orders?.reduce(
      (total, order) =>
        order.status === "Pending" ? total + order.count : total,
      0,
    );

    return {
      name: month,
      complete: complete || 0,
      pending: pending || 0,
      amt: complete || pending || 0,
    };
  });

  return data;
}

export default { convertToData };
