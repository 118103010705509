import React, { SyntheticEvent, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Input,
  Button,
  Link,
  Image,
  Stack,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { MdOutlineMail } from "react-icons/md";
import instance, { interceptorId } from "../api/api";
import validateEmail from "../constants/validateEmail";
import Logo from "../images/logo-dark-text.svg";
import DefaultHeader from "../components/DefaultHeader";
import Footer from "../components/Footer";

instance.interceptors.response.eject(interceptorId);

type IForgotPassword = {
  username: string;
};

export default function ForgotPassword() {
  const toast = useToast();
  const initialValue = {
    username: "",
  };

  const [formValue, setFormValue] = useState(initialValue);
  const [formErrors, setFormError] = useState({
    username: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const validate = (value: IForgotPassword) => {
    let isEmail = false;

    const errors = {
      username: "",
    };

    const textRegex = /^[a-z]/i;

    if (value.username.includes("@") || textRegex.test(value.username))
      isEmail = true;
    if (isEmail) {
      if (!validateEmail().test(value.username)) {
        errors.username = "Invalid email format";
      }
    }

    return errors;
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setFormError(validate(formValue));
    setIsSubmitting(true);

    try {
      await instance.post("/forgot-password", { username: formValue.username });

      toast({
        title: "Password Reset Requested",
        description: "Reset link has been sent to your email.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setIsSubmitting(false);
      return;
    } catch (error) {
      toast({
        title: "Password Reset Error",
        description: "Password reset request failed. Please try again..",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setIsSubmitting(false);
    }
  };

  return (
    <Stack bgColor="#FEFAE0">
      <DefaultHeader />
      <form onSubmit={handleSubmit} noValidate>
        <Stack h="100vh">
          <Flex
            direction="column"
            justifyContent="center"
            alignSelf="center"
            mt="1em"
            w={[300, 400, 500]}
          >
            <Box alignSelf="center">
              <Image w={130} h={130} src={Logo} />
            </Box>
            <Box w={[300, 400, 500]} textAlign="center" height="100px" mb="5%">
              <Text
                color="black"
                fontSize={{ base: "3xl", md: "5xl" }}
                fontWeight={400}
              >
                Forgot your Password?
              </Text>
              <Text>
                Enter your username/email to request a password change.
              </Text>
            </Box>
            <FormControl mb="5" isRequired>
              <FormLabel fontWeight={600} className="label">
                Email address
              </FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <MdOutlineMail />
                </InputLeftElement>
                <Input
                  borderColor="#D9D9D9"
                  placeholder="Email Address"
                  name="username"
                  type="email"
                  required
                  value={formValue.username}
                  onChange={handleChange}
                  mb="10px"
                  fontWeight={200}
                />
              </InputGroup>
              {formErrors.username && (
                <span className="error">{formErrors.username}</span>
              )}
            </FormControl>

            <Button
              type="submit"
              alignContent="left"
              fontSize="xl"
              color="white"
              bgColor="#BC6C25"
              isLoading={isSubmitting}
              _hover={{
                border: "2px solid #BC6C25",
                color: "black",
                bgColor: "white",
              }}
            >
              Reset Password
            </Button>
            <Flex direction="column" alignItems="center" mt={5} mb={7}>
              <Text p={1}>
                Don&#39;t have an account?{"  "}
                <Link
                  aria-label="sign up"
                  color="#BC6C25"
                  fontWeight={500}
                  textDecoration="underline"
                  href="/register"
                >
                  Sign Up{" "}
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Stack>
      </form>
      <Footer />
    </Stack>
  );
}
