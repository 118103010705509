import React, { useState } from "react";
import { Box, Text, Flex, useMediaQuery, Spacer } from "@chakra-ui/react";
import useSWR from "swr";
import Header from "../../components/AdminHeader";
import OrderCard from "../../components/OrderCard";
import FarmersAvailable from "../../components/FarmersAvailable";
import ProductsAvailable from "../../components/ProductsAvailable";
import OrderCompleteButton from "../../components/OrderCompleteButton";
import BuyerDetails from "../../components/BuyerDetails";
import instance from "../../api/api";
import { IOrderDetails } from "../../models/orderDetails";

function OrderDetails() {
  const productType = localStorage.getItem("productType");
  const eggsSize = localStorage.getItem("eggsSize");
  const ageInWeeks = localStorage.getItem("ageInWeeks");
  const quantity = localStorage.getItem("quantity");
  const price = localStorage.getItem("price");
  const ordersID = localStorage.getItem("OrderId");
  const buyerID = localStorage.getItem("buyersID");
  const [isEggsSeller, setIsEggsSeller] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [availableFarmers, setAvailableFarmers] = useState<IOrderDetails[]>([]);

  const formData = new FormData();
  formData.append("productType", productType!);
  formData.append("quantity", quantity!);

  const sendRequest = async (url: string) => {
    const response = await instance.post(url, { productType, quantity });
    availableFarmers.pop();
    setAvailableFarmers(response?.data.farmers);
    function checkProductType() {
      if (productType === "Eggs") {
        setIsEggsSeller(true);
      }

      if (productType === "Poultry") {
        setIsEggsSeller(false);
      }
    }
    checkProductType();
  };
  const { data, error, isLoading } = useSWR("/admin/runAlgorithm", sendRequest);

  function PostDetails() {
    if (isLoading) {
      return "loading";
    }
    if (error) {
      return "error";
    }

    if (!data) {
      return "no data";
    }

    return availableFarmers;
  }

  if (!isLoading && data) {
    PostDetails();
  }

  return (
    <Box>
      <Header />
      <Box className="title" pt="34">
        <Flex
          pt="20px"
          pl={[2, 10, 59.9]}
          direction={{ base: "column", md: "row" }}
          alignItems={["left", "left", "center"]}
        >
          <Text
            color="#1C6B28"
            fontWeight="bold"
            fontSize="38"
            pb="15px"
            mr={10}
          >
            Order Details
          </Text>
        </Flex>
        <Flex>
          <Box ml={[2, 10, 16]}>
            <OrderCard title="Product Type" value={productType} />
          </Box>
          {isEggsSeller ? (
            <OrderCard title="Egg Size" value={eggsSize} />
          ) : (
            <OrderCard title="Age in weeks" value={ageInWeeks} />
          )}
          <OrderCard title="Quantity" value={quantity} />
          <Box mr={[0, 0, 10]}>
            <OrderCard title="Price" value={price} />
          </Box>
        </Flex>
      </Box>
      <Flex
        pt="20px"
        pl={[2, 10, 59.9]}
        direction={{ base: "column", md: "row" }}
        alignItems={["left", "left", "center"]}
        mt={8}
      >
        <Text color="#1C6B28" fontWeight="bold" fontSize="38" pb="15px" mr={10}>
          Available Farmers
        </Text>
      </Flex>
      {isMobile ? (
        <Box
          ml={[2, 10, 16]}
          mr={[2, 10, 80]}
          mb={30}
          h="auto"
          w={["auto", "auto", "auto"]}
        >
          <Flex direction="column">
            <Box border="1px" borderColor="black" mb={2}>
              <FarmersAvailable availableFarmers={availableFarmers} />
            </Box>
            <Box border="1px" borderColor="black">
              <ProductsAvailable
                productType={productType!}
                availableProducts={availableFarmers}
              />
            </Box>
          </Flex>
        </Box>
      ) : (
        <Box
          border="1px"
          borderColor="black"
          ml={[2, 10, 16]}
          mr={["auto", "auto", 78]}
          mb={30}
          h="auto"
          w={["auto", "auto", "auto"]}
          overflow="auto"
        >
          <Flex direction="row">
            <FarmersAvailable availableFarmers={availableFarmers} />
            <ProductsAvailable
              productType={productType!}
              availableProducts={availableFarmers}
            />
          </Flex>
        </Box>
      )}
      <Flex
        pt="20px"
        pl={[2, 10, 59.9]}
        direction={{ base: "column", md: "row" }}
        alignItems={["left", "left", "center"]}
        mt={8}
      >
        <Text color="#1C6B28" fontWeight="bold" fontSize="38" mr={10}>
          Buyer Details
        </Text>
      </Flex>
      <Flex
        pt="20px"
        pl={[2, 10, 59.9]}
        direction={{ base: "column", md: "row" }}
        alignItems={["left", "left", "center"]}
      >
        <BuyerDetails buyersID={buyerID!} />
      </Flex>
      <Flex>
        <Spacer />
        <OrderCompleteButton orderID={ordersID!} />
      </Flex>
    </Box>
  );
}

export default OrderDetails;
