import React from "react";
import {
  Image,
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import PlusIcon from "../images/plus.svg";
import AddProduct from "./AddProduct";

interface AddProductProps {
  setProducts: React.Dispatch<any>;
}
function AddInventoryItemDrawer({ setProducts }: AddProductProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef(null);
  return (
    <>
      <Button
        bgColor="#BC6C25"
        color="white"
        border="1px solid #BC6C25"
        borderRadius="6px"
        _hover={{
          bgColor: "white",
          color: "black",
          border: "2px solid #BC6C25",
        }}
        ref={btnRef}
        onClick={() => {
          onOpen();
        }}
        px={{ base: 4, md: 8 }}
      >
        <Box pr="2px" mr={2}>
          <Image src={PlusIcon} />
        </Box>
        Add New Item
      </Button>
      <Drawer
        data-testid="addInventoryDrawer"
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="sm"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add Inventory Item</DrawerHeader>

          <DrawerBody>
            <AddProduct setProducts={setProducts} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default AddInventoryItemDrawer;
