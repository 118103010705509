/* eslint-disable react/jsx-no-useless-fragment */
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  InputGroup,
  InputLeftElement,
  Button,
  Box,
  Stack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useState } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import { getUserId } from "../services/auth.service";
import "../styles/fontStyle.css";
import "./Errors.css";
import { IProduct } from "../interface/Product.type";
import instance from "../api/api";
import eggSize from "../constants/getEggValue";
import getProductType from "../constants/getProductType";
import productType from "../constants/productType";
import measurements from "../constants/measurementsList";

interface AddProductProps {
  setProducts: React.Dispatch<any>;
}

function AddProduct({ setProducts }: AddProductProps) {
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const blobSasUrl =
    "https://farmai.blob.core.windows.net/farmer-products?sp=racwdl&st=2023-07-19T10:55:53Z&se=2023-08-05T18:55:53Z&sv=2022-11-02&sr=c&sig=YODLP8j0XgvAdQeQ74wXBjraSUfG1waVhLOzugWfnhk%3D";
  const blobServiceClient = new BlobServiceClient(blobSasUrl);

  const containerName = "farmer-products";
  const containerClient = blobServiceClient.getContainerClient(containerName);

  const uploadImage = async (file: any) => {
    try {
      const blobName = `${new Date().getTime()}-${file.name}`;
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);

      // Convert the file to ArrayBuffer before uploading
      const arrayBuffer = await file.arrayBuffer();

      // Upload the ArrayBuffer data
      await blockBlobClient.uploadData(arrayBuffer, {
        blobHTTPHeaders: {
          blobContentType: file.type,
        },
      });

      // Wait for the upload to complete
      await blockBlobClient.uploadData(arrayBuffer);
      return blockBlobClient.url;
    } catch (error) {
      return error;
    }
  };

  const toast = useToast();
  const farmerId = getUserId();

  const [selectedProductType, setSelectedProductType] = useState("");
  const [selectedMeasurementUnit, setSelectedMeasurementUnit] = useState("");

  const handleUpload = async (images: any) => {
    const urls: any[] = [];
    images.map(async (image: any) => {
      const url = await uploadImage(image);
      urls.push(url);
      return url;
    });
    setImageUrls(urls);
  };

  const handleFileChange = async (event: any) => {
    const fileList = event.target.files;
    const selectedImages = [];

    for (let i = 0; i < fileList.length; i += 1) {
      selectedImages.push(fileList.item(i));
    }
    await handleUpload(selectedImages);
  };

  const handleOptionChange = (event: any) => {
    setSelectedProductType(event.target.value);
  };

  const handleMeausurementUnitChange = (event: any) => {
    setSelectedMeasurementUnit(event.target.value);
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<IProduct>();

  const onSubmit = async (data: IProduct) => {
    try {
      const response = await instance.post(
        `/api/v1/farmers/${farmerId}/products`,
        {
          ...data,
          productImage: imageUrls,
        },
      );

      const updatedProducts = await instance.get(
        `api/v1/farmers/${farmerId}/products`,
      );
      setProducts(updatedProducts.data.products);

      toast({
        title: "Stock uploaded",
        description: "Stock uploaded successfully.",
        status: "success",
        duration: 7000,
        isClosable: true,
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          toast({
            title: "Stock not uploaded",
            description: "Unsuccessful stock upload",
            status: "error",
            duration: 7000,
            isClosable: true,
          });
        }
      }
      return "An unexpected error occurred";
    }
  };
  const uploadError = {
    productType: {
      required: "Product type is required",
    },
    productName: {
      required: "Product name is required",
    },
    askingPrice: {
      required: "Product price is required",
      pattern: {
        value: /^[0-9]+$/,
        message: "Only numbers are required",
      },
    },
    quantity: {
      required: "Quantity is required",
      min: {
        value: 1,
        message: "Quantity can not be less than 1",
      },
    },
    unitOfMeasurement: {
      required: "Unit of Measurement is required",
    },
    ageInWeeks: {
      required: "Age in weeks is required",
      min: {
        value: 1,
        message: "Weeks can not be less than 1",
      },
    },
  };
  return (
    <div>
      <Stack>
        <Box>
          <Box py={10} mt="-25">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack mt={2} spacing={4}>
                <FormControl>
                  <FormLabel fontWeight={500} className="label">
                    Product Type
                  </FormLabel>
                  <Select
                    id="productType"
                    {...register("productType", {
                      required: "Please select the product you want to sell",
                    })}
                    value={selectedProductType}
                    onChange={handleOptionChange}
                  >
                    <option value="">Select Product Type</option>
                    {getProductType.map((option) => (
                      <option key={option.id} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                {selectedProductType === productType.Eggs && (
                  <FormControl mt={3} mb={4}>
                    <FormLabel fontWeight={500} className="label">
                      EGG SIZE
                    </FormLabel>
                    <Select
                      id="eggSize"
                      {...register("eggSize", {
                        required: "Please select your egg size",
                      })}
                    >
                      <option value="">Select Egg Size</option>
                      {eggSize.map((option) => (
                        <option key={option.id} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {selectedProductType !== productType.Eggs && (
                  <FormControl mt={3} mb={4}>
                    <FormLabel
                      htmlFor="productName"
                      fontWeight={500}
                      className="label"
                    >
                      Product Name
                    </FormLabel>
                    <Input
                      id="productName"
                      placeholder="Name of product"
                      fontWeight={400}
                      {...register("productName", uploadError.productName)}
                    />
                    {errors.productName && (
                      <p className="error">{errors.productName?.message}</p>
                    )}
                  </FormControl>
                )}
                {selectedProductType === productType.Livestock && (
                  <FormControl mt={3} mb={4}>
                    <FormLabel
                      htmlFor="ageInWeeks"
                      fontWeight={500}
                      className="label"
                    >
                      AGE IN WEEKS
                    </FormLabel>
                    <Input
                      id="ageInWeeks"
                      placeholder="2 Weeks"
                      fontWeight={400}
                      type="number"
                      {...register("ageInWeeks", uploadError.ageInWeeks)}
                    />
                    {errors.ageInWeeks && (
                      <p className="error">{errors.ageInWeeks?.message}</p>
                    )}
                  </FormControl>
                )}
                <FormControl>
                  <FormLabel fontWeight={500} className="label">
                    Quantity
                  </FormLabel>
                  <NumberInput
                    defaultValue={50}
                    min={50}
                    clampValueOnBlur={false}
                  >
                    <NumberInputField
                      {...register("quantity", uploadError.quantity)}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  {errors.quantity && (
                    <p className="error">{errors.quantity?.message}</p>
                  )}
                </FormControl>
                <FormControl mt={3} mb={4}>
                  <FormLabel fontWeight={500} className="label">
                    Unit Of Measurement
                  </FormLabel>
                  <Select
                    id="unitOfMeasurement"
                    {...register("unitOfMeasurement", {
                      required: "Please indicate a unit of measurement",
                    })}
                    onChange={handleMeausurementUnitChange}
                  >
                    <option value="">Select Unit Of Measurement</option>
                    {measurements.map((option) => (
                      <option key={option.id} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel
                    htmlFor="askingPrice"
                    fontWeight={500}
                    className="label"
                  >
                    Price Per {selectedMeasurementUnit}
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement> M </InputLeftElement>
                    <Input
                      id="askingPrice"
                      w={100}
                      fontWeight={400}
                      placeholder="350"
                      {...register("askingPrice", uploadError.askingPrice)}
                    />
                  </InputGroup>
                  {errors.askingPrice && (
                    <p className="error">{errors.askingPrice?.message}</p>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel
                    htmlFor="productImage"
                    fontWeight={500}
                    className="label"
                  >
                    Upload an image of your product
                  </FormLabel>
                  <Input
                    id="productImage"
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    fontWeight={400}
                  />
                </FormControl>
              </Stack>
              <Button
                size="lg"
                mt={2}
                w="full"
                bgColor="#BC6C25"
                color="white"
                _hover={{
                  bgColor: "white",
                  color: "black",
                  border: "2px solid #BC6C25",
                }}
                type="submit"
                isLoading={isSubmitting}
              >
                Add Product
              </Button>
            </form>
          </Box>
        </Box>
      </Stack>
    </div>
  );
}

export default AddProduct;
