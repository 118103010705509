import { ReactNode } from "react";
import {
  Box,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  HStack,
} from "@chakra-ui/react";
import { AiOutlineMail } from "react-icons/ai";
import { FiPhone, FiTwitter, FiYoutube } from "react-icons/fi";
import { RiFacebookBoxLine, RiLinkedinBoxLine } from "react-icons/ri";
import { BsInstagram } from "react-icons/bs";
import { isLoggedIn } from "../constants/auth";

function ListHeader({ children }: { children: ReactNode }) {
  return (
    <Text fontWeight="500" fontSize="lg" mb={2}>
      {children}
    </Text>
  );
}

export default function Footer() {
  return (
    <Box bg="#303133" color="whiteAlpha.900" data-testid="footer">
      <Container as={Stack} maxW="6xl" py={5}>
        <SimpleGrid
          templateColumns={{ sm: "1fr 1fr", md: "1fr 1fr 1fr 1fr" }}
          spacing={1}
        >
          <Stack align="flex-start">
            <ListHeader>About us</ListHeader>
            <Link href="/home" data-testid="company">
              Our Company
            </Link>
          </Stack>
          <Stack align="flex-start" py={["3rem", "3rem", "0rem"]}>
            <ListHeader>Farmer</ListHeader>
            {!isLoggedIn && (
              <>
                <Link href="/register" data-testid="farmersignup">
                  Sign up
                </Link>
                <Link href="/login">Login</Link>
              </>
            )}
          </Stack>
          <Stack align="flex-start">
            <ListHeader>Buyer</ListHeader>
            {!isLoggedIn && (
              <>
                <Link href="/register">Sign up</Link>
                <Link href="/login" data-testid="login">
                  Login
                </Link>
              </>
            )}
          </Stack>
          <Stack align="flex-start" py={["3rem", "3rem", "0rem"]}>
            <ListHeader>Contact Us</ListHeader>
            <HStack>
              <FiPhone fontSize={25} />
              <Text data-testid="phone">+266 58946217</Text>
            </HStack>
            <HStack>
              <AiOutlineMail fontSize={25} />
              <Text>hello@farmai.africa</Text>
            </HStack>
            <HStack>
              <RiFacebookBoxLine fontSize={25} />
              <BsInstagram fontSize={22} />
              <FiTwitter fontSize={25} />
              <RiLinkedinBoxLine fontSize={25} />
              <FiYoutube fontSize={25} />
            </HStack>
          </Stack>
        </SimpleGrid>
        <Stack py={10}>
          <Text fontSize="lg" textAlign="center">
            © 2022 FarmAI. All rights reserved
          </Text>
        </Stack>
      </Container>
    </Box>
  );
}
