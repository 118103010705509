import React, { SyntheticEvent, useEffect, useState } from "react";
import { Box, Flex, Text, Input, Button, Link } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  getRemoteConfig,
  fetchAndActivate,
  getValue,
} from "firebase/remote-config";
import instance, { interceptorId } from "../api/api";
import "../utils/firebase";
import LoadingSpinner from "../components/LoadingSpinner";
import LoginWithPassword from "./LoginWithPassword";
import validateEmail from "../constants/validateEmail";
import validatePhoneNumber from "../constants/validatePhoneNumber";

instance.interceptors.response.eject(interceptorId);
type ILogin = {
  input: string;
};

export default function Login() {
  const [IS_MFA_ENABLED, SET_IS_MFA_ENABLED] = useState(false);

  const remoteConfig = getRemoteConfig();
  remoteConfig.settings.minimumFetchIntervalMillis = 15000;
  fetchAndActivate(remoteConfig)
    .then(() => {
      SET_IS_MFA_ENABLED(getValue(remoteConfig, "IS_MFA_ENABLED").asBoolean());
    })
    .catch(() => {
      return <LoadingSpinner />;
    });

  const navigate = useNavigate();
  const isValid = (errors: ILogin) => {
    if (errors.input === "") {
      return true;
    }
    return false;
  };

  const initialValue = {
    input: "",
  };

  const [formValue, setFormValue] = useState(initialValue);
  const [formErrors, setFormError] = useState({
    input: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const validate = (value: ILogin) => {
    let isEmail = false;
    let isPhoneNumber = false;

    const errors = {
      input: "",
    };

    const textRegex = /^[a-z]/i;
    if (!value.input) {
      errors.input = "Phone Number or Email Required";
    }

    if (value.input.includes("@") || textRegex.test(value.input))
      isEmail = true;
    if (isEmail) {
      if (!validateEmail().test(value.input)) {
        errors.input = "Invalid email format";
      }
    }

    if (validatePhoneNumber().test(value.input)) {
      isPhoneNumber = true;
    }

    if (isPhoneNumber) {
      if (value.input.length !== 8) {
        errors.input = "Invalid phone number";
      }
    }

    return errors;
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    setFormError(validate(formValue));
    setIsSubmitting(true);
  };

  useEffect(() => {
    const handleLogin = async (data: ILogin) => {
      try {
        setIsLoggingIn(true);
        setApiResponse("");
        const res = await instance
          .post("/login", {
            contact: data.input,
          })
          .then((response) => {
            setApiResponse(response.data.message);
            formValue.input = "";
            navigate("/verify", { state: { route: "/login" } });
            return response;
          })
          .catch((error) => {
            setIsLoggingIn(error.response.data.success);
            setApiResponse(error.response.data.message);
            return error;
          });
        return res;
      } catch (error) {
        setIsLoggingIn(false);
        return error;
      }
    };
    if (isValid(formErrors) && isSubmitting) {
      handleLogin(formValue);
    }
  }, [formErrors, formValue, isSubmitting, navigate]);

  return (
    <div>
      {IS_MFA_ENABLED ? (
        <form onSubmit={handleSubmit} noValidate>
          <Flex
            align="center"
            direction="column"
            justifyContent="center"
            alignSelf="center"
            mt="6em"
            ml={["11%", "16%", "25%", "33%", "34%"]}
            w={[300, 400, 500]}
            border="1px solid #DADADA"
            alignItems="center"
          >
            <Box
              border="2px solid green"
              w={[300, 400, 500]}
              textAlign="center"
              height="100px"
              backgroundColor="#1C6B28"
            >
              <Text mt="5%" color="white" fontSize="3xl" fontWeight={400}>
                Login
              </Text>
            </Box>
            <Input
              borderColor="#D9D9D9"
              textAlign="center"
              placeholder="Email Address"
              name="input"
              required
              value={formValue.input}
              onChange={handleChange}
              w={[250, 350, 450]}
              mt="32px"
              mb="22px"
              fontWeight={700}
            />
            {formErrors.input && (
              <span className="error">{formErrors.input}</span>
            )}
            {apiResponse !== null && (
              <span className="response">{apiResponse}</span>
            )}
            <Button
              type="submit"
              w={[250, 350, 450]}
              p="7"
              fontSize="xl"
              colorScheme="brand"
              isLoading={isLoggingIn}
            >
              Submit
            </Button>
            <Flex direction="column" alignItems="center" mt={5} mb={7}>
              <Text p={1}>Don&#39;t have an account?</Text>
              <Text p={1} color="#1C6B28" fontWeight={700}>
                Create an account as a
              </Text>
              <Text p={1} color="#1C6B28" fontWeight={400}>
                <Link href="/register">Farmer </Link> |{" "}
                <Link href="/register">Buyer </Link>
              </Text>
            </Flex>
          </Flex>
        </form>
      ) : (
        <LoginWithPassword />
      )}
    </div>
  );
}
