import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Box,
  Button,
  CircularProgress,
  Flex,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import instance from "../api/api";
import ErrorHandling from "../utils/errorHandling";

interface OrderCompleteButtonProps {
  orderID: string;
}

function OrderCompleteButton({ orderID }: OrderCompleteButtonProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessfulSold, setIsSuccessfullySold] = useState(false);
  const [openAlertComplete, setOpenAlertComplete] = useState(false);
  const closeAlertComplete = () => setOpenAlertComplete(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const toast = useToast();
  const navigate = useNavigate();

  const updateOrderToSold = async (orderId: string) => {
    setIsLoading(true);

    try {
      await instance.put(`/admin/complete-order/${orderId}`);
      setIsLoading(false);
      toast({
        title: "Order sold",
        description: "Order successfully sold",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIsSuccessfullySold(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setIsLoading(false);
        toast({
          title: "Order not sold",
          description: "Order sold unsuccessfully",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        setIsSuccessfullySold(false);
        ErrorHandling(error);
      }
    }
  };

  const renderCompleteButton = (
    <div>
      {isSuccessfulSold ? (
        <Box w="100%">
          <Button
            w="100%"
            bg="#BC6C25"
            color="white"
            _hover={{ bgColor: "gray.300", color: "#FE4E00" }}
            fontSize="20px"
            onClick={() => {
              navigate("/dashboard/all-orders");
            }}
          >
            Go back to orders
          </Button>
        </Box>
      ) : (
        <Flex>
          <Box w="100%">
            <Button
              bg="#BC6C25"
              color="white"
              w="100%"
              variant="solid"
              borderColor="#BC6C25"
              mr={3}
              onClick={() => setOpenAlertComplete(true)}
              _hover={{
                background: "#606C38",
              }}
            >
              Complete Order
            </Button>
          </Box>

          {openAlertComplete && (
            <AlertDialog
              leastDestructiveRef={cancelRef}
              isOpen={openAlertComplete}
              onClose={closeAlertComplete}
            >
              <AlertDialogContent>
                <AlertDialogHeader>Complete Order</AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure you want complete this order and set it as SOLD?
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={closeAlertComplete}>
                    Cancel
                  </Button>
                  <Button
                    color="white"
                    bg="#BC6C25"
                    _hover={{
                      background: "#606C38",
                    }}
                    onClick={() => {
                      updateOrderToSold(orderID);
                      setOpenAlertComplete(false);
                    }}
                    ml={3}
                  >
                    Complete Order
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
        </Flex>
      )}
    </div>
  );

  return (
    <div>
      {isLoading ? (
        <Box w="100%" mr={[10, 10, 20]} mb={30}>
          <Button
            w="100%"
            bg="#BC6C25"
            color="#fff"
            _hover={{ bgColor: "gray.300", color: "#1C6B28" }}
            fontSize="20px"
            disabled={isLoading}
          >
            <CircularProgress
              isIndeterminate
              color="green"
              size="30px"
              mr={5}
            />
          </Button>
        </Box>
      ) : (
        renderCompleteButton
      )}
    </div>
  );
}

export default OrderCompleteButton;
