/* eslint-disable react/no-children-prop */
import {
  Box,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import React, { useState } from "react";
import source from "../../images/searchIcon.png";

function AllUsersSearchBar({ placeholder }: { placeholder: string }) {
  const [searchKey, setSearchKey] = useState("");

  const handleChange = (event: any) => {
    setSearchKey(event.target.value);
  };
  return (
    <Box mr={15}>
      <InputGroup size="sm">
        <InputLeftElement>
          <Image src={source} width="20px" height="20px" />
        </InputLeftElement>
        <Input
          type="text"
          placeholder={placeholder}
          value={searchKey}
          borderRadius="6px"
          onChange={handleChange}
        />
      </InputGroup>
    </Box>
  );
}

export default AllUsersSearchBar;
