// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getRemoteConfig } from "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyCy3X5_DgKNkvTPNS5q7mWOl0k3znAFq7A",
  authDomain: "farmai-b4d55.firebaseapp.com",
  projectId: "farmai-b4d55",
  storageBucket: "farmai-b4d55.appspot.com",
  messagingSenderId: "83886529293",
  appId: "1:83886529293:web:436752277b49feddedee44",
  measurementId: "G-YGB8LW7B0B",
};

initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 15000;

export default firebaseConfig;
