import { Box, Flex, Text, Hide } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import useSWR from "swr";
import instance from "../../api/api";
import AllOrdersTable from "../../components/AdminDashboard/AllOrdersTable";
import OverviewCard from "../../components/AdminDashboard/OverviewCard";
import Header from "../../components/AdminHeader";
import SearchButton from "../../components/SearchButton";
import SortByButton from "../../components/SortByButton";
import totalEggsSoldIcon from "../../images/totalEggsSold.svg";
import totalOrdersSoldIcon from "../../images/totalOrdersSold.svg";
import { IOrder } from "../../models/Orders.type";
import OrderStatus from "../../constants/orderStatus";

function AdminAllOrders() {
  const [traysSold, setTraysSold] = useState<number>(0);
  const [pendingOrders, setPendingOrders] = useState<[]>([]);
  const [totalSales, setTotalSales] = useState<number>(0);

  const sendRequest = async (url: string) => {
    const response = await instance.get(url);

    const allOrders = response.data.orders;

    return allOrders;
  };

  const { data: userOrders, error: ordersError } = useSWR(
    "/api/v1/orders",
    sendRequest,
  );

  useEffect(() => {
    if (userOrders) {
      const soldOrders = userOrders.filter(
        (order: IOrder) => order.status === OrderStatus.OrderStatus.SOLD,
      );
      const totalTraysSold = soldOrders.reduce(
        (total: number, order: IOrder) => total + order.quantity,
        0,
      );
      setTraysSold(totalTraysSold);
      const pendingOrdersList = userOrders.filter(
        (order: IOrder) => order.status === OrderStatus.OrderStatus.PENDING,
      );
      setPendingOrders(pendingOrdersList);

      const totalSalesAmount = soldOrders.reduce(
        (total: number, order: IOrder) => total + order.price,
        0,
      );
      setTotalSales(totalSalesAmount);
    }
  }, [userOrders]);

  if (ordersError) {
    return (
      <Flex mb={["2", "2", "3"]} pl="10" pr="10" textAlign="center">
        <Text>Failed to load orders.</Text>
      </Flex>
    );
  }

  return (
    <Box>
      <Header />
      <Text color="#303133" fontWeight="bold" fontSize="38" pb="5" p="10">
        All Orders
      </Text>
      <Hide>
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={["left", "left", "center"]}
        >
          <SearchButton placeholder="Search for orders" />
          <SortByButton />
        </Flex>
      </Hide>

      <Flex
        pl="10"
        pr="10"
        mb={["2", "2", "3"]}
        gap="20px"
        flexDir={["column", "row"]}
      >
        <OverviewCard
          title="Trays Sold"
          amount={traysSold}
          imageLocation={totalEggsSoldIcon}
        />
        <OverviewCard
          title="Total Orders"
          amount={userOrders?.length || 0}
          imageLocation={totalOrdersSoldIcon}
        />
        <OverviewCard
          title="Pending Orders"
          amount={pendingOrders.length}
          imageLocation={totalOrdersSoldIcon}
        />
        <OverviewCard
          title="Total Sales"
          amount={`M${totalSales || 0}`}
          imageLocation={totalOrdersSoldIcon}
        />
      </Flex>

      <Flex overflow="auto" flexDir="column" pl={["2", "10"]} pr={["2", "10"]}>
        <AllOrdersTable orders={userOrders !== undefined ? userOrders : []} />
      </Flex>
    </Box>
  );
}

export default AdminAllOrders;
