import {
  Stack,
  HStack,
  Text,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Tab,
  Heading,
  Box,
  Image,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BuyerRegister from "../components/BuyerRegister";
import FarmerRegister from "../components/FarmerRegister";
import Image1 from "../images/Registration-Images/Image1.svg";
import Image2 from "../images/Registration-Images/Image2.svg";
import Image3 from "../images/Registration-Images/Image3.svg";
import Image4 from "../images/Registration-Images/Image4.svg";
import Logo from "../images/logo.svg";
import DefaultHeader from "../components/DefaultHeader";

export default function Registration() {
  const IMAGES = [Image1, Image2, Image3, Image4];
  const [tabIndex, setTabIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % IMAGES.length);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [IMAGES.length]);

  const handleTabClick = (index: React.SetStateAction<number>) => {
    setTabIndex(index);
  };

  return (
    <div>
      <DefaultHeader />
      <HStack bgColor="#FEFAE0" alignItems="flex-start" mt="-0.2em">
        <Stack
          w={{ base: "100%", md: "50%" }}
          mt={{ base: "1em", md: "2.4em" }}
          p={10}
        >
          <Box textAlign="center" lineHeight={{ lg: "4em " }}>
            <Heading
              fontWeight={400}
              fontSize={{ base: "1.5em", sm: "2em", lg: "3em", xl: "4em" }}
            >
              Create an account
            </Heading>
            {tabIndex === 0 ? (
              <Text>
                Let&apos;s get you a spot in the market place started for free
              </Text>
            ) : (
              <Text>Let&apos;s get you closer to fresh produce</Text>
            )}
          </Box>
          <Tabs
            isFitted
            colorScheme="orange"
            color="black"
            index={tabIndex}
            onChange={handleTabClick}
            borderBottomColor="white"
          >
            <TabList mb="1em">
              <Tab>Farmer</Tab>
              <Tab>Buyer</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <FarmerRegister />
              </TabPanel>
              <TabPanel>
                <BuyerRegister />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
        <Stack
          w="50%"
          justifyContent="right"
          align="right"
          height="100vh"
          display={{ base: "none", md: "inherit" }}
          position="fixed"
          right={0}
        >
          <Image
            objectFit="cover"
            height="100%"
            width="100%"
            src={IMAGES[currentImageIndex]}
          />
          <Box position="absolute" w="full">
            <Image w={200} h={200} src={Logo} />
          </Box>
        </Stack>
      </HStack>
    </div>
  );
}
