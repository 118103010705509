import { Box, VStack, Text } from "@chakra-ui/react";
import React from "react";

interface OrderCardProps {
  title: string;
  value: string | null | undefined;
}

function OrderCard({ title, value }: OrderCardProps) {
  return (
    <Box
      bgColor="#F5F5F5"
      w={[24, 24, 320]}
      h={[24, 24, 104]}
      mr={[2, 10, 10]}
      pl={[2, 2, 6]}
      pt={[1, 1, 6]}
    >
      {title === "Price" ? (
        <VStack alignItems="left">
          <Text fontWeight="bold" fontSize={18}>
            {title}
          </Text>
          <Text>M {value}</Text>
        </VStack>
      ) : (
        <VStack alignItems="left">
          <Text fontWeight="bold" fontSize={18}>
            {title}
          </Text>
          <Text>{value}</Text>
        </VStack>
      )}
    </Box>
  );
}

export default OrderCard;
