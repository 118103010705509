import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import convertToDataUtils from "../../utils/convertOrdersData";

export default function OrderSummaryGraph({ graphData }: { graphData: [] }) {
  const data = convertToDataUtils.convertToData(graphData);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="complete" fill="#606C38" />
        <Bar dataKey="pending" fill="#BC6C25" />
      </BarChart>
    </ResponsiveContainer>
  );
}
