import React from "react";
import AOS from "aos";
import * as amplitude from "@amplitude/analytics-browser";
import MainRoutes from "./routes";

function App() {
  amplitude.init("13a609bcc394bde63b28372e9c23808d", undefined, {
    defaultTracking: {
      sessions: true,
      pageViews: true,
      formInteractions: true,
      fileDownloads: true,
    },
  });
  AOS.init();
  return (
    <div className="App">
      <MainRoutes />
    </div>
  );
}

export default App;
