import { Box, Flex, Text, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";

export default function Success() {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/");
  };
  return (
    <div>
      <Header />
      <Flex justifyContent="center">
        <Flex
          align="center"
          direction="column"
          mt="6em"
          border="1px solid #DADADA"
          alignItems="center"
        >
          <Box
            border="2px solid green"
            w={[300, 400, 500]}
            textAlign="center"
            height="100px"
            backgroundColor="#1C6B28"
          >
            <Text mt="5%" color="white" fontSize="3xl" fontWeight={400}>
              Success
            </Text>
          </Box>
          <Flex marginTop="22px" mb="36px" w={[250, 350, 450]}>
            <Text p={2}>
              Congratulations your registration was successful! Please wait for
              your account to be activated. We will contact you for further
              steps.
            </Text>
          </Flex>
          <Button
            w={[250, 350, 450]}
            mb="20px"
            colorScheme="brand"
            p="7"
            fontSize="xl"
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </Flex>
      </Flex>
    </div>
  );
}
