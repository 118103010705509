import {
  Avatar,
  Image,
  Stack,
  Button,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  AvatarGroup,
  Badge,
  Flex,
  ToastId,
} from "@chakra-ui/react";
import eggIcon from "../images/eggIcon.svg";
import chickenIcon from "../images/chickenIcon.svg";
import emailIcon from "../images/emailIcon.svg";
import locationIcon from "../images/locationIcon.svg";
import telephoneIcon from "../images/telephoneIcon.svg";
import chickenLegIcon from "../images/chickenLegIcon.svg";
import farmIcon from "../images/farmIcon.svg";

export interface UserRequestProps {
  userRequestDetails: {
    _id: string;
    firstName: string;
    surname: string;
    emailAddress?: string;
    shopName?: string;
    farmName?: string;
    district: string;
    status: string;
    phoneNumber: number;
    userProfilePicture?: string;
    productType?: string[];
    createdAt: string;
    updatedAt: string;
  };
  updateUserToApproved: (userId: string) => Promise<ToastId>;
  onClose: () => void;
  isOpen: boolean;
  isLoading: boolean;
}

export default function UserRequestDetailsModal({
  userRequestDetails,
  isOpen,
  onClose,
  updateUserToApproved,
  isLoading,
}: UserRequestProps) {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent bg="#FEFAE0">
        <ModalHeader>
          <Stack direction="row">
            <Badge borderRadius="lg" variant="solid" colorScheme="tigersEye">
              Location
            </Badge>
            <Text fontSize="sm">{userRequestDetails.district}</Text>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDir="row">
            <Text mr="5" fontWeight="bold" fontSize="3xl">
              {userRequestDetails.firstName} {userRequestDetails.surname}
            </Text>
            {userRequestDetails.productType && (
              <AvatarGroup pt="1" justifyContent="end" size="sm" max={2}>
                {userRequestDetails.productType.map((product) => {
                  return (
                    <Avatar
                      key={product}
                      border="0px"
                      src={product === "Eggs" ? eggIcon : chickenIcon}
                    />
                  );
                })}
              </AvatarGroup>
            )}
          </Flex>
          <Flex p="3" flexDirection="column" bg="white">
            <Flex p="2">
              <Image w="5" mr="2" src={emailIcon} />
              <Text w="40">Email Address</Text>
              <Text fontSize="sm">{userRequestDetails.emailAddress}</Text>
            </Flex>
            <Flex p="2">
              <Image w="5" mr="2" src={telephoneIcon} />
              <Text w="40">Phone Number</Text>
              <Text>{userRequestDetails.phoneNumber}</Text>
            </Flex>
            <Flex p="2">
              <Image w="5" mr="2" src={locationIcon} />
              <Text w="40">District</Text>
              <Text>{userRequestDetails.district}</Text>
            </Flex>
            {userRequestDetails.productType && (
              <Flex p="2">
                <Image w="5" mr="2" src={chickenLegIcon} />
                <Text w="40">Product Type</Text>
                {userRequestDetails.productType.map((product) => (
                  <Text key={product} mr="1">
                    {product}.
                  </Text>
                ))}
              </Flex>
            )}
            <Flex p="2">
              <Image w="5" mr="2" src={farmIcon} />
              <Text w="40">
                {userRequestDetails.productType ? (
                  <Text>Farm Name </Text>
                ) : (
                  <Text>Shop Name </Text>
                )}
              </Text>
              {userRequestDetails.productType ? (
                <Text>{userRequestDetails.farmName} </Text>
              ) : (
                <Text>{userRequestDetails.shopName} </Text>
              )}
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="tigersEye"
            w="100%"
            isLoading={isLoading}
            onClick={() => {
              updateUserToApproved(userRequestDetails._id);
            }}
            _hover={{
              background: "#303133",
              color: "white",
            }}
          >
            Accept User Request
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
