import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { MdOutlineModeEdit } from "react-icons/md";
import Order from "../models/Orders.type";
import EditOrder from "../pages/Buyer/EditOrder";

interface BuyerEditOrderProps {
  isActive: boolean;
  order: Order;
}

function BuyerEditOrderDrawer({ isActive, order }: BuyerEditOrderProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef(null);
  return (
    <>
      {isActive ? (
        <Button
          data-testid="editButton"
          name="edit"
          role="button"
          w="auto"
          h={[26, 26, 26]}
          fontSize={14}
          borderRadius={6}
          _hover={{
            bgColor: "white",
            color: "black",
            border: "2px solid #BC6C25",
          }}
          bgColor="#BC6C25"
          border="2px solid #BC6C25"
          color="white"
          px={{ base: 4, md: 8 }}
          onClick={() => {
            localStorage.setItem("itemId", order._id);
            localStorage.setItem("orderId", order._id);
            localStorage.setItem("productType", order.productType);
            onOpen();
          }}
        >
          <span>
            <MdOutlineModeEdit />
          </span>{" "}
          Edit
        </Button>
      ) : (
        <Button
          data-testid="editButton"
          name="edit"
          role="button"
          w="auto"
          h={[26, 26, 26]}
          fontSize={14}
          borderRadius={6}
          _hover={{
            bgColor: "white",
            color: "black",
            border: "2px solid #BC6C25",
          }}
          bgColor="#BC6C25"
          border="2px solid #BC6C25"
          color="white"
          cursor="not-allowed"
          ref={btnRef}
          isDisabled
          px={{ base: 4, md: 8 }}
        >
          <span>
            <MdOutlineModeEdit />
          </span>{" "}
          Edit
        </Button>
      )}
      <Drawer
        data-testid="editOrderDrawer"
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="sm"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Edit Order</DrawerHeader>

          <DrawerBody>
            <EditOrder />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default BuyerEditOrderDrawer;
