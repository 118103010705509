import {
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  GridItem,
  Button,
  Flex,
  Heading,
  Box,
  Select,
  Text,
  VStack,
  Avatar,
  HStack,
  useToast,
  Stack,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import instance from "../api/api";
import { IFarmer } from "../models/farmer.type";
import "./Errors.css";
import "../styles/fontStyle.css";
import User from "../images/Vector.svg";
import validateNames from "../constants/validate";
import LoadingSpinner from "./LoadingSpinner";

const validate = validateNames();
export default function AdminUpdateFarmerForm({
  initialValues,
}: {
  initialValues: IFarmer;
}) {
  const userId = localStorage.getItem("farmerId");
  const toast = useToast();

  const districts = [
    { id: 1, value: "Berea", text: "Berea" },
    { id: 2, value: "Butha-Buthe", text: "Butha-Buthe" },
    { id: 3, value: "Leribe", text: "Leribe" },
    { id: 4, value: "Mafeteng", text: "Mafeteng" },
    { id: 5, value: "Maseru", text: "Maseru" },
    { id: 6, value: "Mohale's Hoek", text: "Mohale's Hoek" },
    { id: 7, value: "Mokhotlong", text: "Mokhotlong" },
    { id: 8, value: "Qacha's Nek", text: "Qacha's Nek" },
    { id: 9, value: "Quthing", text: "Quthing" },
    { id: 10, value: "Thaba-Tseka", text: "Thaba-Tseka" },
  ];

  const productTypes = [
    {
      id: 1,
      value: "Eggs",
      text: "Eggs",
    },
    {
      id: 2,
      value: "Poultry",
      text: "Poultry",
    },
    {
      id: 3,
      value: "Poultry,Eggs",
      text: "Poultry & Eggs",
    },
  ];

  const navigate = useNavigate();
  if (!userId) {
    navigate("/login");
  }
  const [userProfile, setUserProfile] = useState(initialValues);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setUserProfile({ ...userProfile, [name]: value });
  };
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<IFarmer>();

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const resetPasswordClicked = () => {
    setIsResetPassword(!isResetPassword);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    let data;
    if (userProfile.password !== "") {
      data = {
        firstName: userProfile.firstName,
        surname: userProfile.surname,
        district: userProfile.district,
        farmName: userProfile.farmName,
        phoneNumber: userProfile.phoneNumber,
        emailAddress: userProfile.emailAddress,
        productType: userProfile.productType,
        password: userProfile.password,
      };
    } else {
      data = {
        firstName: userProfile.firstName,
        surname: userProfile.surname,
        district: userProfile.district,
        farmName: userProfile.farmName,
        phoneNumber: userProfile.phoneNumber,
        emailAddress: userProfile.emailAddress,
        productType: userProfile.productType,
      };
    }
    try {
      const response = await instance.put(`/api/v1/farmers/${userId}`, data);
      setIsLoading(false);
      toast({
        title: "Profile Updated",
        description: "Profile updated successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          setIsLoading(false);
          toast({
            title: "Profile not updated",
            description: "Profile could not be updated",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          return error;
        }
      }
      return "An unexpected error occurred";
    }
  };

  const renderUpdateFarmer = (
    <Flex
      w="full"
      h="full"
      p={10}
      alignItems="flex-start"
      direction={{ base: "column", md: "row" }}
    >
      <Box w="full">
        <Flex
          direction={{ base: "column", md: "row" }}
          align={{ base: "center" }}
          ml={[0, 0, 50]}
        >
          <Avatar src={User} size={{ base: "xl", md: "xl" }} />
          <VStack p={6} align="flex-start">
            <HStack>
              <Text size="sm">{userProfile?.firstName}</Text>
              <Text size="sm">{userProfile?.surname}</Text>
            </HStack>
            <Flex align={{ base: "center" }}>
              <Text size="sm">Farmer</Text>
            </Flex>
          </VStack>
        </Flex>
        <Box w={{ base: "full", md: "65%" }} ml={[0, 0, 50]}>
          <hr className="line" />
        </Box>

        <Heading
          p={6}
          fontSize={{ base: "xl" }}
          fontWeight={500}
          ml={[0, 0, 30]}
        >
          Personal Information
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <SimpleGrid
            columns={2}
            columnGap={3}
            rowGap={6}
            w={{ base: "full", md: "65%" }}
            py={25}
            ml={[0, 0, 50]}
          >
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel fontWeight={700} className="label">
                  first name
                </FormLabel>
                <Input
                  placeholder="John"
                  value={userProfile.firstName}
                  {...register("firstName", {
                    required: "First name is required",
                    minLength: 3,
                    pattern: {
                      value: validate,
                      message: "Invalid character!!",
                    },
                    onChange: (e) => {
                      handleInputChange(e);
                    },
                  })}
                />
                {errors?.surname && (
                  <p className="error">{errors.surname.message}</p>
                )}
                {errors?.surname?.type === "minLength" && (
                  <p className="error">
                    Last name must be atleast 3 characters long
                  </p>
                )}
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel fontWeight={700} className="label">
                  last name
                </FormLabel>
                <Input
                  placeholder="Doe"
                  value={userProfile?.surname}
                  {...register("surname", {
                    required: "Last name is required",
                    minLength: 3,
                    pattern: {
                      value: validate,
                      message: "Invalid character!!",
                    },
                  })}
                  onChange={handleInputChange}
                />
                {errors?.surname && (
                  <p className="error">{errors.surname.message}</p>
                )}
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel
                  htmlFor="productType"
                  fontWeight={700}
                  className="label"
                >
                  product type
                </FormLabel>
                <Select
                  id="productType"
                  {...register("productType", {
                    required: "Product type is required",
                  })}
                  onChange={handleInputChange}
                  value={userProfile.productType}
                >
                  {productTypes.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </Select>
                {errors?.productType && (
                  <p className="error">{errors.productType.message}</p>
                )}
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel
                  htmlFor="district"
                  fontWeight={700}
                  className="label"
                >
                  district
                </FormLabel>
                <Select
                  id="district"
                  {...register("district")}
                  value={userProfile.district}
                  onChange={handleInputChange}
                >
                  {districts.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </Select>
                {errors?.district && (
                  <p className="error">{errors.district.message}</p>
                )}
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl>
                <FormLabel fontWeight={700} className="label">
                  email address
                </FormLabel>
                <Input
                  placeholder="farmai@gmail.com"
                  value={userProfile?.emailAddress}
                  {...register("emailAddress", {
                    required: "Email address is required",
                  })}
                  onChange={handleInputChange}
                />
                {errors?.emailAddress && (
                  <p className="error">{errors?.emailAddress?.message}</p>
                )}
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel fontWeight={700} className="label">
                  phone number
                </FormLabel>
                <Input
                  placeholder="56214440"
                  type="number"
                  value={userProfile?.phoneNumber}
                  {...register("phoneNumber", {
                    required: "Phone number is required",
                    minLength: 8,
                    maxLength: 8,
                  })}
                  onChange={handleInputChange}
                />
                {errors?.phoneNumber && (
                  <p className="error">{errors?.phoneNumber?.message}</p>
                )}
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel fontWeight={700} className="label">
                  farm name
                </FormLabel>
                <Input
                  placeholder="Farm name"
                  {...register("farmName", {
                    required: "Farm name is required",
                  })}
                  value={userProfile?.farmName}
                  onChange={handleInputChange}
                />
                {errors?.farmName && (
                  <p className="error">{errors.farmName.message}</p>
                )}
              </FormControl>
            </GridItem>
            {isResetPassword && (
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <FormControl isRequired>
                  <FormLabel fontWeight={700} className="label">
                    password
                  </FormLabel>
                  <Input
                    w="90%"
                    placeholder="Password"
                    type={passwordShown ? "text" : "password"}
                    {...register("password", {
                      required: "Password is required",
                      minLength: 6,
                    })}
                    onChange={handleInputChange}
                  />
                  <Stack
                    mt="-1.7em"
                    mb="0.7em"
                    flexDirection="row"
                    justifyContent="right"
                    cursor="pointer"
                  >
                    {!passwordShown ? (
                      <BsFillEyeFill onClick={togglePassword} />
                    ) : (
                      <BsFillEyeSlashFill onClick={togglePassword} />
                    )}
                  </Stack>
                  {errors?.password && (
                    <p className="error">{errors.password.message}</p>
                  )}
                  {errors?.password?.type === "minLength" && (
                    <p className="error">
                      Password must be at least 6 characters long
                    </p>
                  )}
                </FormControl>
              </GridItem>
            )}
            <GridItem colSpan={{ base: 2, md: 2 }}>
              <Flex>
                <Button
                  size="lg"
                  w="full"
                  mt="2rem"
                  colorScheme="brand"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Update
                </Button>
                <Button
                  size="lg"
                  w="110%"
                  color="white"
                  mt="2rem"
                  ml={2}
                  bgColor="red.500"
                  _hover={{
                    bgColor: "red.300",
                  }}
                  onClick={() => resetPasswordClicked()}
                >
                  Reset <br /> Password
                </Button>
                <Button
                  size="lg"
                  w="full"
                  mt="2rem"
                  ml={2}
                  bgColor="gray.300"
                  type="submit"
                  disabled={isLoading}
                  onClick={() => navigate("/dashboard/all-users")}
                >
                  Cancel
                </Button>
              </Flex>
            </GridItem>
          </SimpleGrid>
        </form>
      </Box>
    </Flex>
  );

  return <div>{isLoading ? <LoadingSpinner /> : renderUpdateFarmer}</div>;
}
