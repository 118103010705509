import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Image,
  FormControl,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  GridItem,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import Order from "../models/Orders.type";
import EggTray from "../images/eggTray.svg";
import instance from "../api/api";
import { getUserId } from "../services/auth.service";
import { PRODUCT } from "../constants/productType";

export default function ProductCard({
  size,
  price,
  quantity,
  userRole,
  productType,
  productName,
  imageUrl,
  unitOfMeasurement,
}: {
  size: string;
  price: number;
  quantity: number;
  userRole: string | null;
  productType: string;
  productName: string;
  imageUrl?: string[];
  unitOfMeasurement?: string;
}) {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm<Order>();
  const userId = getUserId();
  const toast = useToast();
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNextImage = () => {
    if (imageUrl != null) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrl.length);
    }
  };

  const handlePreviousImage = () => {
    if (imageUrl != null) {
      setCurrentIndex((prevIndex) => (prevIndex - 1) % imageUrl.length);
    }
  };

  const onSubmit = async (data: Order) => {
    let theOrder;
    const totalPrice = Number(data.price) * Number(data.quantity);
    if (productType === PRODUCT.Eggs) {
      theOrder = {
        quantity: data.quantity,
        productType,
        price: totalPrice,
        eggSize: size?.toLowerCase(),
      };
    } else {
      theOrder = {
        quantity: data.quantity,
        productType,
        price: totalPrice,
      };
    }
    if (userRole === "Buyer") {
      try {
        await instance.post(`/api/v1/buyers/${userId}/order`, theOrder);
        toast({
          title: "Order created",
          description: "Order placed successfully, see cart to view orders",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        navigate("/dashboard/buyer-dashboard");
        return data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            toast({
              title: "Order not created",
              description: "Order could not be placed",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          }
        }
        return "An unexpected error occured";
      }
    } else {
      toast({
        title: "Order not created",
        description: "Please login as a buyer to place an order",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
      return "Unauthorized";
    }
  };

  const setImageSize = () => {
    if (size === "Small") {
      return <Image w={140} h={169} src={EggTray} />;
    }

    if (size === "Medium") {
      return <Image w={220} h={169} src={EggTray} />;
    }

    if (size === "Large") {
      return <Image src={EggTray} />;
    }

    return (
      <Image w={220} h={169} src={imageUrl ? imageUrl[currentIndex] : ""} />
    );
  };

  return (
    <GridItem
      textAlign="center"
      justifyContent="center"
      w={{ base: "100%", md: "full" }}
      alignItems="center"
      borderRadius="10px"
      boxShadow="0px 0px 36px 5px rgba(48, 49, 51, 0.16)"
    >
      <Box textAlign="center" height="100px" justifyContent="center">
        {productType === PRODUCT.Eggs ? (
          <Text mt="5%" color="#352E33" fontSize="3xl" fontWeight={700}>
            {size} Eggs ({size[0]})
          </Text>
        ) : (
          <Text mt="5%" color="#352E33" fontSize="3xl" fontWeight={700}>
            {productName}
          </Text>
        )}
        <Text
          fontWeight={600}
          {...register("price", {
            value: price,
          })}
        >
          <span>Price: </span>
          {`M${price}`}
        </Text>
        {productType === PRODUCT.Eggs ? (
          <Text fontWeight={600} textAlign="center">
            In Stock: {quantity} Trays
          </Text>
        ) : (
          <Text fontWeight={600} textAlign="center">
            In Stock: {quantity} {unitOfMeasurement}
          </Text>
        )}
      </Box>
      <Flex justifyContent="center" align="center">
        {imageUrl && imageUrl.length > 1 && (
          <Button onClick={handlePreviousImage}>{"<"}</Button>
        )}
        <Box justifyContent="center">{setImageSize()}</Box>
        {imageUrl && imageUrl.length > 1 && (
          <Button onClick={handleNextImage}>{">"}</Button>
        )}
      </Flex>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          textAlign="center"
          flexDirection="column"
          justifyContent="center"
          w="100%"
        >
          <FormControl>
            <Flex justifyContent="center" p={3}>
              <Box mr={10}>
                <Text fontSize="2xl" fontWeight={100}>
                  Quantity
                </Text>
              </Box>
              <Box maxW={20}>
                <NumberInput
                  defaultValue={1}
                  min={1}
                  max={Number(quantity)}
                  clampValueOnBlur={false}
                >
                  <NumberInputField
                    {...register("quantity", {
                      required: "Quantity is required",
                      min: 1,
                    })}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Box>
            </Flex>
          </FormControl>
        </Flex>
        <Button
          w="80%"
          mb="20px"
          bgColor="#BC6C25"
          color="white"
          type="submit"
          p={7}
          fontSize="xl"
          isDisabled={quantity === 0 || userRole !== "Buyer"}
          isLoading={isSubmitting}
          _hover={{
            bgColor: "white",
            color: "black",
            border: "2px solid #BC6C25",
          }}
        >
          Place Order
        </Button>
      </form>
    </GridItem>
  );
}
