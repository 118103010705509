import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  useColorModeValue,
  useDisclosure,
  Show,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { NavLink } from "react-router-dom";
import Logo from "../Logo";
import "../Errors.css";
import headerSelector from "../../utils/HeaderSelector";
import "../../styles/fontStyle.css";
import { Logout } from "../../context/Auth/AuthContext";
import { isLoggedIn } from "../../constants/auth";

const NAV_ITEMS: Array<NavItem> = headerSelector();

function DesktopNav() {
  const linkColor = useColorModeValue("white", "white");
  const linkHoverColor = useColorModeValue("white", "white");
  const activeColor = useColorModeValue("wheat", "wheat");

  return (
    <Box>
      <Stack direction="row" spacing={1} p={3}>
        {/* <Center> */}
        {NAV_ITEMS.map((navItem) => (
          <Box key={navItem.label}>
            <Popover trigger="hover" placement="bottom-start">
              <PopoverTrigger>
                <Link
                  as={NavLink}
                  p={10}
                  to={navItem.href ?? "#"}
                  fontSize="md"
                  fontWeight={700}
                  color={linkColor}
                  _hover={{
                    textDecoration: "none",
                    color: linkHoverColor,
                  }}
                  _activeLink={{
                    color: activeColor,
                  }}
                >
                  {navItem.label}
                </Link>
              </PopoverTrigger>
            </Popover>
          </Box>
        ))}
        {/* </Center> */}
      </Stack>
    </Box>
  );
}

function MobileNavItem({ label, children, href }: NavItem) {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify="space-between"
        align="center"
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text fontWeight={700} color="white">
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition="all .25s ease-in-out"
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle="solid"
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align="start"
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
}

function MobileNav() {
  return (
    <Stack bg="#1C6B28" p={4} display={{ md: "none" }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem
          key={navItem.label}
          label={navItem.label}
          href={navItem.href}
        />
      ))}
    </Stack>
  );
}
export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();
  const linkColor = useColorModeValue("white", "white");
  const linkHoverColor = useColorModeValue("white", "white");

  return (
    <Box className="header">
      <Flex
        flexDirection="row-reverse"
        bg="#162117"
        data-testid="navbar"
        color={useColorModeValue("white.900", "white")}
        minH="60px"
        py={{ base: 2 }}
        px={{ base: 15 }}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align="center"
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            color="whiteAlpha.900"
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant="ghost"
            aria-label="Toggle Navigation"
          />
        </Flex>
        <Flex justifyContent="space-between" w="full">
          <Logo />

          <Flex mt="1.56em" display={{ base: "none", md: "flex" }} ml={10}>
            <DesktopNav />
          </Flex>
          {isLoggedIn && (
            <Show above="md">
              <Box mt="2.2em" onClick={() => Logout()}>
                <Link
                  href="/home"
                  fontSize="md"
                  fontWeight={700}
                  color={linkColor}
                  _hover={{
                    textDecoration: "none",
                    color: linkHoverColor,
                  }}
                >
                  Logout
                </Link>
              </Box>
            </Show>
          )}
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}
interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}
