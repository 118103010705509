import {
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  GridItem,
  Button,
  Flex,
  Heading,
  Box,
  Select,
  Text,
  VStack,
  Avatar,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import instance from "../api/api";
import { IFarmer } from "../models/farmer.type";
import User from "../images/Vector.svg";
import validateNames from "../constants/validate";
import { getUserId } from "../services/auth.service";
import LoadingSpinner from "./LoadingSpinner";

const validate = validateNames();

export default function FarmersProfileForm({
  initialValues,
}: {
  initialValues: IFarmer;
}) {
  const districts = [
    { id: 1, value: "Berea", text: "Berea" },
    { id: 2, value: "Butha-Buthe", text: "Butha-Buthe" },
    { id: 3, value: "Leribe", text: "Leribe" },
    { id: 4, value: "Mafeteng", text: "Mafeteng" },
    { id: 5, value: "Maseru", text: "Maseru" },
    { id: 6, value: "Mohale's Hoek", text: "Mohale's Hoek" },
    { id: 7, value: "Mokhotlong", text: "Mokhotlong" },
    { id: 8, value: "Qacha's Nek", text: "Qacha's Nek" },
    { id: 9, value: "Quthing", text: "Quthing" },
    { id: 10, value: "Thaba-Tseka", text: "Thaba-Tseka" },
  ];

  const productType = [
    { id: 1, value: "Poultry", text: "Poultry" },
    { id: 2, value: "Eggs", text: "Eggs" },
    { id: 3, value: ["Poultry", "Eggs"], text: "Poultry & Eggs" },
  ];

  const toast = useToast();
  const userId = getUserId();
  const [userProfile, setUserProfile] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setUserProfile({ ...userProfile, [name]: value });
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<IFarmer>();

  const uploadError = {
    firstName: {
      required: "First name is required",
      minLength: 3,
      pattern: {
        value: validate,
        message: "Invalid character!!",
      },
    },
    surname: {
      required: "Last name is required",
      minLength: 3,
      pattern: {
        value: validate,
        message: "Invalid character!!",
      },
    },
    shopName: {
      required: "Shop name is required",
    },
    district: {
      required: "District is required",
    },
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const data = {
      firstName: userProfile.firstName,
      surname: userProfile.surname,
      district: userProfile.district,
      farmName: userProfile.farmName,
    };
    try {
      const response = await instance.put(`/api/v1/farmers/${userId}`, data);
      setIsLoading(false);
      toast({
        title: "Update Farmer",
        description: "Profile updated successfully",
        status: "success",
        duration: 7000,
        isClosable: true,
      });
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          setIsLoading(false);
          toast({
            title: "Update Farmer",
            description: "Problem when updating",
            status: "error",
            duration: 7000,
            isClosable: true,
          });
        }
      }
      return "An unexpected error occurred";
    }
  };
  const renderUser = (
    <Flex
      w="full"
      h="full"
      p={10}
      alignItems="flex-start"
      direction={{ base: "column", md: "row" }}
    >
      <Box w="full">
        <Flex
          direction={{ base: "column", md: "row" }}
          align={{ base: "center" }}
          ml={[0, 0, 50]}
        >
          <Avatar src={User} size={{ base: "xl", md: "xl" }} />
          <VStack p={6} align="flex-start">
            <HStack>
              <Text size="sm">{userProfile?.firstName}</Text>
              <Text size="sm">{userProfile?.surname}</Text>
            </HStack>
            <Flex align={{ base: "center" }}>
              <Text size="sm">Farmer</Text>
            </Flex>
          </VStack>
        </Flex>
        <Box w={{ base: "full", md: "65%" }} ml={[0, 0, 50]}>
          <hr className="line" />
        </Box>

        <Heading
          p={6}
          fontSize={{ base: "xl" }}
          fontWeight={500}
          ml={[0, 0, 30]}
        >
          Personal Information
        </Heading>

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <SimpleGrid
            columns={2}
            columnGap={3}
            rowGap={6}
            w={{ base: "full", md: "65%" }}
            py={25}
            ml={[0, 0, 50]}
          >
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel fontWeight={700}>FIRST NAME</FormLabel>
                <Input
                  placeholder="John"
                  value={userProfile.firstName}
                  {...register("firstName", uploadError.firstName)}
                  onChange={handleInputChange}
                />
                {errors?.firstName && (
                  <p className="error">{errors?.firstName?.message}</p>
                )}
                {errors?.firstName?.type === "minLength" && (
                  <p className="error">
                    First name must be atleast 3 characters long
                  </p>
                )}
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel fontWeight={700}>LAST NAME</FormLabel>
                <Input
                  placeholder="Doe"
                  value={userProfile?.surname}
                  {...register("surname", uploadError.surname)}
                  onChange={handleInputChange}
                />
                {errors?.surname && (
                  <p className="error">{errors.surname.message}</p>
                )}
                {errors?.surname?.type === "minLength" && (
                  <p className="error">
                    Last name must be atleast 3 characters long
                  </p>
                )}
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel fontWeight={700}>PRODUCT TYPE</FormLabel>
                <Select
                  id="productType"
                  {...register("productType")}
                  onChange={handleInputChange}
                  value={userProfile?.productType}
                >
                  {productType.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </Select>
                {errors?.productType && (
                  <p className="error">{errors.productType.message}</p>
                )}
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel
                  htmlFor="district"
                  fontWeight={700}
                  className="label"
                >
                  DISTRICT
                </FormLabel>
                <Select
                  id="district"
                  {...register("district")}
                  value={userProfile.district}
                  onChange={handleInputChange}
                >
                  {districts.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </Select>
                {errors?.district && (
                  <p className="error">{errors.district.message}</p>
                )}
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl>
                <FormLabel fontWeight={700}>EMAIL ADDRESS</FormLabel>
                <Input
                  placeholder="farmai@gmail.com"
                  value={userProfile?.emailAddress}
                  disabled
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl>
                <FormLabel fontWeight={700}>PHONE NUMBER</FormLabel>
                <Input
                  placeholder="56214440"
                  type="number"
                  value={userProfile?.phoneNumber}
                  disabled
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel fontWeight={700}>FARM NAME</FormLabel>
                <Input
                  placeholder="Farm name"
                  {...register("farmName", {
                    required: "Farm name is required",
                  })}
                  value={userProfile?.farmName}
                  onChange={handleInputChange}
                />
                {errors?.farmName && (
                  <p className="error">{errors.farmName.message}</p>
                )}
              </FormControl>
              <Button
                size="lg"
                w="full"
                mt="2rem"
                colorScheme="brand"
                isLoading={isSubmitting}
                type="submit"
                disabled={isLoading}
              >
                Update
              </Button>
            </GridItem>
          </SimpleGrid>
        </form>
      </Box>
    </Flex>
  );
  return <div>{isLoading ? <LoadingSpinner /> : renderUser}</div>;
}
