import { Menu, MenuButton, IconButton, MenuList } from "@chakra-ui/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import Admin from "../models/admin.type";
import DeleteButtonDialog from "./AdminDashboard/DeleteButtonDialog";
import EditUserDrawer from "./AdminDashboard/EditUserDrawer";

interface AdminTableActionsButtonProps {
  admins: Admin[];
  adminId: string;
  setUserAdmins: React.Dispatch<React.SetStateAction<Admin[]>>;
}

export default function AdminTableActionsButton({
  adminId,
  admins,
  setUserAdmins,
}: AdminTableActionsButtonProps) {
  return (
    <Menu>
      <MenuButton
        borderColor="black"
        as={IconButton}
        aria-label="Options"
        icon={<BsThreeDotsVertical />}
        variant="outline"
      />
      <MenuList>
        <EditUserDrawer userType="Admin" userId={adminId} userList={admins} />
        <DeleteButtonDialog
          userType="Admin"
          userId={adminId}
          userList={admins}
          setUserList={setUserAdmins}
        />
      </MenuList>
    </Menu>
  );
}
