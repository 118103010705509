import ROLES from "./roles";

export const isLoggedIn = localStorage.getItem("token") !== null;
export const isLoggedInBuyer =
  localStorage.getItem("token") !== null &&
  localStorage.getItem("role") === ROLES.BUYER;
export const isLoggedInFarmer =
  localStorage.getItem("token") !== null &&
  localStorage.getItem("role") === ROLES.FARMER;
export const isLoggedInAdmin =
  localStorage.getItem("token") !== null &&
  localStorage.getItem("role") === ROLES.ADMIN;
export const getToken = () => {
  return localStorage.getItem("token");
};
export const getRole = () => {
  return localStorage.getItem("role");
};
export const isAuthenticated =
  localStorage.getItem("token") !== null &&
  localStorage.getItem("role") !== null;
