import { Avatar, AvatarGroup, Button, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function RequestsOverviewCard() {
  const navigate = useNavigate();

  return (
    <Flex
      color="white"
      bg="#BC6C25"
      alignItems="center"
      textAlign="center"
      flexDir="column"
      flexGrow="1"
      p="2"
      borderRadius="md"
    >
      <Text pt="2" fontSize={["", "", "xl"]} fontWeight="extrabold">
        Requests Overview
      </Text>
      <AvatarGroup mb="10" mt="10" size="sm" max={4}>
        <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
        <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
        <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
        <Avatar name="Prosper Otemuyiwa" src="https://bit.ly/prosper-baba" />
        <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
      </AvatarGroup>
      <Button
        onClick={() => {
          navigate("/dashboard/user-requests");
        }}
        bg="#BC6C25"
        border="1px"
        mb="1"
      >
        See User Requests
      </Button>
    </Flex>
  );
}
