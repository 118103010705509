import { useState } from "react";
import useSWR from "swr";
import instance from "../../api/api";
import Header from "../../components/AdminHeader";
import "../../components/Errors.css";
import "../../styles/fontStyle.css";
import LoadingSpinner from "../../components/LoadingSpinner";
import AdminUpdateAdminForm from "../../components/AdminUpdateAdminForm";

function AdminUpdateAdmin() {
  const [initialValues, setInitialValues] = useState({
    _id: "",
    firstName: "",
    surname: "",
    phoneNumber: "",
    emailAddress: "",
    isAdmin: "",
    password: "",
  });

  const userId = localStorage.getItem("adminId");

  const sendRequest = async (url: string) => {
    const response = await instance.get(url);
    if (response.data) {
      setInitialValues(response.data.admin);
      initialValues.password = "";
    }
    return response.data;
  };

  const { data, error } = useSWR(`/admin/admins/${userId}`, sendRequest);
  if (!data || error) {
    return (
      <div>
        <Header />
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div>
      <Header />
      {initialValues.firstName !== "" ? (
        <AdminUpdateAdminForm initialValues={initialValues} />
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
}
export default AdminUpdateAdmin;
