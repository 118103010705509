import {
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  GridItem,
  Button,
  Box,
  Radio,
  useToast,
  Flex,
  Heading,
  RadioGroup,
  HStack,
  Stack,
} from "@chakra-ui/react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { AddIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import Header from "../../components/AdminHeader";
import { IAdmin } from "../../models/admin.type";
import "../../components/Errors.css";
import "../../styles/fontStyle.css";
import instance from "../../api/api";
import validateNames from "../../constants/validate";
import LoadingSpinner from "../../components/LoadingSpinner";

const validate = validateNames();

function AdminRegister() {
  const [passwordShown, setPasswordShown] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<IAdmin>({
    defaultValues: {
      firstName: "",
      surname: "",
      phoneNumber: "",
      emailAddress: "",
      password: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const onSubmit = async (data: IAdmin) => {
    setIsLoading(true);
    try {
      const response = await instance.post("/admin/createUser", data);
      setIsLoading(false);
      toast({
        title: "Account created",
        description: "New admin account was added successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      reset();
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          setIsLoading(false);
          toast({
            title: "Account not created",
            description: "New admin could not be added",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          reset();
        }
      }
      return "An unexpected error occurred";
    }
  };

  const renderAdminRegister = (
    <div>
      <Header />
      <Flex
        w="full"
        h="full"
        p={10}
        alignItems="flex-start"
        justify="space-between"
        direction={{ base: "column", md: "row" }}
      >
        <Box w="full" mr={20}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <SimpleGrid
              columns={1}
              w={{ base: "full", md: "40%" }}
              py={25}
              ml={[0, 0, 50]}
            >
              <Heading mb="5" size={{ base: "lg", md: "xl" }} color="#1C6B28">
                Assign Admin Role
              </Heading>
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <FormControl isRequired mb={3}>
                  <FormLabel
                    htmlFor="firstName"
                    fontWeight={700}
                    className="label"
                  >
                    first name
                  </FormLabel>
                  <Input
                    id="firstName"
                    placeholder="John"
                    {...register("firstName", {
                      required: "First name is required",
                      minLength: 3,
                      pattern: {
                        value: validate,
                        message: "Invalid character!!",
                      },
                    })}
                  />
                  {errors?.firstName && (
                    <p className="error">{errors.firstName.message}</p>
                  )}
                  {errors?.firstName?.type === "minLength" && (
                    <p className="error">
                      First name must be at least 3 characters long
                    </p>
                  )}
                </FormControl>
                <FormControl isRequired mb={3}>
                  <FormLabel
                    htmlFor="surname"
                    fontWeight={700}
                    className="label"
                  >
                    last name
                  </FormLabel>
                  <Input
                    placeholder="Doe"
                    id="surname"
                    {...register("surname", {
                      required: "Last name is required",
                      minLength: 3,
                      pattern: {
                        value: validate,
                        message: "Invalid character!!",
                      },
                    })}
                  />
                  {errors?.surname && (
                    <p className="error">{errors.surname.message}</p>
                  )}
                  {errors?.surname?.type === "minLength" && (
                    <p className="error">
                      Last name must be at least 3 characters long
                    </p>
                  )}
                </FormControl>
                <FormControl isRequired mb={3}>
                  <FormLabel
                    htmlFor="phoneNumber"
                    fontWeight={700}
                    className="label"
                  >
                    phone number
                  </FormLabel>
                  <Input
                    placeholder="56214440"
                    type="number"
                    id="phoneNumber"
                    {...register("phoneNumber", {
                      required: "Phone number is required",
                      maxLength: 8,
                      minLength: 8,
                    })}
                  />
                  {errors?.phoneNumber && (
                    <p className="error">{errors.phoneNumber.message}</p>
                  )}
                  {errors?.phoneNumber?.type === "maxLength" && (
                    <p className="error">Phone number cannot exceed 8 digits</p>
                  )}
                  {errors?.phoneNumber?.type === "minLength" && (
                    <p className="error">Phone number is 8 digits</p>
                  )}
                </FormControl>
                <FormControl>
                  <RadioGroup defaultValue="true">
                    <FormLabel fontWeight={700} className="label">
                      {" "}
                      IS ADMIN
                    </FormLabel>
                    <HStack>
                      <Radio value="true" {...register("isAdmin")}>
                        True
                      </Radio>
                      <Radio value="false" {...register("isAdmin")}>
                        False
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </FormControl>
                <FormControl isRequired mt={3}>
                  <FormLabel
                    htmlFor="emailAddress"
                    fontWeight={700}
                    className="label"
                  >
                    email address
                  </FormLabel>
                  <Input
                    placeholder="Farmia@gmail.com "
                    id="emailAddress"
                    type="email"
                    {...register("emailAddress", {
                      required: "Email address is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    })}
                  />
                  {errors?.emailAddress && (
                    <p className="error">{errors.emailAddress.message}</p>
                  )}
                </FormControl>
                <FormControl isRequired mt={3}>
                  <FormLabel
                    htmlFor="password"
                    fontWeight={700}
                    className="label"
                  >
                    password
                  </FormLabel>
                  <Input
                    w="90%"
                    placeholder="Password"
                    id="password"
                    type={passwordShown ? "text" : "password"}
                    {...register("password", {
                      required: "Password is required",
                      minLength: 8,
                    })}
                  />
                  <Stack
                    mt="-1.7em"
                    mb="0.7em"
                    flexDirection="row"
                    justifyContent="right"
                    cursor="pointer"
                  >
                    {!passwordShown ? (
                      <BsFillEyeFill onClick={togglePassword} />
                    ) : (
                      <BsFillEyeSlashFill onClick={togglePassword} />
                    )}
                  </Stack>
                  {errors?.password && (
                    <p className="error">{errors.password.message}</p>
                  )}
                  {errors?.password?.type === "minLength" && (
                    <p className="error">
                      Password must be at least 6 characters long
                    </p>
                  )}
                </FormControl>
              </GridItem>
              <GridItem>
                <Button
                  leftIcon={<AddIcon />}
                  colorScheme="brand"
                  size="lg"
                  w={{ base: "full", md: "50%" }}
                  mt="2rem"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Add Admin
                </Button>
              </GridItem>
            </SimpleGrid>
          </form>
        </Box>
      </Flex>
    </div>
  );

  return <div>{isLoading ? <LoadingSpinner /> : renderAdminRegister}</div>;
}

export default AdminRegister;
