import {
  Box,
  TableContainer,
  Table,
  Thead,
  Th,
  Flex,
  Tbody,
  Td,
  Tr,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useState, useRef } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import instance from "../api/api";
import { IProduct } from "../interface/Product.type";
import formatDate from "../utils/formatDate";
import FarmerEditProductDrawer from "./FarmerEditProductDrawer";

interface UpdateInventoryProps {
  products: IProduct[];
  setProducts: React.Dispatch<any>;
}
function FarmersInventoryTable({
  products,
  setProducts,
}: UpdateInventoryProps) {
  const toast = useToast();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [openFarmerAlert, setOpenFarmerAlert] = useState(false);
  const closeFarmerAlert = () => setOpenFarmerAlert(false);

  const deleteProduct = async (_id: string) => {
    try {
      const response = await instance.delete(`/api/v1/products/${_id}`);
      setProducts(products.filter((value) => value._id !== _id));
      toast({
        title: "Delete Product",
        description: "Product deleted successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      return response;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response) {
          toast({
            title: "Prodcut not deleted",
            description: "Product could not be deleted",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      }
      return e;
    }
  };
  return (
    <Box pt="25px" pl={[10, 10, 59.9]} pr="40px" mb={5}>
      <TableContainer
        p={4}
        mt="15px"
        mb={30}
        border="1px dashed black"
        w="auto"
        borderRadius="10px"
      >
        <Table
          aria-label="Inventory table"
          size="sm"
          fontWeight="medium"
          variant="striped"
        >
          <Thead
            aria-label="Table head"
            h="60px"
            borderBottom="2px solid #DDA15E"
          >
            <Tr aria-label="headerRow" data-testid="headerRow">
              <Th fontWeight={800}>#</Th>
              <Th fontWeight={800}>Status</Th>
              <Th fontWeight={800}>Date Added</Th>
              <Th fontWeight={800}>Product Type</Th>
              <Th fontWeight={800}>Product</Th>
              <Th fontWeight={800}>Size</Th>
              <Th fontWeight={800}>Quantity</Th>
              <Th fontWeight={800}>Price</Th>
              <Th fontWeight={800} w="0%">
                Actions
              </Th>
            </Tr>
          </Thead>
          <Tbody
            aria-label="Table body"
            data-testid="tableBody"
            py={10}
            bg="#F5F5F5"
          >
            {products.map((product: IProduct, index) => {
              return (
                <Tr
                  aria-label="bodyRow"
                  data-testid="bodyRow"
                  role="row"
                  key={product._id}
                >
                  <Td>{index + 1}</Td>
                  <Td color="white">
                    <Box
                      border="3px solid #303133"
                      bgColor="#303133"
                      borderRadius="6px"
                      textAlign="center"
                      fontWeight={500}
                      w={{ base: "full" }}
                    >
                      Pending
                    </Box>
                  </Td>
                  <Td maxW={["auto", "auto", 40, 20]}>
                    {formatDate(product?.createdAt)}
                  </Td>
                  <Td>{product.productType}</Td>
                  {product.productType === "Eggs" ? (
                    <Td>Eggs</Td>
                  ) : (
                    <Td>{product.productName}</Td>
                  )}
                  {product.eggSize ? (
                    <Td textTransform="capitalize">{product.eggSize}</Td>
                  ) : (
                    <Td>---</Td>
                  )}
                  {product.productType === "Eggs" ? (
                    <Td>{product.quantity}</Td>
                  ) : (
                    <Td>
                      {product.quantity}({product.unitOfMeasurement})
                    </Td>
                  )}
                  <Td>M{product.askingPrice}</Td>
                  <Td>
                    <Flex direction="row">
                      <FarmerEditProductDrawer isActive product={product} />
                      <Button
                        name="delete"
                        role="button"
                        data-testid="deleteButton"
                        aria-label="deleteButton"
                        aria-labelledby="deleteButton"
                        w={{ base: "auto", md: "20%" }}
                        h={[26, 26, 26]}
                        ml={4}
                        fontSize={14}
                        borderRadius={6}
                        _hover={{ bgColor: "#BC6C25", color: "white" }}
                        bgColor="white"
                        border="2px solid #BC6C25"
                        color="black"
                        px={{ base: 4, md: 8 }}
                        fontWeight={600}
                        onClick={() => {
                          setOpenFarmerAlert(true);
                          localStorage.setItem("farmerId", product._id);
                        }}
                      >
                        <span>
                          <RiDeleteBinLine />
                        </span>{" "}
                        Delete
                      </Button>
                      {openFarmerAlert && (
                        <AlertDialog
                          isOpen={openFarmerAlert}
                          leastDestructiveRef={cancelRef}
                          onClose={closeFarmerAlert}
                        >
                          <AlertDialogOverlay>
                            <AlertDialogContent>
                              <AlertDialogHeader
                                fontSize="lg"
                                fontWeight="bold"
                              >
                                Delete Product
                              </AlertDialogHeader>

                              <AlertDialogBody>Are you sure?</AlertDialogBody>
                              <AlertDialogFooter>
                                <Button
                                  ref={cancelRef}
                                  onClick={closeFarmerAlert}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  colorScheme="red"
                                  onClick={() => {
                                    const farmerId: string =
                                      localStorage.getItem("farmerId")!;
                                    deleteProduct(farmerId);
                                    setOpenFarmerAlert(false);
                                  }}
                                >
                                  Delete
                                </Button>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialogOverlay>
                        </AlertDialog>
                      )}
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default FarmersInventoryTable;
