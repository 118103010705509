import { Button, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { useState } from "react";
import useSWR from "swr";
import instance from "../api/api";
import UserRequest, { UserDetails } from "./UserRequest";

export default function BuyerRequests() {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [buyersList, setBuyersList] = useState<any>([]);

  const sendRequest = async (url: string) => {
    const response = await instance.get(url);

    const newBuyerRequests: UserDetails[] = response.data.farmers;

    setBuyersList((previous: any) => {
      if (
        JSON.stringify(previous) === JSON.stringify(newBuyerRequests) ||
        response.data.totalItems === previous.length
      ) {
        return previous;
      }

      return previous.concat(response.data.buyers);
    });

    return response.data;
  };

  const {
    data: buyerRequestsData,
    isLoading,
    error,
  } = useSWR(
    `/api/v1/buyers/requests?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    sendRequest,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      dedupingInterval: 0,
      refreshInterval: 0,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      refreshIntervalInBackground: false,
      key: `data-${pageNumber}`,
    },
  );

  return (
    <Flex textAlign="center" flexDirection="column">
      <SimpleGrid spacing={4} columns={[1, 2, 4]}>
        {buyersList.length > 0 &&
          buyersList.map((buyer: any) => {
            return (
              <UserRequest
                setUserRequestList={setBuyersList}
                key={buyer._id}
                userRequestDetails={buyer}
              />
            );
          })}
      </SimpleGrid>
      <Flex justifyContent="center">
        {buyerRequestsData &&
          buyersList.length > 0 &&
          buyersList.length < buyerRequestsData.totalItems && (
            <Button
              onClick={() => {
                setPageSize((prevPageSize) => {
                  if (buyersList.length < prevPageSize) {
                    return prevPageSize;
                  }
                  return prevPageSize + 8;
                });
                setPageNumber((prevPageNum) => {
                  if (buyersList.length < pageSize) {
                    return buyersList.length;
                  }
                  return prevPageNum + 8;
                });
              }}
              mt="10"
              isLoading={isLoading}
              w={["100%", "30%", "10%"]}
            >
              Load More
            </Button>
          )}
      </Flex>

      {!buyerRequestsData && <Text mt="2">Loading new requests....</Text>}

      {buyerRequestsData && buyerRequestsData.totalItems === 0 && (
        <Text>No new buyer requests!</Text>
      )}
      {error && <Text>Failed to load new buyer requests! Try again later</Text>}
    </Flex>
  );
}
