import axios, { AxiosError } from "axios";

export default function ErrorHandling(error: AxiosError | Error) {
  let message;
  if (axios.isAxiosError(error) && error.response) {
    message = error.response.data.message;
  } else {
    message = String(error);
  }
  return message;
}
