import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { MdOutlineModeEdit } from "react-icons/md";
import { IProduct } from "../interface/Product.type";
import EditProduct from "../pages/Farmer/EditProduct";

interface FarmerEditProductProps {
  isActive: boolean;
  product: IProduct;
}

function FarmerEditProductDrawer({
  isActive,
  product,
}: FarmerEditProductProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef(null);
  return (
    <>
      {isActive ? (
        <Button
          data-testid="editButton"
          name="edit"
          role="button"
          w={{ base: "auto", md: "20%" }}
          h={[26, 26, 26]}
          fontSize={14}
          borderRadius={6}
          _hover={{
            bgColor: "white",
            color: "black",
            border: "2px solid #BC6C25",
          }}
          bgColor="#BC6C25"
          border="2px solid #BC6C25"
          color="white"
          px={{ base: 4, md: 8 }}
          onClick={() => {
            localStorage.setItem("productType", product.productType);
            localStorage.setItem("productId", product._id);
            onOpen();
          }}
        >
          <span>
            <MdOutlineModeEdit />
          </span>{" "}
          Edit
        </Button>
      ) : (
        <Button
          data-testid="editButton"
          name="edit"
          role="button"
          w={{ base: "auto", md: "20%" }}
          h={[26, 26, 26]}
          fontSize={14}
          borderRadius={6}
          _hover={{
            bgColor: "white",
            color: "black",
            border: "2px solid #BC6C25",
          }}
          bgColor="#BC6C25"
          border="2px solid #BC6C25"
          color="white"
          cursor="not-allowed"
          ref={btnRef}
          isDisabled
          px={{ base: 4, md: 8 }}
        >
          <span>
            <MdOutlineModeEdit />
          </span>{" "}
          Edit
        </Button>
      )}
      <Drawer
        data-testid="editProductDrawer"
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="sm"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Edit Product</DrawerHeader>

          <DrawerBody>
            <EditProduct />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default FarmerEditProductDrawer;
