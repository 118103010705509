import { Menu, MenuButton, IconButton, MenuList } from "@chakra-ui/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import Farmer from "../models/farmer.type";
import DeleteButtonDialog from "./AdminDashboard/DeleteButtonDialog";
import EditUserDrawer from "./AdminDashboard/EditUserDrawer";

interface BuyerTableActionsButtonProps {
  farmers: Farmer[];
  farmerId: string;
  setUserFarmers: React.Dispatch<React.SetStateAction<Farmer[]>>;
}

export default function FarmerTableActionsButton({
  farmerId,
  farmers,
  setUserFarmers,
}: BuyerTableActionsButtonProps) {
  return (
    <Menu>
      <MenuButton
        borderColor="black"
        as={IconButton}
        aria-label="Options"
        icon={<BsThreeDotsVertical />}
        variant="outline"
      />
      <MenuList>
        <EditUserDrawer userType="Buyer" userId={farmerId} userList={farmers} />
        <DeleteButtonDialog
          userType="Farmer"
          userId={farmerId}
          userList={farmers}
          setUserList={setUserFarmers}
        />
      </MenuList>
    </Menu>
  );
}
