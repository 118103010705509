import {
  Box,
  TableContainer,
  Table,
  Thead,
  Button,
  Text,
  Th,
  Tbody,
  Td,
  Tr,
  Flex,
  Stack,
  Select,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  HStack,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState, useRef } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import instance from "../../api/api";
import Order from "../../models/Orders.type";
import formatDate from "../../utils/formatDate";
import BuyerEditOrderDrawer from "../../components/BuyerEditOrderDrawer";

interface BuyerTableProps {
  orders: Order[];
  setOrders: React.Dispatch<any>;
  currentPage: number;
  setCurrentPage: React.Dispatch<any>;
  itemsPerPage: number;
  setItemsPerPage: React.Dispatch<any>;
  totalItems: number;
}
function BuyerTable({
  orders,
  setOrders,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  setItemsPerPage,
  totalItems,
}: BuyerTableProps) {
  const isActive = true;
  const toast = useToast();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const closeAlert = () => setOpenAlert(false);

  const handleTotalRows = (selectedOption: any) => {
    const newPageSize = Number(selectedOption.target.value);
    setItemsPerPage(newPageSize);
  };

  const deleteOrder = async (_id: string) => {
    try {
      const res = await instance.delete(`/api/v1/orders/${_id}`);
      setOrders(orders.filter((value) => value._id !== _id));
      toast({
        title: "Order deleted",
        description: "Order deleted successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      return res;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          toast({
            title: "Order not deleted",
            description: "Order deleted unsuccessfully",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      }
      return error;
    }
  };

  return (
    <Box>
      <Box>
        <Box pt="25px" pl={[10, 10, 59.9]} pr="40px" mb={5}>
          <TableContainer
            p={4}
            mt="15px"
            mb={30}
            border="1px dashed black"
            borderRadius="10px"
          >
            <Table
              aria-label="Orders table"
              size="sm"
              fontWeight="medium"
              variant="striped"
            >
              <Thead
                aria-label="Table head"
                h="60px"
                borderBottom="2px solid #DDA15E"
              >
                <Tr aria-label="headerRow" data-testid="headerRow">
                  <Th>#</Th>
                  <Th>Status</Th>
                  <Th maxW={["auto", "auto", 40, 20]}>Date Added</Th>
                  <Th maxW={["auto", "auto", 40, 20]}>Product Type</Th>
                  <Th maxW={["auto", "auto", 20]}>Size</Th>
                  <Th maxW={["auto", "auto", 20, 10]}>Quantity</Th>
                  <Th>Price</Th>
                  <Th maxW={["auto", "auto", 20]}>Actions</Th>
                </Tr>
              </Thead>
              <Tbody aria-label="Table body" data-testid="tableBody" h="55px">
                {orders.map((order: Order, index: number) => (
                  <Tr
                    data-testid="row"
                    aria-label="row"
                    role="row"
                    key={order?._id}
                    borderRadius="6px"
                  >
                    <Td>{index + 1}</Td>
                    {order.status === "Sold" ? (
                      <Td color="white">
                        <Box
                          border="3px solid #606C38"
                          bgColor="#606C38"
                          borderRadius="6px"
                          textAlign="center"
                          fontWeight={500}
                          w={{ base: "full", lg: "80%", xl: "50%" }}
                        >
                          {order.status}
                        </Box>
                      </Td>
                    ) : (
                      <Td color="white">
                        <Box
                          border="3px solid #303133"
                          bgColor="#303133"
                          borderRadius="6px"
                          textAlign="center"
                          fontWeight={500}
                          w={{ base: "full", lg: "80%", xl: "50%" }}
                        >
                          {order.status}
                        </Box>
                      </Td>
                    )}
                    <Td maxW={["auto", "auto", 40, 20]}>
                      {formatDate(order.createdAt)}
                    </Td>
                    <Td maxW={["auto", "auto", 40, 40]}>{order.productType}</Td>
                    <Td maxW={["auto", "auto", 10]}>{order.eggSize}</Td>
                    <Td>{order.quantity}</Td>
                    <Td>{`M${order.price}`}</Td>
                    <Td maxW={["auto", "auto", 20]}>
                      <Flex direction="row">
                        {order.status === "Sold" ? (
                          <BuyerEditOrderDrawer
                            isActive={!isActive}
                            order={order}
                          />
                        ) : (
                          <BuyerEditOrderDrawer
                            isActive={isActive}
                            order={order}
                          />
                        )}
                        <Button
                          name="delete"
                          role="button"
                          data-testid="deleteButton"
                          aria-label="deleteButton"
                          aria-labelledby="deleteButton"
                          w="auto"
                          h={[26, 26, 26]}
                          ml={4}
                          fontSize={14}
                          borderRadius={6}
                          _hover={{ bgColor: "#BC6C25", color: "white" }}
                          bgColor="white"
                          border="2px solid #BC6C25"
                          color="black"
                          px={{ base: 4, md: 8 }}
                          fontWeight={600}
                          onClick={() => {
                            setOpenAlert(true);
                            localStorage.setItem("order", order._id);
                          }}
                        >
                          <span>
                            <RiDeleteBinLine />
                          </span>{" "}
                          Delete
                        </Button>
                        {openAlert && (
                          <AlertDialog
                            isOpen={openAlert}
                            leastDestructiveRef={cancelRef}
                            onClose={closeAlert}
                          >
                            <AlertDialogContent>
                              <AlertDialogHeader
                                fontSize="lg"
                                fontWeight="bold"
                              >
                                Delete Order
                              </AlertDialogHeader>

                              <AlertDialogBody>
                                Are you sure? You cant undo this action
                                afterwards.
                              </AlertDialogBody>

                              <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={closeAlert}>
                                  Cancel
                                </Button>
                                <Button
                                  colorScheme="red"
                                  onClick={() => {
                                    const orderId: string =
                                      localStorage.getItem("order")!;
                                    deleteOrder(orderId);
                                    setOpenAlert(false);
                                  }}
                                  ml={3}
                                >
                                  Delete
                                </Button>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>
                        )}
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <HStack justifyContent="space-between" p={2}>
              <Stack>
                <Text>
                  1 - {orders.length} of {totalItems}
                </Text>
              </Stack>

              <Stack
                flexDirection="row"
                align="center"
                justifyContent="space-between"
              >
                <Select
                  onChange={handleTotalRows}
                  w={20}
                  border="1px solid black"
                >
                  <option key={10} value={10}>
                    10
                  </option>
                  <option key={20} value={20}>
                    20
                  </option>
                  <option key={30} value={30}>
                    30
                  </option>
                </Select>
                <Flex align="baseline">
                  <Button
                    border="1px solid #BC6C25"
                    p={2}
                    borderRadius={10}
                    mr={2}
                    ml={1}
                    cursor="pointer"
                    isDisabled={currentPage === 0 || currentPage === 2}
                    onClick={() => {
                      setCurrentPage((prevPageNum: number) => {
                        return prevPageNum - itemsPerPage;
                      });
                    }}
                  >
                    <FiArrowLeft />
                  </Button>
                  <Button
                    border="1px solid #BC6C25"
                    p={2}
                    borderRadius={10}
                    cursor="pointer"
                    isDisabled={orders && orders.length < itemsPerPage}
                    onClick={() => {
                      setCurrentPage((prevPageNum: number) => {
                        if (orders.length < itemsPerPage) {
                          return orders.length;
                        }
                        return prevPageNum + 10;
                      });
                    }}
                  >
                    <FiArrowRight />
                  </Button>
                </Flex>
              </Stack>
            </HStack>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
}

export default BuyerTable;
