import { Box, Flex, Image, Text } from "@chakra-ui/react";
import eggAvatar from "../../images/eggAvatar.svg";

interface ProductOrderedProps {
  productType: string;
  productSize: string;
  productQuantity: number;
  productPrice: number;
}

export default function ProductOrdered({
  productPrice,
  productQuantity,
  productSize,
  productType,
}: ProductOrderedProps) {
  return (
    <Flex
      borderRadius="md"
      mb="2"
      alignItems="center"
      justifyContent="space-between"
      w="100%"
      p="3"
      bg="white"
    >
      <Box borderRadius="md" objectFit="cover">
        <Image src={eggAvatar} alt="Dan Abramov" />
      </Box>
      <Flex w="30%" flexDir="column">
        <Text fontSize={["sm", "md"]} fontWeight="bold">
          {productType}
        </Text>
        <Flex>
          <Text fontSize={["sm", "md"]} fontWeight="bold">
            Size:{" "}
          </Text>
          <Text fontSize={["sm", "md"]}>{productSize}</Text>
        </Flex>
      </Flex>
      <Box w="20%">
        <Text fontSize={["sm", "md"]} fontWeight="bold">
          P{productPrice}
        </Text>
      </Box>
      <Box w={["10%", "15%"]}>
        <Text fontSize={["sm", "md"]} fontWeight="bold">
          {productQuantity}
        </Text>
      </Box>
    </Flex>
  );
}
