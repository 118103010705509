const measurements = [
  { id: 1, value: "Kg", text: "Kg" },
  { id: 2, value: "Trays", text: "Tray" },
  { id: 3, value: "Crates", text: "Crate" },
  { id: 4, value: "Bunches", text: "Bunch" },
  { id: 5, value: "Bundles", text: "Bundle" },
  { id: 6, value: "Buckets", text: "Bucket" },
  { id: 7, value: "Boxes", text: "Box" },
];

export default measurements;
