import {
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  GridItem,
  Button,
  Flex,
  Heading,
  Box,
  HStack,
  Select,
  useToast,
  Text,
  VStack,
  Avatar,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import User from "../images/Vector.svg";
import { IBuyer } from "../models/buyer.type";
import validateNames from "../constants/validate";
import validateEmail from "../constants/validateEmail";
import validatePhoneNumber from "../constants/validatePhoneNumber";
import { getUserId } from "../services/auth.service";
import LoadingSpinner from "./LoadingSpinner";
import instance from "../api/api";

const validate = validateNames();
const validEmail = validateEmail();
const validPhoneNumber = validatePhoneNumber();

export default function BuyerProfileForm({
  initialValues,
}: {
  initialValues: IBuyer;
}) {
  const districts = [
    { id: 1, value: "Berea", text: "Berea" },
    { id: 2, value: "Butha-Buthe", text: "Butha-Buthe" },
    { id: 3, value: "Leribe", text: "Leribe" },
    { id: 4, value: "Mafeteng", text: "Mafeteng" },
    { id: 5, value: "Maseru", text: "Maseru" },
    { id: 6, value: "Mohale's Hoek", text: "Mohale's Hoek" },
    { id: 7, value: "Mokhotlong", text: "Mokhotlong" },
    { id: 8, value: "Qacha's Nek", text: "Qacha's Nek" },
    { id: 9, value: "Quthing", text: "Quthing" },
    { id: 10, value: "Thaba-Tseka", text: "Thaba-Tseka" },
  ];
  const [userProfile, setUserProfile] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const userId = getUserId();

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setUserProfile({ ...userProfile, [name]: value });
  };
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<IBuyer>();

  const uploadError = {
    firstName: {
      required: "First name is required",
      pattern: {
        value: validate,
        message: "Invalid character!!",
      },
    },
    surname: {
      required: "Last name is required",
      pattern: {
        value: validate,
        message: "Invalid character!!",
      },
    },
    emailAddress: {
      required: "Email address is required",
      pattern: {
        value: validEmail,
        message: "Invalid character!!",
      },
    },
    phoneNumber: {
      required: "Phone number is required",
      pattern: {
        value: validPhoneNumber,
        message: "Invalid character!!",
      },
    },
    shopName: {
      required: "Shop name is required",
    },
    district: {
      required: "District is required",
    },
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const data = {
      firstName: userProfile.firstName,
      surname: userProfile.surname,
      district: userProfile.district,
      shopName: userProfile.shopName,
      emailAddress: userProfile.emailAddress,
      phoneNumber: userProfile.phoneNumber,
    };
    try {
      const response = await instance.put(`/api/v1/buyers/${userId}`, data);
      toast({
        title: "Profile Updated",
        description: "Your profile is updated successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          toast({
            title: "Profile not Updated",
            description: "Profile could not be updated ",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          setIsLoading(false);
          return error;
        }
      }
      return "An unexpected error occurred";
    }
  };

  const renderUser = (
    <Flex
      w="full"
      h="full"
      p={10}
      alignItems="flex-start"
      direction={{ base: "column", md: "row" }}
    >
      <Box w="full">
        <Flex
          direction={{ base: "column", md: "row" }}
          align={{ base: "center" }}
          ml={[0, 0, 50]}
        >
          <Avatar src={User} size={{ base: "xl", md: "xl" }} />
          <VStack p={6} align="flex-start">
            <HStack>
              <Text size="sm">{userProfile?.firstName}</Text>
              <Text size="sm">{userProfile?.surname}</Text>
            </HStack>
            <Text size="sm">Buyer</Text>
          </VStack>
        </Flex>
        <Box w={{ base: "full", md: "65%" }} ml={[0, 0, 50]}>
          <hr className="line" />
        </Box>
        <Heading
          p={6}
          fontSize={{ base: "xl" }}
          fontWeight={500}
          ml={[0, 0, 30]}
        >
          Personal Information
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <SimpleGrid
            columns={2}
            columnGap={3}
            rowGap={6}
            w={{ base: "full", md: "65%" }}
            py={25}
            ml={[0, 0, 50]}
          >
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel fontWeight={700} className="label">
                  FIRST NAME
                </FormLabel>
                <Input
                  placeholder="John"
                  {...register("firstName", uploadError.firstName)}
                  value={userProfile.firstName}
                  onChange={handleInputChange}
                />
                {errors.firstName && (
                  <p className="error">{errors.firstName?.message}</p>
                )}
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel fontWeight={700} className="label">
                  LAST NAME
                </FormLabel>
                <Input
                  placeholder="Doe"
                  {...register("surname", uploadError.surname)}
                  value={userProfile.surname}
                  onChange={handleInputChange}
                />
                {errors.surname && (
                  <p className="error">{errors.surname?.message}</p>
                )}
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel
                  htmlFor="district"
                  fontWeight={700}
                  className="label"
                >
                  DISTRICT
                </FormLabel>
                <Select
                  id="district"
                  {...register("district")}
                  value={userProfile.district}
                  onChange={handleInputChange}
                >
                  {districts.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </Select>
                {errors?.district && (
                  <p className="error">{errors.district.message}</p>
                )}
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel fontWeight={700} className="label">
                  EMAIL ADDRESS
                </FormLabel>
                <Input
                  placeholder="farmai@gmail.com"
                  data-testid="email"
                  {...register("emailAddress", uploadError.emailAddress)}
                  value={userProfile.emailAddress}
                  onChange={handleInputChange}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel fontWeight={700} className="label">
                  PHONE NUMBER
                </FormLabel>
                <Input
                  placeholder="56214440"
                  type="number"
                  data-testid="phone"
                  {...register("phoneNumber", uploadError.phoneNumber)}
                  value={userProfile.phoneNumber}
                  onChange={handleInputChange}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <FormControl isRequired>
                <FormLabel fontWeight={700} className="label">
                  SHOP NAME
                </FormLabel>
                <Input
                  placeholder="Shop name"
                  {...register("shopName", uploadError.shopName)}
                  value={userProfile.shopName}
                  onChange={handleInputChange}
                />
                {errors.shopName && (
                  <p className="error">{errors.shopName?.message}</p>
                )}
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <Button
                size="lg"
                w="full"
                data-testid="Loading"
                mt="2rem"
                colorScheme="brand"
                type="submit"
                isLoading={isSubmitting}
              >
                Update
              </Button>
            </GridItem>
          </SimpleGrid>
        </form>
      </Box>
    </Flex>
  );

  return <div>{isLoading ? <LoadingSpinner /> : renderUser}</div>;
}
