import { useState } from "react";
import useSWR from "swr";
import instance from "../api/api";
import { IFarmer } from "../models/farmer.type";

export const getUserId = () => {
  const userId = localStorage.getItem("userId");
  if (userId) return userId;
  return null;
};

export const getRole = () => {
  const role = localStorage.getItem("role");
  if (role) return role;
  return null;
};

export function GetFarmerDetails() {
  const [farmerDetails, setFarmerDetails] = useState<IFarmer>();

  const sendRequest = async (url: string) => {
    const response = await instance.get(url);
    if (response.data) {
      setFarmerDetails(response.data.farmer);
    }
    return response.data;
  };

  const { data: farmerData, error: farmerDataFetchError } = useSWR(
    `/api/v1/farmers/${getUserId()}`,
    sendRequest,
  );

  if (!farmerData || farmerDataFetchError) return null;

  return farmerDetails;
}
