import {
  FormControl,
  FormLabel,
  Input,
  Select,
  InputGroup,
  InputLeftElement,
  RadioGroup,
  Radio,
  Button,
  Box,
  useToast,
  Stack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { ChangeEvent, useState } from "react";
import axios from "axios";
import useSWR from "swr";
import "../../components/Errors.css";
import product from "../../constants/productType";
import LoadingSpinner from "../../components/LoadingSpinner";
import { IOrder } from "../../models/Orders.type";
import instance from "../../api/api";
import getProductType from "../../utils/GetProductType";
import eggSize from "../../constants/getEggValue";

function EditOrder() {
  const id = localStorage.getItem("orderId");
  const toast = useToast();
  const productType = getProductType();
  const [isLoading, setIsLoading] = useState(false);
  const [smallEgg, setSmallEgg] = useState("");
  const [mediumEgg, setMediumEgg] = useState("");
  const [largeEgg, setLargeEgg] = useState("");

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<IOrder>();

  const initialOrderState = {
    _id: "",
    price: "",
    quantity: "",
    ageInWeeks: "",
    eggSize: "",
    buyerId: "",
    productType: "",
  };

  const [orders, setOrders] = useState(initialOrderState);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setOrders({ ...orders, [name]: value });
  };

  const sendRequest = async (url: string) => {
    const eggPrices = await instance.get("/api/v1/eggSizes");
    setSmallEgg(eggPrices.data.small.price);
    setMediumEgg(eggPrices.data.medium.price);
    setLargeEgg(eggPrices.data.large.price);

    const response = await instance.get(url);

    if (response.data) {
      setOrders(response.data.order);
    }

    return response.data;
  };

  const { data: buyersOrderData, error: buyersOrderError } = useSWR(
    `/api/v1/orders/${id}`,
    sendRequest,
  );

  if (!buyersOrderData || buyersOrderError) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  const onSubmit = async (data: IOrder) => {
    let newPrice;
    if (data.eggSize === "Small") {
      newPrice = Number(smallEgg) * Number(data.quantity);
    }
    if (data.eggSize === "Medium") {
      newPrice = Number(mediumEgg) * Number(data.quantity);
    }

    if (data.eggSize === "Large") {
      newPrice = Number(largeEgg) * Number(data.quantity);
    }
    const order = {
      eggSize: data.eggSize,
      price: newPrice,
      quantity: data.quantity,
    };
    setIsLoading(true);
    try {
      const response = await instance.put(`/api/v1/orders/${id}`, order);
      setIsLoading(false);
      toast({
        title: "Update Order",
        description: "Order updated successfully",
        status: "success",
        duration: 7000,
        isClosable: true,
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          setIsLoading(false);
          toast({
            title: "Update Order Error",
            description: "Order could not be updated!",
            status: "error",
            duration: 7000,
            isClosable: true,
          });
        }
      }
      return "An unexpected error occurred";
    }
  };

  const uploadError = {
    productName: {
      required: "Product name is required",
    },
    quantity: {
      required: "Quantity is required",
      min: {
        value: 1,
        message: "Quantity can not be less than 1",
      },
    },
    ageInWeeks: {
      required: "Age in weeks is required",
      min: {
        value: 1,
        message: "Quantity can not be less than 1",
      },
    },
    description: {
      required: "Description is required",
      minLength: {
        value: 5,
        message: "Not less than 10",
      },
    },
  };

  const renderEditOrder = (
    <div>
      <Stack>
        <Box py={10} mt={{ base: "-45", md: "-35", lg: "-25" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack mt={2} spacing={4}>
              <FormControl>
                <FormLabel as="legend" fontWeight={500} className="label">
                  Product Type
                </FormLabel>
              </FormControl>
              {productType === product.Poultry ? (
                <RadioGroup defaultValue="Poultry">
                  <Radio mr={2} value="Poultry">
                    Poultry
                  </Radio>
                  <Radio value="Eggs" isDisabled>
                    Eggs
                  </Radio>
                </RadioGroup>
              ) : (
                <RadioGroup justifyContent="space-between" defaultValue="Eggs">
                  <Radio
                    mr={10}
                    value="Poultry"
                    isDisabled
                    border="2px solid orange"
                  >
                    Poultry
                  </Radio>
                  <Radio value="Eggs">Eggs</Radio>
                </RadioGroup>
              )}

              {productType === product.Poultry ? (
                <>
                  <FormControl>
                    <FormLabel
                      htmlFor="quantity"
                      fontWeight={500}
                      className="label"
                    >
                      Quantity In KG
                    </FormLabel>
                    <Box>
                      <Input
                        fontWeight={400}
                        value={orders?.quantity}
                        {...register("quantity", uploadError.quantity)}
                        onChange={handleInputChange}
                      />
                    </Box>
                    {errors.quantity && (
                      <p className="error">{errors.quantity?.message}</p>
                    )}
                  </FormControl>
                  <FormControl mt={3} mb={4}>
                    <FormLabel
                      htmlFor="ageInWeeks"
                      fontWeight={500}
                      className="label"
                    >
                      Age In Weeks
                    </FormLabel>
                    <Input
                      id="ageInWeeks"
                      placeholder="2weeks"
                      fontWeight={400}
                      type="number"
                      value={orders?.ageInWeeks}
                      {...register("ageInWeeks", uploadError.ageInWeeks)}
                      onChange={handleInputChange}
                    />
                    {errors.ageInWeeks && (
                      <p className="error">{errors.ageInWeeks?.message}</p>
                    )}
                  </FormControl>
                </>
              ) : (
                <>
                  <FormControl>
                    <FormLabel
                      htmlFor="quantity"
                      fontWeight={500}
                      className="label"
                    >
                      Quantity In Trays
                    </FormLabel>
                    <Box>
                      <Input
                        id="quantityInTrays"
                        data-testid="quantityInTrays"
                        fontWeight={400}
                        value={orders?.quantity}
                        {...register("quantity", uploadError.quantity)}
                        onChange={handleInputChange}
                      />
                    </Box>
                    {errors.quantity && (
                      <p className="error">{errors.quantity?.message}</p>
                    )}
                  </FormControl>
                  <FormControl mt={3} mb={4}>
                    <FormLabel fontWeight={500} className="label">
                      Egg Size
                    </FormLabel>
                    <Select
                      id="eggSize"
                      data-testid="eggSize"
                      value={orders?.eggSize.toLowerCase()}
                      {...register("eggSize")}
                      onChange={handleInputChange}
                    >
                      {eggSize.map((option) => (
                        <option key={option.id} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
              {productType === product.Poultry ? (
                <FormControl>
                  <FormLabel htmlFor="price" fontWeight={500} className="label">
                    Price Per KG
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement> M </InputLeftElement>
                    <Input
                      id="price"
                      fontWeight={400}
                      placeholder="350"
                      value={orders?.price}
                      {...register("price")}
                      name="price"
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                  {errors.price && (
                    <p className="error">{errors.price?.message}</p>
                  )}
                </FormControl>
              ) : (
                <FormControl>
                  <FormLabel htmlFor="price" fontWeight={500} className="label">
                    Price Per Tray
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement> M </InputLeftElement>
                    <Input
                      id="price"
                      data-testid="pricePerTray"
                      fontWeight={400}
                      placeholder="350"
                      value={orders?.price}
                      {...register("price")}
                      name="price"
                      onChange={handleInputChange}
                      disabled
                    />
                  </InputGroup>
                  {errors.price && (
                    <p className="error">{errors.price?.message}</p>
                  )}
                </FormControl>
              )}
            </Stack>
            <Button
              size="lg"
              mt={2}
              w="full"
              bgColor="#BC6C25"
              color="white"
              _hover={{
                bgColor: "white",
                color: "black",
                border: "2px solid #BC6C25",
              }}
              type="submit"
              isLoading={isSubmitting}
              disabled={isLoading}
            >
              Update Order
            </Button>
          </form>
        </Box>
      </Stack>
    </div>
  );
  return (
    <div>
      {isLoading ? (
        <div>
          <LoadingSpinner />
        </div>
      ) : (
        renderEditOrder
      )}
    </div>
  );
}

export default EditOrder;
