import axios, { AxiosResponse } from "axios";
import { Logout } from "../context/Auth/AuthContext";

const token = localStorage.getItem("token");

const instance = axios.create({
  baseURL: "https://farmai-africa.azurewebsites.net",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

export const interceptorId = instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      Logout();
    }
  },
);

export default instance;
