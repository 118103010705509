import {
  Flex,
  Box,
  VStack,
  Heading,
  Button,
  HStack,
  Text,
  Image,
  Link,
} from "@chakra-ui/react";
import React from "react";
import Header from "../components/Landing_Page/HeroHeader";
import HeroEgg from "../images/Homepage_Images/HeroEgg.svg";
import Footer from "../components/Footer";
import { isLoggedIn } from "../constants/auth";
import LandingSection2 from "../components/Landing_Page/LandingSection2";
import "../styles/fontStyle.css";
import About from "../components/Landing_Page/About";
import LandingSection4 from "../components/Landing_Page/LandingSection4";
import LandingSection5 from "../components/Landing_Page/LandingSection5";

function Herosection() {
  return (
    <Box bg="#EBEBEB">
      <Header />
      <Flex
        mt={{
          base: "0em",
          md: "-2em",
          xl: "0em",
        }}
        p={{
          base: "0.8em",
          md: "2em",
        }}
        maxH="120em"
        h={{ base: "auto", sm: "30em", md: "48em" }}
        py={[0, 5, 0]}
        direction={{ base: "column-reverse", md: "row" }}
        bgColor="#162117"
      >
        <VStack
          p={7}
          spacing={10}
          py={[4, 2, 40]}
          alignItems="flex-start"
          w={{
            sm: "30em",
            md: "48em",
            lg: "62em",
            xl: "80em",
            "2xl": "96em",
          }}
        >
          <Heading
            fontSize={["1.86em", "1.86em", "5xl"]}
            color="#FFFFFF"
            data-testid="hero"
            fontWeight={500}
            mt={{
              base: "0",
              md: "-10",
            }}
          >
            A marketplace for <br /> small scale farmers in <br /> Africa
          </Heading>

          <VStack
            alignItems="flex-start"
            w={{
              base: "80%",
              md: "100%",
            }}
          >
            <Text fontSize={[20, 17, 25]} fontWeight={400} color="#FFFFFF">
              Bringing fresh, locally sourced produce to your table
            </Text>
          </VStack>

          {!isLoggedIn && (
            <>
              <Flex direction={{ base: "column", sm: "row" }} ml={[50, 30, 0]}>
                <Link
                  href="/register"
                  _hover={{
                    textDecoration: "none",
                  }}
                >
                  <Button
                    bg="#1C6B28"
                    colorScheme="brand"
                    textDecoration="none"
                    data-testid="button"
                    fontSize="lg"
                    h={{ base: "40px", md: "50px" }}
                    w={[170, 170, 280]}
                    borderRadius="20px"
                    mr={5}
                  >
                    Farmers
                  </Button>
                </Link>
                <Link
                  href="/register"
                  _hover={{
                    textDecoration: "none",
                  }}
                >
                  <Button
                    color="#FFFFFF"
                    fontSize="lg"
                    w={[170, 170, 280]}
                    h={{ base: "40px", md: "50px" }}
                    borderRadius="20px"
                    bg="none"
                    border="3px solid #FFFFFF"
                    _hover={{
                      bg: "white",
                      color: "black",
                    }}
                    mt={{ base: "10px", sm: "0px" }}
                  >
                    Buyers
                  </Button>
                </Link>
              </Flex>
              <HStack pb={{ base: 6, md: 0 }} mb={{ base: "2em", md: "0em" }}>
                <Text fontSize={[20, 17, 25]} fontWeight={400} color="#FFFFFF">
                  Already signed up? Login{" "}
                  <Link href="/login" color="#FFFFFF" fontWeight={800}>
                    here
                  </Link>
                </Text>
              </HStack>
            </>
          )}
        </VStack>
        <VStack>
          <Image
            src={HeroEgg}
            mt={["-0em", "-1.5em"]}
            p="1.88em"
            display={{
              base: "none",
              xl: "block",
            }}
            height={{
              base: "auto",
              sm: "0em",
              md: "48em",
              lg: "62em",
              xl: "80em",
              "2xl": "96em",
            }}
            width={{
              base: "auto",
              sm: "0em",
              md: "48em",
              lg: "60em",
              xl: "80em",
              "2xl": "96em",
            }}
          />
        </VStack>
      </Flex>
      <LandingSection2 />
      <About />
      <LandingSection4 />
      <LandingSection5 />
      <Footer />
    </Box>
  );
}

export default Herosection;
