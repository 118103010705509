import {
  FormControl,
  FormLabel,
  Input,
  InputLeftElement,
  SimpleGrid,
  GridItem,
  useToast,
  Button,
  HStack,
  Link,
  Text,
  Select,
  Flex,
  Box,
  InputGroup,
} from "@chakra-ui/react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useState } from "react";
import { MdPersonOutline, MdOutlineMail } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { AiOutlineShop } from "react-icons/ai";
import { IBuyer } from "../interfaces/IBuyer";
import "../styles/Error.css";
import "../styles/fontStyle.css";
import instance from "../api/api";
import validateNames from "../constants/validate";

const validate = validateNames();

function BuyerRegister() {
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const districts = [
    { id: 1, value: "Berea", text: "Berea" },
    { id: 2, value: "Butha-Buthe", text: "Butha-Buthe" },
    { id: 3, value: "Leribe", text: "Leribe" },
    { id: 4, value: "Mafeteng", text: "Mafeteng" },
    { id: 5, value: "Maseru", text: "Maseru" },
    { id: 6, value: "Mohale's Hoek", text: "Mohale's Hoek" },
    { id: 7, value: "Mokhotlong", text: "Mokhotlong" },
    { id: 8, value: "Qacha's Nek", text: "Qacha's Nek" },
    { id: 9, value: "Quthing", text: "Quthing" },
    { id: 10, value: "Thaba-Tseka", text: "Thaba-Tseka" },
  ];
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IBuyer>();

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const onSubmit = async (data: IBuyer) => {
    try {
      const response = await instance.post("/api/v1/buyers/register", data);
      if (response) {
        navigate("/success");
      }
      if (!response) {
        toast({
          title: "Account not created.",
          description:
            "Sorry your registration was not successful. Please try again later.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast({
          title: "Account not created.",
          description:
            "Sorry your registration was not successful. Please try again later.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return error.message;
      }
      return "An unexpected error occurred";
    }
  };

  const uploadError = {
    firstName: {
      required: "First name is required",
      minLength: 3,
      pattern: {
        value: validate,
        message: "Invalid character!!",
      },
    },
    surname: {
      required: "Last name is required",
      minLength: 3,
      pattern: {
        value: validate,
        message: "Invalid character!!",
      },
    },
    phoneNumber: {
      required: "Phone number is required",
      maxLength: 8,
      minLength: 8,
    },
    emailAddress: {
      required: "Email address is required",
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid email address",
      },
    },
    shopName: {
      required: "Shop name is required",
    },
    district: {
      required: "Please select your district",
    },
    password: {
      required: "Please enter a password",
      minLength: 6,
    },
  };

  return (
    <div>
      <Flex
        w="full"
        h="full"
        alignItems="flex-start"
        direction={{ base: "column", md: "row" }}
      >
        <Box w="full">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <SimpleGrid columns={2} columnGap={3} rowGap={6} w="full" py={25}>
              <GridItem colSpan={{ base: 2, lg: 1 }}>
                <FormControl isRequired>
                  <FormLabel fontWeight={600} className="label">
                    first name
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <MdPersonOutline />
                    </InputLeftElement>
                    <Input
                      placeholder="John"
                      {...register("firstName", uploadError.firstName)}
                    />
                  </InputGroup>
                  {errors?.firstName && (
                    <p className="error">{errors.firstName.message}</p>
                  )}
                  {errors?.firstName?.type === "minLength" && (
                    <p className="error">
                      First name must be at least 3 characters long
                    </p>
                  )}
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2, lg: 1 }}>
                <FormControl isRequired>
                  <FormLabel fontWeight={600} className="label">
                    last name
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <MdPersonOutline />
                    </InputLeftElement>
                    <Input
                      placeholder="Doe"
                      {...register("surname", uploadError.surname)}
                    />
                  </InputGroup>

                  {errors?.surname && (
                    <p className="error">{errors.surname.message}</p>
                  )}
                  {errors?.surname?.type === "minLength" && (
                    <p className="error">
                      Last name must be at least 3 characters long
                    </p>
                  )}
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2 }}>
                <FormControl isRequired>
                  <FormLabel fontWeight={600} className="label">
                    district
                  </FormLabel>
                  <Select
                    id="district"
                    {...register("district", uploadError.district)}
                  >
                    <option value="">Select District</option>
                    {districts.map((option) => (
                      <option key={option.id} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </Select>
                  {errors?.district && (
                    <p className="error">{errors.district.message}</p>
                  )}
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2 }}>
                <FormControl isRequired>
                  <FormLabel fontWeight={600} className="label">
                    email address
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <MdOutlineMail />
                    </InputLeftElement>
                    <Input
                      placeholder="farmai@gmail.com"
                      {...register("emailAddress", uploadError.emailAddress)}
                    />
                  </InputGroup>
                  {errors?.emailAddress && (
                    <p className="error">{errors.emailAddress.message}</p>
                  )}
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2 }}>
                <FormControl isRequired>
                  <FormLabel fontWeight={600} className="label">
                    phone number
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <FiPhone />
                    </InputLeftElement>
                    <Input
                      placeholder="56214440"
                      type="number"
                      {...register("phoneNumber", uploadError.phoneNumber)}
                    />
                  </InputGroup>
                  {errors?.phoneNumber && (
                    <p className="error">{errors.phoneNumber.message}</p>
                  )}
                  {errors?.phoneNumber?.type === "maxLength" && (
                    <p className="error">Phone number cannot exceed 8 digits</p>
                  )}
                  {errors?.phoneNumber?.type === "minLength" && (
                    <p className="error">Phone number is 8 digits</p>
                  )}
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2 }}>
                <FormControl isRequired>
                  <FormLabel fontWeight={600} className="label">
                    shop name
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <AiOutlineShop />
                    </InputLeftElement>
                    <Input
                      placeholder="Farm Products"
                      {...register("shopName", uploadError.shopName)}
                    />
                  </InputGroup>
                  {errors?.shopName && (
                    <p className="error">{errors.shopName.message}</p>
                  )}
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2 }}>
                <FormControl isRequired>
                  <FormLabel fontWeight={600} className="label">
                    password
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement>
                      {!passwordShown ? (
                        <BsFillEyeFill onClick={togglePassword} />
                      ) : (
                        <BsFillEyeSlashFill onClick={togglePassword} />
                      )}
                    </InputLeftElement>
                    <Input
                      placeholder="Password"
                      type={passwordShown ? "text" : "password"}
                      {...register("password", uploadError.password)}
                    />
                  </InputGroup>
                  {errors?.password && (
                    <p className="error">{errors.password.message}</p>
                  )}
                  {errors?.password?.type === "minLength" && (
                    <p className="error">
                      Password must be at least 6 characters long
                    </p>
                  )}
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2 }}>
                <Button
                  size="lg"
                  w="full"
                  mt="2rem"
                  isLoading={isSubmitting}
                  bgColor="#BC6C25"
                  color="white"
                  type="submit"
                  _hover={{
                    border: "2px solid #BC6C25",
                    color: "black",
                    bgColor: "white",
                  }}
                >
                  Create Account
                </Button>
                <HStack justifyContent="center" p={3}>
                  <Text fontSize={[20, 17, 20]}>
                    Already have an account?{"  "}
                    <Link
                      href="/login"
                      color="#BC6C25"
                      fontWeight={500}
                      textDecoration="underline"
                    >
                      Login
                    </Link>
                  </Text>
                </HStack>
              </GridItem>
            </SimpleGrid>
          </form>
        </Box>
      </Flex>
    </div>
  );
}
export default BuyerRegister;
