import {
  isLoggedInAdmin,
  isLoggedInBuyer,
  isLoggedInFarmer,
} from "../constants/auth";

export default function HeaderSelector() {
  if (isLoggedInBuyer) {
    return [
      { label: "Marketplace", href: "/products/listing" },
      { label: "Orders", href: "/dashboard/buyer-dashboard" },
      { label: "Profile", href: "/dashboard/buyers-profile" },
    ];
  }

  if (isLoggedInFarmer) {
    return [
      { label: "Inventory", href: "/dashboard/farmers-dashboard" },
      { label: "Profile", href: "/dashboard/farmers-profile" },
    ];
  }

  if (isLoggedInAdmin) {
    return [
      { label: "Dashboard", href: "/dashboard/admin-dashboard" },
      { label: "Marketplace", href: "/products/listing" },
      { label: "User Requests", href: "/dashboard/user-requests" },
      { label: "Users", href: "/dashboard/all-users" },
      {
        label: "Orders",
        href: "/dashboard/all-orders" || "/dashboard/order-details",
      },
      { label: "Profile", href: "/dashboard/admin-profile" },
    ];
  }

  return [
    { label: "Home", href: "/home" },
    { label: "Marketplace", href: "/products/listing" },
    { label: "Register", href: "/register" },
    { label: "Login", href: "/login" },
  ];
}
