import React, { SyntheticEvent, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Input,
  Button,
  Link,
  Image,
  Stack,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import instance, { interceptorId } from "../api/api";
import Logo from "../images/logo-dark-text.svg";
import DefaultHeader from "../components/DefaultHeader";
import Footer from "../components/Footer";

instance.interceptors.response.eject(interceptorId);

type IResetPassword = {
  password: string;
};

export default function ResetPassword() {
  const [queryParameters] = useSearchParams();
  const toast = useToast();
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);

  const initialValue = {
    password: "",
  };

  const [formValue, setFormValue] = useState(initialValue);
  const [formErrors, setFormError] = useState({
    password: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const validate = (value: IResetPassword) => {
    const errors = {
      password: "",
    };

    if (!value.password) {
      errors.password = "Password is required";
    }

    return errors;
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    const resetToken = queryParameters.get("token");

    e.preventDefault();
    setFormError(validate(formValue));
    setIsSubmitting(true);
    try {
      await instance.put("/password-reset", {
        newPassword: formValue.password,
        token: resetToken,
      });

      toast({
        title: "Password Reset Successful",
        description: "Account password has been reset.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setIsSubmitting(false);
      navigate("/login");
    } catch (error) {
      toast({
        title: "Password Reset Error",
        description: "Password reset request failed. Please try again..",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setIsSubmitting(false);
    }
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <Stack bgColor="#FEFAE0">
      <DefaultHeader />
      <form onSubmit={handleSubmit} noValidate>
        <Stack h="100vh">
          <Flex
            direction="column"
            justifyContent="center"
            alignSelf="center"
            mt="1em"
            w={[300, 400, 500]}
          >
            <Box alignSelf="center">
              <Image w={130} h={130} src={Logo} />
            </Box>
            <Box w={[300, 400, 500]} textAlign="center" height="100px" mb="5%">
              <Text
                color="black"
                fontSize={{ base: "3xl", md: "5xl" }}
                fontWeight={400}
              >
                Reset Password
              </Text>
              <Text>Enter a new password.</Text>
            </Box>

            <FormControl isRequired>
              <FormLabel fontWeight={600} className="label">
                Password
              </FormLabel>
              <InputGroup>
                <InputLeftElement>
                  {!passwordShown ? (
                    <BsFillEyeFill onClick={togglePassword} />
                  ) : (
                    <BsFillEyeSlashFill onClick={togglePassword} />
                  )}
                </InputLeftElement>
                <Input
                  type={passwordShown ? "text" : "password"}
                  borderColor="#D9D9D9"
                  role="textbox"
                  placeholder="Password"
                  name="password"
                  required
                  value={formValue.password}
                  onChange={handleChange}
                  mb="10px"
                  fontWeight={200}
                />
              </InputGroup>
              {formErrors.password && (
                <span className="error">{formErrors.password}</span>
              )}
            </FormControl>
            <Button
              type="submit"
              alignContent="left"
              fontSize="xl"
              color="white"
              bgColor="#BC6C25"
              isLoading={isSubmitting}
              _hover={{
                border: "2px solid #BC6C25",
                color: "black",
                bgColor: "white",
              }}
            >
              Submit
            </Button>
            <Flex direction="column" alignItems="center" mt={5} mb={7}>
              <Text p={1}>
                Don&#39;t have an account?{"  "}
                <Link
                  aria-label="sign up"
                  color="#BC6C25"
                  fontWeight={500}
                  textDecoration="underline"
                  href="/register"
                >
                  Sign Up{" "}
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Stack>
      </form>
      <Footer />
    </Stack>
  );
}
