import React, { ChangeEvent, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  GridItem,
  Button,
  Flex,
  Heading,
  Box,
  HStack,
  useToast,
  Text,
  VStack,
  Avatar,
  Select,
  Stack,
} from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import instance from "../api/api";
import { IAdmin } from "../models/admin.type";
import "./Errors.css";
import "../styles/fontStyle.css";
import User from "../images/Vector.svg";
import LoadingSpinner from "./LoadingSpinner";
import validateNames from "../constants/validate";

const isAdmin = [
  { id: 1, value: "true", text: "true" },
  { id: 2, value: "false", text: "false" },
];

export default function AdminUpdateAdminForm({
  initialValues,
}: {
  initialValues: IAdmin;
}) {
  const [userProfile, setUserProfile] = useState(initialValues);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const validate = validateNames();
  const toast = useToast();
  const navigate = useNavigate();
  const userId = localStorage.getItem("adminId");

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setUserProfile({ ...userProfile, [name]: value });
  };
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<IAdmin>();

  const uploadError = {
    firstName: {
      required: "First name is required",
      pattern: {
        value: validate,
        message: "Invalid character!!",
      },
    },
    surname: {
      required: "Last name is required",
      pattern: {
        value: validate,
        message: "Invalid character!!",
      },
    },
    password: {
      required: "Please enter a password",
      minLength: 6,
    },
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const resetPasswordClicked = () => {
    setIsResetPassword(!isResetPassword);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    let data;
    if (userProfile.password !== "") {
      data = {
        firstName: userProfile.firstName,
        surname: userProfile.surname,
        emailAddress: userProfile.emailAddress,
        phoneNumber: userProfile.phoneNumber,
        isAdmin: userProfile.isAdmin,
        password: userProfile.password,
      };
    } else {
      data = {
        firstName: userProfile.firstName,
        surname: userProfile.surname,
        emailAddress: userProfile.emailAddress,
        phoneNumber: userProfile.phoneNumber,
        isAdmin: userProfile.isAdmin,
      };
    }

    try {
      const response = await instance.put(`/admin/admins/${userId}`, data);
      setIsLoading(false);
      toast({
        title: "Profile updated",
        description: "Profile updated successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          setIsLoading(false);
          toast({
            title: "Profile not updated",
            description: "Profile could not be updated",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          return error;
        }
      }
      return "An unexpected error occurred";
    }
  };
  const renderUser = (
    <div>
      <Flex
        w="full"
        h="full"
        p={10}
        alignItems="flex-start"
        direction={{ base: "column", md: "row" }}
      >
        <Box w="full">
          <Flex
            direction={{ base: "column", md: "row" }}
            align={{ base: "center" }}
            ml={[0, 0, 50]}
          >
            <Avatar src={User} size={{ base: "xl", md: "xl" }} />
            <VStack p={6} align="flex-start">
              <HStack>
                <Text size="sm">{userProfile?.firstName}</Text>
                <Text size="sm">{userProfile?.surname}</Text>
              </HStack>
              <Text size="sm">Administrator</Text>
            </VStack>
          </Flex>
          <Box w={{ base: "full", md: "65%" }} ml={[0, 0, 50]}>
            <hr className="line" />
          </Box>
          <Heading
            p={6}
            fontSize={{ base: "xl" }}
            fontWeight={500}
            ml={[0, 0, 30]}
          >
            Personal Information
          </Heading>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <SimpleGrid
              columns={2}
              columnGap={3}
              rowGap={6}
              w={{ base: "full", md: "65%" }}
              py={25}
              ml={[0, 0, 50]}
            >
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <FormControl isRequired>
                  <FormLabel fontWeight={700} className="label">
                    first name
                  </FormLabel>
                  <Input
                    placeholder="John"
                    {...register("firstName", uploadError.firstName)}
                    value={userProfile.firstName}
                    onChange={handleInputChange}
                  />
                  {errors.firstName && (
                    <p className="error">{errors.firstName?.message}</p>
                  )}
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <FormControl isRequired>
                  <FormLabel fontWeight={700} className="label">
                    last name
                  </FormLabel>
                  <Input
                    placeholder="Doe"
                    {...register("surname", uploadError.surname)}
                    value={userProfile.surname}
                    onChange={handleInputChange}
                  />
                  {errors.surname && (
                    <p className="error">{errors.surname?.message}</p>
                  )}
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <FormControl isRequired>
                  <FormLabel fontWeight={700} className="label">
                    email address
                  </FormLabel>
                  <Input
                    placeholder="farmai@gmail.com"
                    value={userProfile?.emailAddress}
                    {...register("emailAddress")}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <FormControl isRequired>
                  <FormLabel fontWeight={700} className="label">
                    phone number
                  </FormLabel>
                  <Input
                    placeholder="56214440"
                    type="number"
                    value={userProfile.phoneNumber}
                    {...register("phoneNumber", {
                      required: "Phone number is required",
                      minLength: 8,
                      maxLength: 8,
                    })}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>
              <VStack align="normal">
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  <FormControl>
                    <FormLabel fontWeight={700} className="label">
                      isadmin
                    </FormLabel>
                    <Select
                      id="isAdmin"
                      {...register("isAdmin")}
                      value={userProfile.isAdmin}
                      onChange={handleInputChange}
                    >
                      {isAdmin.map((option) => (
                        <option key={option.id} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                {isResetPassword && (
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <FormControl isRequired>
                      <FormLabel fontWeight={700} className="label">
                        password
                      </FormLabel>
                      <Input
                        w="90%"
                        placeholder="Password"
                        type={passwordShown ? "text" : "password"}
                        {...register("password", uploadError.password)}
                        onChange={handleInputChange}
                      />
                      <Stack
                        mt="-1.7em"
                        mb="0.7em"
                        flexDirection="row"
                        justifyContent="right"
                        cursor="pointer"
                      >
                        {!passwordShown ? (
                          <BsFillEyeFill onClick={togglePassword} />
                        ) : (
                          <BsFillEyeSlashFill onClick={togglePassword} />
                        )}
                      </Stack>
                      {errors?.password && (
                        <p className="error">{errors.password.message}</p>
                      )}
                      {errors?.password?.type === "minLength" && (
                        <p className="error">
                          Password must be at least 6 characters long
                        </p>
                      )}
                    </FormControl>
                  </GridItem>
                )}
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  <Flex>
                    <Button
                      size="lg"
                      w="full"
                      mt="2rem"
                      colorScheme="brand"
                      type="submit"
                      isLoading={isSubmitting}
                      disabled={isLoading}
                    >
                      Save Changes
                    </Button>
                    <Button
                      size="lg"
                      w="110%"
                      color="white"
                      mt="2rem"
                      ml={2}
                      bgColor="red.500"
                      _hover={{
                        bgColor: "red.300",
                      }}
                      onClick={() => resetPasswordClicked()}
                    >
                      Reset <br /> Password
                    </Button>
                    <Button
                      size="lg"
                      w="full"
                      mt="2rem"
                      ml={2}
                      bgColor="gray.300"
                      type="submit"
                      disabled={isLoading}
                      onClick={() => navigate("/dashboard/all-users")}
                    >
                      Cancel
                    </Button>
                  </Flex>
                </GridItem>
              </VStack>
            </SimpleGrid>
          </form>
        </Box>
      </Flex>
    </div>
  );

  return <div>{isLoading ? <LoadingSpinner /> : renderUser}</div>;
}
