import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
} from "@chakra-ui/react";
import React from "react";
import OrderDetailz from "../models/orderDetails";

interface ProductsAvailableProps {
  productType: string;
  availableProducts: OrderDetailz[];
}

function ProductsAvailable({
  productType,
  availableProducts,
}: ProductsAvailableProps) {
  return (
    <Box>
      {productType === "Eggs" ? (
        <Box>
          <TableContainer mr={["auto", "auto", 2]}>
            <Table
              size={["sm", "sm", "lg"]}
              variant="striped"
              fontWeight="medium"
            >
              <Thead h="70px" maxWidth={[0, 0, 38]}>
                <Th>Quantity in Trays</Th>
                <Th>Price</Th>
              </Thead>
              {availableProducts.map((products) => (
                <Tbody h="81px" maxWidth={[0, 0, 38]}>
                  <Td>{products.quantity}</Td>
                  <Td>M{products.price}</Td>
                </Tbody>
              ))}
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Box>
          <TableContainer mr={["auto", "auto", 70]}>
            <Table
              size={["sm", "sm", "md"]}
              variant="striped"
              fontWeight="medium"
            >
              <Thead h="70px" maxWidth={[0, 0, 38]}>
                <Th>Product Type</Th>
                <Th>Quantity in KG</Th>
                <Th>Price</Th>
              </Thead>
              {availableProducts.map((products) => (
                <Tbody h="81px" maxWidth={[0, 0, 38]}>
                  {products.productType.length > 1 ? (
                    <Td>Poultry & Eggs</Td>
                  ) : (
                    <Box>
                      <Td>{products.quantity}</Td>
                      <Td>M{products.price}</Td>
                    </Box>
                  )}
                </Tbody>
              ))}
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
}

export default ProductsAvailable;
