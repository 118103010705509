import { useState } from "react";
import useSWR from "swr";
import instance from "../../api/api";
import Header from "../../components/AdminHeader";
import "../../components/Errors.css";
import "../../styles/fontStyle.css";
import LoadingSpinner from "../../components/LoadingSpinner";
import AdminUpdateFarmerForm from "../../components/AdminUpdateFarmerForm";

function AdminUpdateFarmer() {
  const [initialValues, setInitialValues] = useState({
    _id: "",
    firstName: "",
    surname: "",
    district: "",
    farmName: "",
    phoneNumber: "",
    emailAddress: "",
    productType: [""],
    createdAt: "",
    updatedAt: "",
    status: "",
    password: "",
  });
  const userId = localStorage.getItem("farmerId");

  const sendRequest = async (url: string) => {
    const response = await instance.get(url);
    if (response.data) {
      setInitialValues(response.data.farmer);
      initialValues.password = "";
    }
    return response.data;
  };
  const { data, error } = useSWR(`/api/v1/farmers/${userId}`, sendRequest);

  if (!data || error) {
    return (
      <div>
        <Header />
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <Header />
      {initialValues.firstName !== "" ? (
        <AdminUpdateFarmerForm initialValues={initialValues} />
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
}
export default AdminUpdateFarmer;
