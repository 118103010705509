import { Box, Flex, Text, Icon } from "@chakra-ui/react";

interface UserSummaryCardProps {
  title: string;
  icon: any;
  activeUsersCount: number;
}

export default function UserSummaryCard({
  title,
  icon,
  activeUsersCount,
}: UserSummaryCardProps) {
  return (
    <Flex
      flexDir="column"
      w="100%"
      p="5"
      justifyContent="center"
      textAlign="center"
      border="1px"
      borderRadius="md"
    >
      <Text>{title}</Text>
      <Flex gap="10px" justifyContent="center">
        <Box>
          <Icon boxSize={6} as={icon} />
        </Box>
        <Text fontWeight="extrabold">{activeUsersCount}</Text>
      </Flex>
    </Flex>
  );
}
