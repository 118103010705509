import { Button, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { useState } from "react";
import useSWR from "swr";
import instance from "../api/api";
import UserRequest, { UserDetails } from "./UserRequest";

export default function FarmerRequests() {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [farmersList, setFarmersList] = useState<any>([]);

  const sendRequest = async (url: string) => {
    const response = await instance.get(url);
    const newFarmerRequests: UserDetails[] = response.data.farmers;

    setFarmersList((previous: UserDetails[]) => {
      // compare if the response data is the same as the previous, and if so return the previous.
      if (
        JSON.stringify(previous) === JSON.stringify(newFarmerRequests) ||
        response.data.totalItems === previous.length
      ) {
        return previous;
      }

      return previous.concat(response.data.farmers);
    });

    return response.data;
  };

  const {
    data: farmersData,
    isLoading,
    error,
  } = useSWR(
    `/api/v1/farmers/requests?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    sendRequest,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      dedupingInterval: 0,
      refreshInterval: 0,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      refreshIntervalInBackground: false,
      key: `data-${pageNumber}`,
    },
  );

  return (
    <Flex textAlign="center" flexDirection="column">
      <SimpleGrid spacing={4} columns={[1, 2, 4]}>
        {farmersList.length > 0 &&
          farmersList.map((farmer: any) => {
            return (
              <UserRequest
                key={farmer._id}
                userRequestDetails={farmer}
                setUserRequestList={setFarmersList}
              />
            );
          })}
      </SimpleGrid>
      <Flex justifyContent="center">
        {farmersData &&
          farmersList.length > 0 &&
          farmersList.length < farmersData.totalItems && (
            <Button
              onClick={() => {
                setPageSize((prevPageSize) => {
                  if (farmersList.length < prevPageSize) {
                    return prevPageSize;
                  }
                  return prevPageSize + 8;
                });
                setPageNumber((prevPageNum) => {
                  if (farmersList.length < pageSize) {
                    return farmersList.length;
                  }
                  return prevPageNum + 8;
                });
              }}
              mt="10"
              isLoading={isLoading}
              w={["100%", "30", "10%"]}
            >
              Load More
            </Button>
          )}
      </Flex>

      {!farmersData && <Text mt="2">Loading new requests....</Text>}

      {farmersData && farmersData.totalItems === 0 && (
        <Text>No new farmer requests!</Text>
      )}
      {error && (
        <Text>Failed to load new farmer requests! Try again later</Text>
      )}
    </Flex>
  );
}
