import { Card, CardBody, Flex, Text } from "@chakra-ui/react";
import React from "react";

interface ProductSupplierProps {
  supplierInfo: {
    id: string;
    name: string;
    quantity: number;
    productType: string[];
    price: number;
    phoneNumber: number;
    emailAddress: string;
  };
  key: React.Key;
}

export default function ProductSupplier({
  supplierInfo,
}: ProductSupplierProps) {
  return (
    <Card>
      <CardBody textAlign="center" p="2" bg="#EEEEEF">
        <Text fontSize="sm" fontWeight="bold">
          {supplierInfo.name}
        </Text>
        <Text fontSize="xs"> {supplierInfo.emailAddress}</Text>
        <Flex justifyContent="center">
          <Text fontSize="xs" mr="2">
            Tel: {supplierInfo.phoneNumber}
          </Text>
          <Text fontSize="xs">Qty: {supplierInfo.quantity}</Text>
        </Flex>
      </CardBody>
    </Card>
  );
}
