import { useState } from "react";
import useSWR from "swr";
import instance from "../../api/api";
import "../../components/Errors.css";
import "../../styles/fontStyle.css";
import { getUserId } from "../../services/auth.service";
import Header from "../../components/AdminHeader";
import AdminProfileForm from "../../components/AdminProfileForm";
import LoadingSpinner from "../../components/LoadingSpinner";

function AdminProfile() {
  const [initialValues, setInitialValues] = useState({
    _id: "",
    firstName: "",
    surname: "",
    phoneNumber: "",
    isAdmin: "",
    emailAddress: "",
  });
  const userId = getUserId();
  const sendRequest = async (url: string) => {
    const response = await instance.get(url);
    if (response.data) {
      setInitialValues(response.data.admin);
    }
    return response.data;
  };
  const { data, error } = useSWR(`/admin/admins/${userId}`, sendRequest);
  if (!data || error) {
    return (
      <div>
        <Header />
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <Header />
      {initialValues.firstName !== "" ? (
        <AdminProfileForm initialValues={initialValues} />
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
}
export default AdminProfile;
