import React, { ReactNode, useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { getRole, getToken } from "../constants/auth";
import ROLES from "../constants/roles";
import AuthContext from "../context/Auth/AuthContext";

interface ProtectedRouteProps {
  route: ReactNode;
  path: string;
}
function ProtectedRoute(component: ProtectedRouteProps) {
  const { route, path } = component;
  const token = getToken();
  return (
    <Routes>
      <Route
        path={`/${path}`}
        element={token ? route : <Navigate replace to="/login" />}
      />
    </Routes>
  );
}

function BuyerRoutes(component: ProtectedRouteProps) {
  const { user } = useContext(AuthContext);
  const token = getToken() || user?.token;
  const role = getRole() || user?.role;
  const isBuyer = role === ROLES.BUYER;
  const { route, path } = component;
  return (
    <Routes>
      <Route
        path={`/dashboard/${path}`}
        element={token && isBuyer ? route : <Navigate replace to="/login" />}
      />
    </Routes>
  );
}

function FarmerRoutes(component: ProtectedRouteProps) {
  const token = getToken();
  const role = getRole();
  const isFarmer = role === ROLES.FARMER;
  const { route, path } = component;
  return (
    <Routes>
      <Route
        path={`/dashboard/${path}`}
        element={token && isFarmer ? route : <Navigate replace to="/login" />}
      />
    </Routes>
  );
}

function AdminRoutes(component: ProtectedRouteProps) {
  const token = getToken();
  const role = getRole();
  const isAdmin = role === ROLES.ADMIN;
  const { route, path } = component;
  return (
    <Routes>
      <Route
        path={`/dashboard/${path}`}
        element={token && isAdmin ? route : <Navigate replace to="/login" />}
      />
    </Routes>
  );
}

function OtherRoutes(component: ProtectedRouteProps) {
  const token = getToken();
  const { route, path } = component;
  return (
    <Routes>
      <Route
        path={`/${path}`}
        element={!token ? route : <Navigate replace to="/home" />}
      />
    </Routes>
  );
}

export default {
  ProtectedRoute,
  BuyerRoutes,
  FarmerRoutes,
  AdminRoutes,
  OtherRoutes,
};
