import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  // useEffect,
  useState,
} from "react";
import {
  Box,
  Flex,
  Text,
  Input,
  Button,
  Link,
  Image,
  Stack,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { AxiosResponse } from "axios";
import { MdOutlineMail } from "react-icons/md";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import instance, { interceptorId } from "../api/api";
import AuthContext from "../context/Auth/AuthContext";
import validateEmail from "../constants/validateEmail";
import validatePhoneNumber from "../constants/validatePhoneNumber";
import Logo from "../images/logo-dark-text.svg";
import DefaultHeader from "../components/DefaultHeader";
import Footer from "../components/Footer";

instance.interceptors.response.eject(interceptorId);
type ILogin = {
  username: string;
  password: string;
};

interface DecodedTokenProps {
  role: string;
}

export default function LoginWithPassword() {
  const [passwordShown, setPasswordShown] = useState(false);
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const isValid = (errors: ILogin) => {
    if (errors.username === "") {
      return true;
    }
    return false;
  };

  const initialValue = {
    username: "",
    password: "",
  };

  const [formValue, setFormValue] = useState(initialValue);
  const [formErrors, setFormError] = useState({
    username: "",
    password: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const validate = (value: ILogin) => {
    let isEmail = false;
    let isPhoneNumber = false;

    const errors = {
      username: "",
      password: "",
    };

    const textRegex = /^[a-z]/i;
    if (!value.username) {
      errors.username = "Phone Number or Email Required";
    }
    if (!value.password) {
      errors.password = "Password is required";
    }

    if (value.username.includes("@") || textRegex.test(value.username))
      isEmail = true;
    if (isEmail) {
      if (!validateEmail().test(value.username)) {
        errors.username = "Invalid email format";
      }
    }

    if (validatePhoneNumber().test(value.username)) {
      isPhoneNumber = true;
    }

    if (isPhoneNumber) {
      if (value.username.length !== 8) {
        errors.username = "Invalid phone number";
      }
    }

    return errors;
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    setFormError(validate(formValue));
    setIsSubmitting(true);
  };

  const login = useCallback(
    (response: AxiosResponse) => {
      setApiResponse(response.data.message);
      const { token } = response.data;
      const { userId } = response.data;
      if (token) {
        const decoded = jwtDecode<DecodedTokenProps>(token);
        const decodedRole = decoded?.role;
        const role = decodedRole;
        localStorage.setItem("userId", userId);
        localStorage.setItem("token", token);
        localStorage.setItem("role", role);
        setUser({ token, userId, role });
        switch (role) {
          case "Farmer": {
            navigate("/dashboard/farmers-dashboard");
            window.location.reload();
            break;
          }
          case "Buyer": {
            navigate("/dashboard/buyer-dashboard");
            window.location.reload();
            break;
          }
          default:
            navigate("/dashboard/admin-dashboard");
            window.location.reload();
        }
      }
      formValue.username = "";
    },
    [formValue, navigate, setUser],
  );

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    const handleLogin = async (data: ILogin) => {
      try {
        setIsLoggingIn(true);
        setApiResponse("");
        const res = await instance
          .post("/passwordLogin", {
            username: data.username,
            password: data.password,
          })
          .then((response) => {
            login(response);
            return response;
          })
          .catch((error) => {
            setIsLoggingIn(error.response.data.success);
            setApiResponse(error.response.data.message);
            return error;
          });
        return res;
      } catch (error) {
        setIsLoggingIn(false);
        return error;
      }
    };
    if (isValid(formErrors) && isSubmitting) {
      handleLogin(formValue);
    }
  }, [formErrors, formValue, isSubmitting, login, navigate, setUser]);

  return (
    <Stack bgColor="#FEFAE0">
      <DefaultHeader />
      <form onSubmit={handleSubmit} noValidate>
        <Stack h="100vh">
          <Flex
            direction="column"
            justifyContent="center"
            alignSelf="center"
            mt="1em"
            w={[300, 400, 500]}
          >
            <Box alignSelf="center">
              <Image w={130} h={130} src={Logo} />
            </Box>
            <Box w={[300, 400, 500]} textAlign="center" height="100px" mb="5%">
              <Text
                color="black"
                fontSize={{ base: "3xl", md: "5xl" }}
                fontWeight={400}
              >
                Welcome back
              </Text>
              <Text>Let&apos;s log you into your account</Text>
            </Box>
            <FormControl isRequired>
              <FormLabel fontWeight={600} className="label">
                Email address
              </FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <MdOutlineMail />
                </InputLeftElement>
                <Input
                  borderColor="#D9D9D9"
                  placeholder="Email Address"
                  name="username"
                  required
                  value={formValue.username}
                  onChange={handleChange}
                  mb="10px"
                  fontWeight={200}
                />
              </InputGroup>
              {formErrors.username && (
                <span className="error">{formErrors.username}</span>
              )}
            </FormControl>
            <FormControl isRequired>
              <FormLabel fontWeight={600} className="label">
                Password
              </FormLabel>
              <InputGroup>
                <InputLeftElement>
                  {!passwordShown ? (
                    <BsFillEyeFill onClick={togglePassword} />
                  ) : (
                    <BsFillEyeSlashFill onClick={togglePassword} />
                  )}
                </InputLeftElement>
                <Input
                  type={passwordShown ? "text" : "password"}
                  borderColor="#D9D9D9"
                  role="textbox"
                  placeholder="Password"
                  name="password"
                  required
                  value={formValue.password}
                  onChange={handleChange}
                  mb="10px"
                  fontWeight={200}
                />
              </InputGroup>
              {formErrors.password && (
                <span className="error">{formErrors.password}</span>
              )}
              {apiResponse !== null && (
                <span className="response">{apiResponse}</span>
              )}
            </FormControl>
            <Text mb="2">
              <Link
                aria-label="forgot password"
                color="#BC6C25"
                fontWeight={500}
                textDecoration="none"
                href="/forgot-password"
              >
                Forgot Password?
              </Link>
            </Text>
            <Button
              type="submit"
              alignContent="left"
              fontSize="xl"
              color="white"
              bgColor="#BC6C25"
              isLoading={isLoggingIn}
              _hover={{
                border: "2px solid #BC6C25",
                color: "black",
                bgColor: "white",
              }}
            >
              Submit
            </Button>
            <Flex direction="column" alignItems="center" mt={5} mb={7}>
              <Text p={1}>
                Don&#39;t have an account?{"  "}
                <Link
                  aria-label="sign up"
                  color="#BC6C25"
                  fontWeight={500}
                  textDecoration="underline"
                  href="/register"
                >
                  Sign Up{" "}
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Stack>
      </form>
      <Footer />
    </Stack>
  );
}
