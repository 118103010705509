import React from "react";
import "aos/dist/aos.css";
import { HStack, Text, Image, Heading, Flex, Stack } from "@chakra-ui/react";
import FieldImage from "../../images/Homepage_Images/FieldImage.svg";

export default function LandingSection5() {
  return (
    <HStack
      w="full"
      justifyContent="space-between"
      p={20}
      maxH="120em"
      h={{ base: "auto", lg: "100vh" }}
      align="center"
      flexDirection={{ base: "column-reverse", md: "row" }}
    >
      <Stack
        data-aos="fade-up"
        data-aos-duration="1000"
        textAlign={{
          base: "center",
          md: "left",
        }}
      >
        <Flex
          flexDirection="column"
          w={{
            base: "auto",
            md: "24em",
            lg: "31em",
            xl: "40em",
            "2xl": "48em",
          }}
        >
          <Heading
            fontSize="2.5em"
            fontWeight={700}
            color="#050505"
            mt={{
              base: "1em",
              md: "auto",
            }}
          >
            We are the future of agricultural marketing
          </Heading>
          <Text fontSize="1.25em" mt={10}>
            Join the future of agricultural marketing on our marketplace. Direct
            connection to customers, revolutionizing sales for farmers. Be a
            part of shaping the future.
          </Text>
        </Flex>
      </Stack>

      <Stack
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="1000"
        w={{
          base: "auto",
          md: "22em",
          lg: "26em",
          xl: "28em",
          "2xl": "36em",
        }}
      >
        <Image src={FieldImage} />
      </Stack>
    </HStack>
  );
}
