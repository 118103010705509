import { Menu, MenuButton, IconButton, MenuList } from "@chakra-ui/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import Buyer from "../models/buyer.type";
import DeleteButtonDialog from "./AdminDashboard/DeleteButtonDialog";
import EditUserDrawer from "./AdminDashboard/EditUserDrawer";

interface BuyerTableActionsButtonProps {
  buyers: Buyer[];
  buyerId: string;
  setUserBuyers: React.Dispatch<React.SetStateAction<Buyer[]>>;
}

export default function BuyerTableActionsButton({
  buyerId,
  buyers,
  setUserBuyers,
}: BuyerTableActionsButtonProps) {
  return (
    <Menu>
      <MenuButton
        borderColor="black"
        as={IconButton}
        aria-label="Options"
        icon={<BsThreeDotsVertical />}
        variant="outline"
      />
      <MenuList>
        <EditUserDrawer userType="Buyer" userId={buyerId} userList={buyers} />
        <DeleteButtonDialog
          userType="Buyer"
          userId={buyerId}
          userList={buyers}
          setUserList={setUserBuyers}
        />
      </MenuList>
    </Menu>
  );
}
