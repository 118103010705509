import React from "react";
import { Box, Image, Link } from "@chakra-ui/react";
import farmLogo from "../images/logo.svg";

function Logo() {
  return (
    <div>
      <Box maxW="70px" maxH="70px">
        <Link href="/home">
          <Image src={farmLogo} />
        </Link>
      </Box>
    </div>
  );
}

export default Logo;
