import { EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormLabel,
  Hide,
  Input,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState, useRef } from "react";
import instance from "../../api/api";

interface EditUserDrawerProps {
  userId: string;
  userType: string;
  userList: any;
}

export default function EditUserDrawer({
  userType,
  userId,
  userList,
}: EditUserDrawerProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userUpdate, setUserUpdate] = useState({});
  const [currentUserDetails, setCurrentUserDetails] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const firstField = useRef();
  const toast = useToast();

  const updateUserDetails = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (userType === "Admin") {
        await instance.put(`/admin/admins/${userId}`, userUpdate);
        setIsLoading(false);
        toast({
          title: `${userType} Profile Updated`,
          description: `${userType} Profile updated successfully`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      }
      if (userType === "Buyer") {
        await instance.put(`/api/v1/buyers/${userId}`, userUpdate);
        setIsLoading(false);
        toast({
          title: `${userType} Profile Updated`,
          description: `${userType} Profile updated successfully`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      }
      if (userType === "Farmer") {
        await instance.put(`/api/v1/farmers/${userId}`, userUpdate);
        setIsLoading(false);
        toast({
          title: `${userType} Profile Updated`,
          description: `${userType} Profile updated successfully`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        title: "Profile Update Failed",
        description: "Profile unsuccessful. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      onClose();
    }
  };

  return (
    <>
      <Box
        as={MenuItem}
        size="sm"
        icon={<EditIcon />}
        onClick={() => {
          const userDetails = userList.filter(
            (user: any) => user._id === userId,
          );
          setCurrentUserDetails(userDetails[0]);
          onOpen();
        }}
      >
        <Hide below="sm">
          <Text>Edit User</Text>
        </Hide>
      </Box>
      <Drawer
        size="md"
        isOpen={isOpen}
        placement="right"
        // @ts-ignore
        initialFocusRef={firstField}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent bg="#FEFAE0">
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Edit User</DrawerHeader>

          <DrawerBody>
            <form onSubmit={updateUserDetails}>
              <Stack spacing="24px">
                <Box>
                  <FormLabel htmlFor="firstName">First Name</FormLabel>
                  <Input
                    borderColor="#A0AEC0"
                    onChange={(e) => {
                      setUserUpdate((prevState) => ({
                        ...prevState,
                        firstName: e.target.value,
                      }));
                    }}
                    defaultValue={currentUserDetails?.firstName}
                    // @ts-ignore
                    ref={firstField}
                    id="firstname"
                    placeholder="John"
                  />
                </Box>
                <Box>
                  <FormLabel htmlFor="surname">Last Name</FormLabel>
                  <Input
                    borderColor="#A0AEC0"
                    onChange={(e) => {
                      setUserUpdate((prevState) => ({
                        ...prevState,
                        surname: e.target.value,
                      }));
                    }}
                    defaultValue={currentUserDetails?.surname}
                    id="surname"
                    placeholder="Smith"
                  />
                </Box>

                {currentUserDetails?.district && (
                  <Box>
                    <FormLabel htmlFor="district">District</FormLabel>
                    <Input
                      borderColor="#A0AEC0"
                      onChange={(e) => {
                        setUserUpdate((prevState) => ({
                          ...prevState,
                          district: e.target.value,
                        }));
                      }}
                      defaultValue={currentUserDetails?.district}
                      id="district"
                      placeholder="Maseru"
                    />
                  </Box>
                )}
                <Box>
                  <FormLabel htmlFor="email">Email Address</FormLabel>
                  <Input
                    borderColor="#A0AEC0"
                    onChange={(e) => {
                      setUserUpdate((prevState) => ({
                        ...prevState,
                        emailAddress: e.target.value,
                      }));
                    }}
                    defaultValue={currentUserDetails?.emailAddress}
                    type="email"
                    id="email"
                    placeholder="johnsmith@farmai.africa"
                  />
                </Box>
                <Box>
                  <FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>
                  <Input
                    borderColor="#A0AEC0"
                    onChange={(e) => {
                      setUserUpdate((prevState) => ({
                        ...prevState,
                        phoneNumber: e.target.value,
                      }));
                    }}
                    defaultValue={currentUserDetails?.phoneNumber}
                    type="tel"
                    id="phoneNumber"
                    placeholder="+26770000000"
                  />
                </Box>
                {/* Conditional */}

                {currentUserDetails?.shopName && (
                  <Box>
                    <FormLabel htmlFor="shopName">Shop Name</FormLabel>
                    <Input
                      borderColor="#A0AEC0"
                      onChange={(e) => {
                        setUserUpdate((prevState) => ({
                          ...prevState,
                          shopName: e.target.value,
                        }));
                      }}
                      defaultValue={currentUserDetails?.shopName}
                      type="email"
                      id="shopName"
                      placeholder="Hen House Emporium"
                    />
                  </Box>
                )}
                {/* Conditional */}
                {currentUserDetails?.farmName && (
                  <Box>
                    <FormLabel htmlFor="farmName">Farm Name</FormLabel>
                    <Input
                      borderColor="#A0AEC0"
                      onChange={(e) => {
                        setUserUpdate((prevState) => ({
                          ...prevState,
                          farmName: e.target.value,
                        }));
                      }}
                      defaultValue={currentUserDetails?.farmName}
                      type="email"
                      id="farmName"
                      placeholder="The Pig Palace"
                    />
                  </Box>
                )}

                {currentUserDetails?.isAdmin && (
                  <Stack>
                    <Text fontSize="md" fontWeight="medium">
                      Is the user an Admin?
                    </Text>
                    <RadioGroup
                      onChange={(value) => {
                        setUserUpdate((prevState) => ({
                          ...prevState,
                          /* eslint-disable */
                          isAdmin: value === "Yes" ? true : false,
                          /* eslint-enable */
                        }));
                      }}
                      defaultValue={currentUserDetails?.isAdmin ? "Yes" : "No"}
                    >
                      <Stack spacing={5} direction="row">
                        <Radio colorScheme="red" value="Yes">
                          Yes
                        </Radio>
                        <Radio colorScheme="green" value="No">
                          No
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </Stack>
                )}
              </Stack>
              <Flex
                mt="5"
                justifyContent="center"
                flexDir="column"
                as={DrawerFooter}
              >
                <Button
                  w="100%"
                  mb="2"
                  borderColor="#BC6C25"
                  _hover={{
                    background: "#606C38",
                    color: "white",
                  }}
                  variant="outline"
                  onClick={onClose}
                >
                  Cancel
                </Button>

                <Button
                  w="100%"
                  isLoading={isLoading}
                  isDisabled={Object.keys(userUpdate).length === 0}
                  _hover={{
                    background: "#606C38",
                  }}
                  bg="#BC6C25"
                  color="white"
                  type="submit"
                >
                  Update User
                </Button>
              </Flex>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
