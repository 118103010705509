import { Flex, Text, Image } from "@chakra-ui/react";
import React from "react";

export interface OverviewCardProps {
  title: string;
  amount: number | string;
  imageLocation: string;
}

export default function OverviewCard({
  title,
  amount,
  imageLocation,
}: OverviewCardProps) {
  return (
    <Flex
      flexGrow="1"
      p="5"
      justifyContent="center"
      border="1px"
      borderRadius="md"
    >
      <Flex w="20" mr="5">
        <Image boxSize={["45px", "45px", "50px"]} src={imageLocation} />
      </Flex>
      <Flex w="40" fontSize={["md", "sm", "md"]} flexDir="column">
        <Text>{title}</Text>
        <Text fontWeight="extrabold">{amount}</Text>
      </Flex>
    </Flex>
  );
}
