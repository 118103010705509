import { Box, Button, Image } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import source from "../images/plus.svg";

function AddOrderButton() {
  const navigate = useNavigate();
  return (
    <Button
      bgColor="#BC6C25"
      color="white"
      border="1px solid #BC6C25"
      borderRadius="6px"
      _hover={{ opacity: 0.9 }}
      onClick={() => {
        navigate("/products/listing");
      }}
    >
      <Box pr="2px" mr={2}>
        <Image src={source} />
      </Box>
      Add New Order
    </Button>
  );
}

export default AddOrderButton;
