import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import OrderDetailz from "../models/orderDetails";

interface FarmersAvailableProps {
  availableFarmers: OrderDetailz[];
}

function FarmersAvailable({ availableFarmers }: FarmersAvailableProps) {
  const [quantityType, setQuantityType] = useState<string>("");

  function checkProductType(farmer: OrderDetailz) {
    if (farmer.productType[0] === "Eggs") {
      setQuantityType("Qty in Trays");
      return;
    }

    setQuantityType("Qty in Kg");
  }

  useEffect(() => {
    checkProductType(availableFarmers[0]);
  }, [availableFarmers]);

  return (
    <Box>
      <TableContainer mr={["auto", "auto", 0.6]}>
        <Table size="sm" variant="striped" fontWeight="medium">
          <Thead h="70px" maxWidth={[0, 0, 40]}>
            <Tr>
              <Th>#</Th>
              <Th>Names</Th>
              <Th>Email Address</Th>
              <Th>Phone Number</Th>
              <Th>{quantityType}</Th>
              <Th>Price</Th>
            </Tr>
          </Thead>
          <Tbody h="81px" maxWidth={[0, 0, 40]}>
            {availableFarmers.map((farmer, index) => (
              <Tr key={farmer.phoneNumber}>
                <Td>{index + 1}</Td>
                <Td>{farmer.name}</Td>
                <Td>{farmer.emailAddress}</Td>
                <Td>{farmer.phoneNumber}</Td>
                <Td>{farmer.quantity}</Td>
                <Td>{farmer.price}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default FarmersAvailable;
