import React, { useState } from "react";
import { Box, Button, Text, Stack, Hide, Flex } from "@chakra-ui/react";
import { BsFilter } from "react-icons/bs";
import useSWR from "swr";
import instance from "../../api/api";
import BuyerHeader from "../../components/BuyersHeader";
import BuyerTable from "./BuyerTable";
import AddOrderButton from "../../components/AddOrderButton";
import SearchButton from "../../components/SearchButton";
import Order from "../../models/Orders.type";
import LoadingSpinner from "../../components/LoadingSpinner";

export default function BuyerDashboard() {
  const userId = localStorage.getItem("userId");
  const [orders, setOrders] = useState<Order[]>([]);
  const [totalItems, setTotalItems] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const sendRequest = async (url: string) => {
    const usersOrders: Order[] = [];
    const response = await instance.get(url);

    if (response.data) {
      for (let i = 0; i < response.data.orders.length; i += 1) {
        usersOrders.push(response.data.orders[i]);
      }
      setTotalItems(response.data.totalItems);
    }

    setOrders(usersOrders);
    return response.data;
  };

  const { data: buyerOrdersData, error: buyerOrdersError } = useSWR(
    `/api/v1/buyers/${userId}/order?pageNumber=${currentPage}&pageSize=${itemsPerPage}`,
    sendRequest,
    {
      refreshInterval: 5000,
    },
  );

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = orders.filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  );

  const dashbooardTopSection = (
    <Flex
      pr={["35px"]}
      pt="20px"
      pl={[10, 10, 50]}
      flexDir="column"
      alignItems="center"
    >
      <Flex w="100%" justifyContent="space-between">
        <Text color="black" fontWeight={500} fontSize="1.5em" pb="5px" pr="5px">
          Orders
        </Text>
        <SearchButton
          placeholder="Search for items"
          searchTerm={searchTerm}
          handleChange={handleSearch}
        />
        <Stack
          direction={{ base: "row" }}
          justifyContent={{ base: "space-between", md: "flex-end" }}
          spacing={4}
        >
          <Hide>
            <Button
              bgColor="white"
              border="1px solid #BC6C25"
              w={{ base: "30%", xl: "20%" }}
            >
              <span>
                <BsFilter />
              </span>{" "}
              Filter
            </Button>
          </Hide>
          <AddOrderButton />
        </Stack>
      </Flex>
    </Flex>
  );

  if (!buyerOrdersData || buyerOrdersError) {
    return (
      <div>
        <BuyerHeader />
        {dashbooardTopSection}
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <Box>
      <BuyerHeader />
      {dashbooardTopSection}
      <BuyerTable
        orders={filteredData}
        setOrders={setOrders}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        totalItems={totalItems}
      />
    </Box>
  );
}
