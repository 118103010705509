import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { Box, Flex, Text, Input, Button } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Header from "../components/Header";
import instance from "../api/api";
import AuthContext from "../context/Auth/AuthContext";
import "../styles/fontStyle.css";

type IVerify = {
  otp: string;
};
interface DecodedTokenProps {
  role: string;
}

export default function VerifyUser() {
  const location = useLocation();
  const previousRoute = location.state?.route;

  const { setUser } = useContext(AuthContext);
  const isValid = (errors: IVerify) => {
    if (errors.otp === "") {
      return true;
    }
    return false;
  };

  const initialValue = {
    otp: "",
  };

  const [formValue, setFormValue] = useState(initialValue);
  const [formErrors, setFormError] = useState({
    otp: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const validate = (value: IVerify) => {
    const errors = {
      otp: "",
    };

    if (!value.otp) {
      errors.otp = "OTP Required";
      setIsSubmitting(false);
    }

    if (value.otp.length !== 5) {
      errors.otp = "Invalid otp";
      setIsSubmitting(false);
    }

    return errors;
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    setFormError(validate(formValue));
    setIsSubmitting(true);
  };
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogin = async (data: IVerify) => {
      const res = await instance.post("/verify", {
        otp: data.otp,
      });
      if (!(res.status >= 200 && res.status < 300)) {
        return;
      }
      formValue.otp = "";
      const { token } = res.data;
      if (token) {
        const decoded = jwtDecode<DecodedTokenProps>(token);
        const decodedRole = decoded?.role;
        const { userId } = res.data;
        const role = decodedRole;
        localStorage.setItem("userId", userId);
        localStorage.setItem("token", token);
        localStorage.setItem("role", role);
        setUser({ token, userId, role });
        switch (role) {
          case "Farmer": {
            navigate("/dashboard/farmers-dashboard");
            window.location.reload();
            break;
          }
          case "Buyer": {
            navigate("/dashboard/buyer-dashboard");
            window.location.reload();
            break;
          }
          default:
            navigate("/dashboard/admin-dashboard");
            window.location.reload();
        }
      }
    };
    if (isValid(formErrors) && isSubmitting) {
      handleLogin(formValue);
    }
  }, [formErrors, formValue, isSubmitting, navigate, setUser]);

  return (
    <div>
      <Header />
      <form onSubmit={handleSubmit} noValidate>
        <Flex
          align="center"
          direction="column"
          justifyContent="center"
          alignSelf="center"
          mt="6em"
          ml={["11%", "16%", "25%", "33%", "34%"]}
          w={[300, 400, 500]}
          border="1px solid #DADADA"
          alignItems="center"
        >
          <Box
            border="2px solid green"
            w={[300, 400, 500]}
            textAlign="center"
            height="100px"
            backgroundColor="#1C6B28"
          >
            <Text mt="5%" color="white" fontSize="3xl" fontWeight={400}>
              Verify
            </Text>
          </Box>
          {previousRoute === "/login" && (
            <Text
              p={2}
              mt="1em"
              fontSize="1.1em"
              color="black"
              textAlign="center"
            >
              A pin has been sent to your email and phone, please provide it
              below
            </Text>
          )}
          <Input
            borderColor="#D9D9D9"
            textAlign="center"
            placeholder="PIN"
            name="otp"
            required
            value={formValue.otp}
            onChange={handleChange}
            w={[250, 350, 450]}
            mt="32px"
            mb="32px"
            fontWeight={700}
          />
          {formErrors.otp && <span className="error">{formErrors.otp}</span>}
          <Button
            type="submit"
            w={[250, 350, 450]}
            p="7"
            fontSize="xl"
            mb="10"
            isLoading={isSubmitting}
            colorScheme="brand"
          >
            Submit
          </Button>
        </Flex>
      </form>
    </div>
  );
}
